<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    :width="width"
    :modal="modal"
    :center="center"
    :before-close="closeDialog"
    class="dialog_box"
    append-to-body
  >
    <div class="content" style="white-space: pre-wrap">
      {{ countent }}
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    showDialog: {
      default: false,
      type: Boolean,
    },
    title: {
      default: "标题",
    },
    width: {
      default: "50%",
    },
    modal: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: true,
    },
    countent: {
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    // init(num) {
    //   console.log(num);
    // },
    closeDialog() {
      this.$emit("closeDialog", false);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 10px !important;
  background: var(--secondary) !important;
  .el-dialog__header {
    padding: 20px 0 !important;
  }
  .el-dialog__body {
    padding: 20px 0 !important;
  }
  .content {
    max-height: 60vh;
    min-height: 20vh;
    padding: 0 20px;
    overflow: auto;
  }
}
</style>

export const enjoyList =

{
    "[微笑]": require('@/assets/images/enjoy/enjoy001.png'),
    "[龇牙]": require('@/assets/images/enjoy/enjoy002.png'),
    "[调皮]": require('@/assets/images/enjoy/enjoy003.png'),
    "[偷笑]": require('@/assets/images/enjoy/enjoy004.png'),
    "[害羞]": require('@/assets/images/enjoy/enjoy005.png'),
    "[可爱]": require('@/assets/images/enjoy/enjoy006.png'),
    "[傲慢]": require('@/assets/images/enjoy/enjoy007.png'),
    "[白眼]": require('@/assets/images/enjoy/enjoy008.png'),
    "[鄙视]": require('@/assets/images/enjoy/enjoy009.png'),
    "[闭嘴]": require('@/assets/images/enjoy/enjoy010.png'),
    "[擦汗]": require('@/assets/images/enjoy/enjoy011.png'),
    "[打哈欠]": require('@/assets/images/enjoy/enjoy012.png'),
    "[大兵]": require('@/assets/images/enjoy/enjoy013.png'),
    "[大哭]": require('@/assets/images/enjoy/enjoy014.png'),
    "[得意]": require('@/assets/images/enjoy/enjoy015.png'),
    "[发呆]": require('@/assets/images/enjoy/enjoy016.png'),
    "[奋斗]": require('@/assets/images/enjoy/enjoy017.png'),
    "[鼓掌]": require('@/assets/images/enjoy/enjoy018.png'),
    "[憨笑]": require('@/assets/images/enjoy/enjoy019.png'),
    "[坏笑]": require('@/assets/images/enjoy/enjoy020.png'),
    "[惊讶]": require('@/assets/images/enjoy/enjoy021.png'),
    "[饥饿]": require('@/assets/images/enjoy/enjoy022.png'),
    "[勾引]": require('@/assets/images/enjoy/enjoy023.png'),
    "[抱拳]": require('@/assets/images/enjoy/enjoy024.png'),
    "[NO]": require('@/assets/images/enjoy/enjoy025.png'),
    "[OK]": require('@/assets/images/enjoy/enjoy026.png'),
    "[爱你]": require('@/assets/images/enjoy/enjoy027.png'),
    "[差劲]": require('@/assets/images/enjoy/enjoy028.png'),
    "[爱心]": require('@/assets/images/enjoy/enjoy029.png'),
    "[可怜]": require('@/assets/images/enjoy/enjoy030.png'),
    "[抠鼻]": require('@/assets/images/enjoy/enjoy031.png'),
    "[酷]": require('@/assets/images/enjoy/enjoy032.png'),
    "[快哭了]": require('@/assets/images/enjoy/enjoy033.png'),
    "[困]": require('@/assets/images/enjoy/enjoy034.png'),
    "[冷汗]": require('@/assets/images/enjoy/enjoy035.png'),
    "[右哼哼]": require('@/assets/images/enjoy/enjoy036.png'),
    "[左哼哼]": require('@/assets/images/enjoy/enjoy037.png'),
    "[嘘]": require('@/assets/images/enjoy/enjoy038.png'),
    "[么么哒]": require('@/assets/images/enjoy/enjoy039.png'),
    "[尴尬]": require('@/assets/images/enjoy/enjoy040.png'),
    "[惊恐]": require('@/assets/images/enjoy/enjoy41.png'),
    "[流汗]": require('@/assets/images/enjoy/enjoy042.png'),
    "[流泪]": require('@/assets/images/enjoy/enjoy043.png'),
    "[撇嘴]": require('@/assets/images/enjoy/enjoy044.png'),
    "[难过]": require('@/assets/images/enjoy/enjoy045.png'),
    "[糗大了]": require('@/assets/images/enjoy/enjoy046.png'),
    "[色]": require('@/assets/images/enjoy/enjoy047.png'),
    "[敲打]": require('@/assets/images/enjoy/enjoy048.png'),
    "[睡觉]": require('@/assets/images/enjoy/enjoy049.png'),
    "[吐]": require('@/assets/images/enjoy/enjoy050.png'),
    "[爱情]": require('@/assets/images/enjoy/enjoy051.png'),
    "[棒棒糖]": require('@/assets/images/enjoy/enjoy052.png'),
    "[抱抱]": require('@/assets/images/enjoy/enjoy053.png'),
    "[爆筋]": require('@/assets/images/enjoy/enjoy054.png'),
    "[鞭炮]": require('@/assets/images/enjoy/enjoy055.png'),
    "[便便]": require('@/assets/images/enjoy/enjoy056.png'),
    "[彩带]": require('@/assets/images/enjoy/enjoy057.png'),
    "[彩球]": require('@/assets/images/enjoy/enjoy058.png'),
    "[菜刀]": require('@/assets/images/enjoy/enjoy059.png'),
    "[钞票]": require('@/assets/images/enjoy/enjoy060.png'),
    "[车厢]": require('@/assets/images/enjoy/enjoy061.png'),
    "[蛋糕]": require('@/assets/images/enjoy/enjoy062.png'),
    "[刀]": require('@/assets/images/enjoy/enjoy063.png'),
    "[灯泡]": require('@/assets/images/enjoy/enjoy064.png'),
    "[凋谢]": require('@/assets/images/enjoy/enjoy065.png'),
    "[多云]": require('@/assets/images/enjoy/enjoy066.png'),
    "[发抖]": require('@/assets/images/enjoy/enjoy067.png'),
    "[飞机]": require('@/assets/images/enjoy/enjoy068.png'),
    "[飞吻]": require('@/assets/images/enjoy/enjoy069.png'),
    "[风车]": require('@/assets/images/enjoy/enjoy070.png'),
    "[红灯笼]": require('@/assets/images/enjoy/enjoy071.png'),
    "[红双喜]": require('@/assets/images/enjoy/enjoy072.png'),
    "[挥手]": require('@/assets/images/enjoy/enjoy073.png'),
    "[回头]": require('@/assets/images/enjoy/enjoy074.png'),
    "[激动]": require('@/assets/images/enjoy/enjoy075.png'),
    "[街舞]": require('@/assets/images/enjoy/enjoy076.png'),
    "[咖啡]": require('@/assets/images/enjoy/enjoy077.png'),
    "[磕头]": require('@/assets/images/enjoy/enjoy078.png'),
    "[骷髅]": require('@/assets/images/enjoy/enjoy079.png'),
    "[蜡烛]": require('@/assets/images/enjoy/enjoy080.png'),
    "[篮球]": require('@/assets/images/enjoy/enjoy081.png'),
    "[礼品袋]": require('@/assets/images/enjoy/enjoy082.png'),
    "[礼物]": require('@/assets/images/enjoy/enjoy083.png'),
    "[麻将]": require('@/assets/images/enjoy/enjoy084.png'),
    "[麦克风]": require('@/assets/images/enjoy/enjoy085.png'),
    "[猫咪]": require('@/assets/images/enjoy/enjoy086.png'),
    "[玫瑰]": require('@/assets/images/enjoy/enjoy087.png'),
    "[米饭]": require('@/assets/images/enjoy/enjoy088.png'),
    "[面条]": require('@/assets/images/enjoy/enjoy089.png'),
    "[奶瓶]": require('@/assets/images/enjoy/enjoy090.png'),
    "[闹钟]": require('@/assets/images/enjoy/enjoy091.png'),
    "[怒]": require('@/assets/images/enjoy/enjoy092.png'),
    "[怄火]": require('@/assets/images/enjoy/enjoy093.png'),
    "[皮球]": require('@/assets/images/enjoy/enjoy094.png'),
    "[啤酒]": require('@/assets/images/enjoy/enjoy095.png'),
    "[瓢虫]": require('@/assets/images/enjoy/enjoy096.png'),
    "[乒乓]": require('@/assets/images/enjoy/enjoy097.png'),
    "[汽车]": require('@/assets/images/enjoy/enjoy098.png'),
    "[强]": require('@/assets/images/enjoy/enjoy099.png'),
    "[拳头]": require('@/assets/images/enjoy/enjoy100.png'),
    "[弱]": require('@/assets/images/enjoy/enjoy101.png'),
    "[青蛙]": require('@/assets/images/enjoy/enjoy102.png'),
    "[沙发]": require('@/assets/images/enjoy/enjoy103.png'),
    "[删除]": require('@/assets/images/enjoy/enjoy104.png'),
    "[闪电]": require('@/assets/images/enjoy/enjoy105.png'),
    "[胜利]": require('@/assets/images/enjoy/enjoy106.png'),
    "[示爱]": require('@/assets/images/enjoy/enjoy107.png'),
    "[手枪]": require('@/assets/images/enjoy/enjoy108.png'),
    "[衰]": require('@/assets/images/enjoy/enjoy109.png'),
    "[太阳]": require('@/assets/images/enjoy/enjoy110.png'),
    "[跳绳]": require('@/assets/images/enjoy/enjoy111.png'),
    "[跳跳]": require('@/assets/images/enjoy/enjoy112.png'),
    "[委屈]": require('@/assets/images/enjoy/enjoy113.png'),
    "[握手]": require('@/assets/images/enjoy/enjoy114.png'),
    "[西瓜]": require('@/assets/images/enjoy/enjoy115.png'),
    "[下雨]": require('@/assets/images/enjoy/enjoy116.png'),
    "[吓]": require('@/assets/images/enjoy/enjoy117.png'),
    "[献吻]": require('@/assets/images/enjoy/enjoy118.png'),
    "[香蕉]": require('@/assets/images/enjoy/enjoy119.png'),
    "[象棋]": require('@/assets/images/enjoy/enjoy120.png'),
    "[心碎了]": require('@/assets/images/enjoy/enjoy121.png'),
    "[信封]": require('@/assets/images/enjoy/enjoy122.png'),
    "[熊猫]": require('@/assets/images/enjoy/enjoy123.png'),
    "[药]": require('@/assets/images/enjoy/enjoy124.png'),
    "[疑问]": require('@/assets/images/enjoy/enjoy125.png'),
    "[阴险]": require('@/assets/images/enjoy/enjoy126.png'),
    "[右车头]": require('@/assets/images/enjoy/enjoy127.png'),
    "[右太极]": require('@/assets/images/enjoy/enjoy128.png'),
    "[雨伞]": require('@/assets/images/enjoy/enjoy129.png'),
    "[月亮]": require('@/assets/images/enjoy/enjoy130.png'),
    "[晕]": require('@/assets/images/enjoy/enjoy131.png'),
    "[再见]": require('@/assets/images/enjoy/enjoy132.png'),
    "[炸弹]": require('@/assets/images/enjoy/enjoy133.png'),
    "[折磨]": require('@/assets/images/enjoy/enjoy134.png'),
    "[纸巾]": require('@/assets/images/enjoy/enjoy135.png'),
    "[咒骂]": require('@/assets/images/enjoy/enjoy136.png'),
    "[猪头]": require('@/assets/images/enjoy/enjoy137.png'),
    "[抓狂]": require('@/assets/images/enjoy/enjoy138.png'),
    "[转圈]": require('@/assets/images/enjoy/enjoy139.png'),
    "[钻戒]": require('@/assets/images/enjoy/enjoy140.png'),
    "[左车头]": require('@/assets/images/enjoy/enjoy141.png'),
    "[左太极]": require('@/assets/images/enjoy/enjoy142.png'),
}
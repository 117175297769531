<template>
  <!-- 聊天弹窗 -->
  <el-dialog
    :visible.sync="showMsgCom"
    width="40%"
    :before-close="handleClose"
    custom-class="msg_dialog"
    append-to-body
    :show-close="false"
  >
    <el-tabs v-model="activeNav" class="my-tabs" @tab-click="tabClick">
      <el-tab-pane
        v-for="(item, index) in tabLabel"
        :key="index"
        :name="item.value"
        :label="item.name"
      >
      </el-tab-pane>
    </el-tabs>
    <div class="main_box">
      <!-- 聊天 -->
      <template v-if="activeNav == 1"><Chat /></template>
      <!-- 消息 -->
      <template v-if="activeNav == 2"><Msg /></template>
      <!-- 朋友 -->
      <template v-if="activeNav == 3"
        ><Friend @changeActive="changeActive"
      /></template>
      <!-- 粉丝 -->
      <template v-if="activeNav == 4"><Fans /></template>
      <!-- 关注 -->
      <template v-if="activeNav == 5"><Attention /></template>
    </div>
    <div class="close" @click="handleClose"><i class="el-icon-close"></i></div>
  </el-dialog>
</template>
<script>
import Msg from "@/components/MsgCom/Msg";
import Friend from "@/components/MsgCom/Friend";
import Fans from "@/components/MsgCom/Fans";
import Chat from "@/components/MsgCom/Chat";
import Attention from "@/components/MsgCom/Attention";
export default {
  props: {
    showMsgCom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeNav: "1",
      tabLabel: [
        {
          name: "私聊",
          value: "1",
        },
        {
          name: "消息",
          value: "2",
        },
        {
          name: "朋友",
          value: "3",
        },
        {
          name: "粉丝",
          value: "4",
        },
        {
          name: "关注",
          value: "5",
        },
      ],
    };
  },
  methods: {
    handleClose() {
      this.$emit("MsgDialogFun", false);
    },
    tabClick(e) {
      console.log(e);
      if (this.activeNav == e.name) return;
      this.activeNav = e.name;
    },
    changeActive(i) {
      this.activeNav = i;
    },
  },
  components: {
    Msg,
    Friend,
    Fans,
    Chat,
    Attention,
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
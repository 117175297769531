<template>
  <el-dialog
    title="联系客服"
    :visible.sync="showCusService"
    width="40%"
    append-to-body
    custom-class="cus_servive"
    :before-close="handleClose"
  >
    <div class="pop_text customer">
      <div class="img_box">
        <img src="@/assets/images/concat.png" alt="" />
      </div>
      <div class="text_item">
        <span>如需联系客服请拨打：</span> 400-631-3398
      </div>
      <div class="text_item"><span>咨询时间：</span> 7:30-22:30</div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    showCusService: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      property: "value",
    };
  },
  methods: {
    handleClose() {
      this.$emit("serviceFun", false);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .cus_servive {
  .el-dialog__header {
    text-align: center;

    .el-dialog__title {
      color: #fff;
      font-size: 24px;
    }
  }

  .el-dialog__body {
    // min-height: 40vh;
    // max-height: 60vh;
    // overflow-y: auto;
    padding: 0 30px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d5d5d5;
    }
  }
  .pop_text {
    color: #fff;
    max-height: 40vh;
    padding-bottom: 20px;
    overflow-y: auto;

    .img_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 40px 0;

      img {
        display: block;
        width: 200px;
      }
    }

    .text_item {
      padding: 0 40px;
      margin-bottom: 10px;

      span {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .customer {
    .text_item {
      text-align: center;
    }
  }
}
</style>
<template>
  <!-- 首页 -->
  <div class="home">
    <!-- 一级菜单 -->
    <div class="nav_box">
      <div class="nav_first" v-if="isLogin">
        <el-tabs v-model="activeFirst" class="my-tabs" @tab-click="tabClick">
          <el-tab-pane
            v-for="(item, index) in tebLabel"
            :key="index"
            :name="item.value"
            :label="item.name"
          >
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="nav_first" v-if="!isLogin">
        <el-tabs v-model="activeFirst" class="my-tabs" @tab-click="tabClick">
          <el-tab-pane
            v-for="(item, index) in tebLabel_n"
            :key="index"
            :name="item.value"
            :label="item.name"
          >
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 二级菜单 -->
      <div class="nav_second" :class="{ mt: !isLogin }">
        <el-tag
          class="el-tag"
          :class="item.id == cateListTypeId ? 'active' : ''"
          v-for="(item, index) in cateListType"
          :key="index"
          @click="clickCateListType(item)"
        >
          <span>
            {{ item.cateName }}
          </span>
        </el-tag>

        <div class="upload_btn" @click="uploadBtnClick">+ 上传</div>
      </div>
      <!-- 三级菜单 -->
      <div class="nav_three">
        <el-tag
          class="el-tag-theme"
          v-for="(item, index) in tagList"
          :key="index"
          @click="ClicktagList(item)"
        >
          <p>
            {{ item.tagName }}
          </p>
          <span :class="FunClass(item)"> </span>
        </el-tag>
      </div>
    </div>
    <div class="home_content" :class="{ pdb: showFooter }">
      <!-- 图片库 -->
      <template>
        <Picture
          v-if="cateListTypeId == 1"
          :tagMsg="tagMsg"
          @PictureSuccess="GetPictureSuccess"
          @Showtotal="Showtotal"
        />
      </template>
      <!-- 视频库 -->
      <template v-if="0"><Video /></template>
      <!-- 音频库 -->
      <template v-if="cateListTypeId == 3"
        ><Audio
          :AudioMsg="AudioMsg"
          @Showtotal="Showtotal"
          @AudioSuccess="GetAudioSuccess"
      /></template>
      <!-- 文字库 -->
      <template v-if="0">
        <Word />
      </template>
      <!-- 模型库 -->
      <template v-if="cateListTypeId == 5">
        <Model
          :ModeMsg="ModeMsg"
          @ModelSuccess="GetModelSuccess"
          @Showtotal="Showtotal"
        />
      </template>
    </div>
    <!-- 上传弹窗 -->
    <UpLoadDrawer
      :uploadDrawer="uploadDrawer"
      @uploadDrawerhandleClose="uploadDrawerhandleClose"
    />
    <!-- 页脚 -->
    <Footer :showFooter="showFooter" v-if="cateListTypeId == 1" />

      <Login :showLogin="showLogin" @closeLogin="loginPopChange" />
    <Detail
      :detailshow="detailshow"
      :detailId="detailId"
      :detailType="detailType"
      :PictureSuccess="PictureSuccess"
      :ModlSuccess="ModlSuccess"
      :AudiSuccess="AudiSuccess"
      @closeDetailDialog="DetailDialog"
    />
  </div>
</template>
<script>
import Detail from "@/components/Detail";
import Picture from "@/components/Home/Picture";
import Video from "@/components/Home/Video";
import Audio from "@/components/Home/Audio";
import Word from "@/components/Home/Word";
import Model from "@/components/Home/Model";
import Footer from "@/components/Footer";
import UpLoadDrawer from "@/components/Home/UpLoadDrawer";
import Login from "@/components/Login";
export default {
  data() {
    return {
      showLogin: false,
      closeFlag: 0,
      total: 1,
      ModeMsg: "",
      AudioMsg: "",
      detailshow: false, //展示详情弹窗
      detailId: 0, //要展示详情的ID
      detailType: 2, //详情类型  1素材 2 模型 3 视频
      // 图片信息
      PictureSuccess: {},
      // 模型信息
      ModlSuccess: {},
      AudiSuccess: {},
      page: 1,
      activeFirst: "1", //一级选中
      activeSecond: "0", //二级选中
      activeThree: "0", //三级选中
      // 一级菜单
      tebLabel: [
        {
          name: "素材库",
          value: "1",
        },
        {
          name: "收藏",
          value: "2",
        },
        {
          name: "我的",
          value: "0",
        },
        {
          name: "大赛作品",
          value: "3",
        },
      ],
      // 一级菜单(未登录)
      tebLabel_n: [
        {
          name: "素材库",
          value: "1",
        },
      ],
      // 二级菜单
      cateListType: [],
      //当前二级菜单Id
      cateListTypeId: 1,
      // 三级菜单
      tagList: [],
      // 当前选中三级菜单
      tagListItem: "111",
      tagModlItem: "",
      tagAudioItem: "",
      tagMsg: "",
      isName: 1,
      showFooter: true,
      uploadDrawer: false,
      flag: true,
      ModelFlag: true,
      socllBoott: 100,
      flagTwo: false,
      token: "",
      User: "",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 总页数
    Showtotal(e) {
      this.total = e;
      // console.log(123);
    },

    // 三级选中
    FunClass(e) {
      // this.flag=true
      // console.log(e, 33);
      // return
      if (this.cateListTypeId == 1) {
        if (this.tagListItem.id == e.id) {
          return "active";
        } else {
          return "";
        }
      } else if (this.cateListTypeId == 5) {
        if (this.tagModlItem.id == e.id) {
          return "active";
        } else {
          return "";
        }
      } else if (this.cateListTypeId == 3) {
        if (this.tagAudioItem.id == e.id) {
          return "active";
        } else {
          return "";
        }
      }
    },
    // 详情
    DetailDialog(i) {
      // console.log(11144);
      //  console.log(7778);
      // return
      // location.reload();
      this.detailshow = i;
      this.shwocateListType();
      this.ModlSuccess = {};
      this.PictureSuccess = {};
    },
    // 登录弹窗操作
    loginPopChange(i) {
      this.showLogin = i;
    },
    // 上传按钮点击
    uploadBtnClick() {
      // this.uploadDrawer = true;
      // console.log(111, this.token, this.User);
      if (
        localStorage.getItem("token") == null ||
        localStorage.getItem("User") == null
      ) {
        // this.$message.error("请先登录");
        // this.showLogin = i;
        this.loginPopChange(true);
        return;
      } else {
        this.uploadDrawer = true;
      }
    },
    // 上传弹窗关闭前
    uploadDrawerhandleClose(i) {
      this.uploadDrawer = false;
    },
    tabClick(e) {
      console.log("当前选中", this.activeFirst);
      this.page = 1;
      this.isName = e.name;
      this.flag = true;
      this.ModelFlag = true;
      this.tagListItemMsg();
    },
    // 滚动事件
    handleScroll() {
      if (this.visualViewport.pageTop > 300) {
        this.showFooter = false;
      } else if (this.visualViewport.pageTop == 0) {
        this.showFooter = true;
      }
    },
    //获取二级菜单
    shwocateListType() {
      this.$http({
        url: this.$http.adornUrl("/app/post/catePostPc"),
        method: "get",
        params: this.$http.adornParams({}),
      }).then((data) => {
        if (data.data.code === 0) {
          let msg = data.data;
          //  隐藏视频库
          let mge = [];
          msg.cateList.forEach((element) => {
            // console.log(element);
            if (element.id == 1 || element.id == 5 || element.id == 3) {
              mge.push(element);
            }
          });
          this.cateListType = mge;
          //  if()
          // if (this.cateListTypeId == 1) {
          //   this.tagListItem = this.cateListType[0]
          // } else if (this.cateListTypeId == 5) {
          //   this.tagModlItem = this.cateListType[0]
          // }

          //获取三级菜单
          this.showTablist();
        }
      });
    },
    // 点击二级菜单
    clickCateListType(e) {
      this.flagTwo = true;
      this.cateListTypeId = e.id;
      //  if()
      this.showTablist();
      this.flag = true;
      this.ModelFlag = true;
      //  this.ClicktagList()
      this.page = 1;

      // console.log(this.page, 3444);

      // console.log(this.socllBoott, 333);
      this.tagListItemMsg();
    },
    //获取三级菜单
    showTablist() {
      this.$http({
        url: this.$http.adornUrl("/app/post/tagPost"),
        method: "get",
        params: this.$http.adornParams({
          cateid: this.cateListTypeId,
        }),
      }).then((data) => {
        if (data.data.code === 0) {
          let msg = data.data.tagList;
          this.tagList = msg;
          this.tagList.unshift({
            cateid: 1,
            id: null,
            isEnable: 0,
            tagName: "全部",
          });
          // console.log(this.tagList, 345);
          if (this.cateListTypeId == 1) {
            this.tagListItem = this.tagList[0];
          } else if (this.cateListTypeId == 5) {
            this.tagModlItem = this.tagList[0];
          } else if (this.cateListTypeId == 3) {
            this.tagAudioItem = this.tagList[0];
          }
          this.tagListItemMsg();
        }
      });
    },

    // 下拉刷新
    showPull() {
      if (this.flagTwo) {
        this.flagTwo = false;
        return;
      }

      if (this.total == 0) {
        this.total = 1;
      }
      // let scrollTop = window.pageYOffset;
      // let viewportSize = window.innerHeight;

      this.socllBoott =
        document.documentElement.scrollHeight -
        document.documentElement.scrollTop -
        document.documentElement.clientHeight;

      // console.log(this.socllBoott, 2225);

      if (this.socllBoott < 50) {
        // console.log(this.socllBoott ,322);

        if (this.page <= this.total) {
          this.page++;
          // console.log(this.page, 32);
          // console.log(this.cateListTypeId, 2222);
          if (this.cateListTypeId == 1) {
            // console.log(1223);
            this.tagListItemMsg();
          } else if (this.cateListTypeId == 5) {
            this.tagListItemMsg();
          } else if (this.cateListTypeId == 3) {
            this.tagListItemMsg();
          }
        }
        // console.log(this.page,666);
      }
    },
    // 获取
    tagListItemMsg() {
      // console.log(this.page, 558);
      // console.log(66777);
      let tag = "";
      let cateid = "";
      if (this.cateListTypeId == 1) {
        tag = this.tagListItem;
        cateid = 1;
      } else if (this.cateListTypeId == 5) {
        tag = this.tagModlItem;
        cateid = 5;
      } else if (this.cateListTypeId == 3) {
        tag = this.tagAudioItem;

        cateid = 3;
      }
      // console.log(tag, 3547);

      //  是否公开
      let fun1 = () => {
        if (this.isName == 0) {
          return null;
        } else if (this.isName == 1) {
          return 0;
        } else if (this.isName == 2) {
          return 0;
        }
      };

      //素材类型：1图片、2文字、3视频、4音频、5模型
      let fun2 = () => {
        if (this.cateListTypeId == 1) {
          return 1;
        } else if (this.cateListTypeId == 2) {
          return 3;
        } else if (this.cateListTypeId == 3) {
          return 4;
        } else if (this.cateListTypeId == 4) {
          return 2;
        } else if (this.cateListTypeId == 5) {
          return 5;
        }
      };

      // return
      let Msg = {};

      if (localStorage.getItem("token") == null) {
        Msg = {
          page: this.page,

          scistate: this.isName == 2 ? 1 : "",
          //  是否公开
          isopen: fun1(),

          cateid: cateid,
          //题材分类
          tags: tag.id,
          isforcepe: 1,
          // type: fun2()
        };
      } else {
        Msg = {
          page: this.page,
          // 本人Id
          uid: JSON.parse(localStorage.getItem("User")).uid,
          //素材库判断：0.我的 1.素材库 2.收藏
          isMaterial: this.isName,

          // 收藏状态：0.未收藏 1.已收藏
          scistate: this.isName == 2 ? 1 : "",
          //  是否公开
          isopen: fun1(),

          //素材类型：1图片、2文字、3视频、4音频
          cateid: cateid,
          //题材分类
          tags: tag.id,
          type: fun2(),
          isforcepe: 1,
          // isonlyforcepe: 1,
        };
      }

      if (this.activeFirst == 3) {
        Msg["isonlyforcepe"] = 1;
      } else {
        Msg = Msg;
      }

      // console.log(this.cateListTypeId,3785);
      if (this.cateListTypeId == 1) {
        this.tagMsg = Msg;
        // console.log(14555);
      } else if (this.cateListTypeId == 5) {
        this.ModeMsg = Msg;
      } else if (this.cateListTypeId == 3) {
        // console.log(111);
        this.AudioMsg = Msg;
      }
    },

    //  三级菜单需要参数

    //点击三级菜单
    ClicktagList(e) {
      this.page = 1;
      if (this.cateListTypeId == 1) {
        this.tagListItem = e;
        this.flag = true;
      } else if (this.cateListTypeId == 5) {
        this.tagModlItem = e;
      } else if (this.cateListTypeId == 3) {
        this.tagAudioItem = e;
      }

      this.tagListItemMsg();
    },
    // 图片信息
    GetPictureSuccess(e) {
      console.log(12);

      this.PictureSuccess = e;
      this.detailshow = true;
      this.detailType = 1;
    },
    GetModelSuccess(e) {
      this.ModlSuccess = e;
      this.detailshow = true;
      this.detailType = 2;
    },
    GetAudioSuccess(e) {
      this.AudiSuccess = e;
      this.detailshow = true;
      this.detailType = 4;
      console.log(this.AudiSuccess, 3758);
    },
  },

  created() {
    window.addEventListener("scroll", this.showPull);
    this.shwocateListType();
  },
  computed: {
    isLogin() {
      return localStorage.getItem("token");
    },
    visualViewport() {
      // console.log("可视区域", window.visualViewport);
      return window.visualViewport;
    },
  },
  components: {
    Picture,
    Video,
    Audio,
    Word,
    Model,
    Footer,
    UpLoadDrawer,
    Detail,
    Login,
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>

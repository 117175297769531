<template>
  <el-drawer
    title="上传素材"
    :visible.sync="uploadDrawer"
    direction="rtl"
    :before-close="uploadDrawerhandleClose"
    append-to-body
    size="40%"
  >
    <div class="drawer_box">
      <el-tabs v-model="activeType" class="my-tabs" @tab-click="tabClick">
        <el-tab-pane
          v-for="(item, index) in tebLabel"
          :key="index"
          :name="item.value"
          :label="item.name"
        >
        </el-tab-pane>
      </el-tabs>
      <div class="form_box">
        <Picture
          v-if="activeType == 1"
          :ImageType="ImageType"
          :activeType="activeType"
          @curNoDropFlgFun="curNoDropFlgFun"
        />
        <Video v-if="activeType == 2" />
        <Audio
          v-if="activeType == 3"
          :AudioType="AudioType"
          :activeType="activeType"
          @AudioCurNoDropFlgFun="AudioCurNoDropFlgFun"
        />
        <Word v-if="activeType == 4" />
        <Model
          v-if="activeType == 5"
          :ModelTye="ModelTye"
          :activeType="activeType"
          @ModelCurNoDropFlgFun="ModelCurNoDropFlgFun"
        />
      </div>
      <div class="handle_box">
        <!-- <div class="hand_btn cancel" @click="uploadDrawerhandleClose">取消</div>
        <div class="hand_btn confirm" @click="Submit()">提交素材</div> -->
        <el-button
          class="hand_btn cancel"
          @click="uploadDrawerhandleClose"
          style="color: red !important"
          >取消</el-button
        >
        <el-button
          style="background-color: #e76751"
          :disabled="curNoDropFlg == false ? true : false"
          type="primary"
          @click="Submit()"
          >提交素材</el-button
        >
      </div>
    </div>
  </el-drawer>
</template>

<script>
import Picture from "./Picture";
import Audio from "./Audio";
import Model from "./Model";
import Video from "./Video";
import Word from "./Word";
export default {
  props: {
    uploadDrawer: {
      type: Boolean,
      default: false,
    },
    activeTypes: String,
  },
  data() {
    return {
      ModelTye: 0,
      ImageType: 0,
      AudioType: 0,
      activeType: "1",
      // 一级菜单
      tebLabel: [
        {
          name: "图片",
          value: "1",
        },
        {
          name: "视频",
          value: "2",
        },
        {
          name: "音乐",
          value: "3",
        },
        {
          name: "文字",
          value: "4",
        },
        {
          name: "模型",
          value: "5",
        },
      ],
      curNoDropFlg: true,
    };
  },
  methods: {
    // 类型选择
    tabClick(e) {
      // console.log(e);
      this.activeType = e.name;
      // console.log(this.activeType, 666);
    },
    // 关闭
    uploadDrawerhandleClose() {
      this.$emit("uploadDrawerhandleClose", false);
    },
    Submit() {
      if (this.activeType == 1) {
        this.ImageType++;
      } else if (this.activeType == 5) {
        this.ModelTye++;
      } else if (this.activeType == 3) {
        this.AudioType++;
      }
    },
    // 图片防止多次点击上传
    curNoDropFlgFun(i) {
      this.curNoDropFlg = i;
    },
    // 模型防止多次点击上传
    ModelCurNoDropFlgFun(i) {
      this.curNoDropFlg = i;
    },
    // 音频防止多次点击上传
    AudioCurNoDropFlgFun(i) {
      this.curNoDropFlg = i;
    },
  },
  components: {
    Picture,
    Audio,
    Model,
    Video,
    Word,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-drawer {
  background-color: var(--secondary) !important;

  .el-drawer__header {
    font-size: 20px;
    padding: 20px;
    margin-bottom: 0;
  }

  .el-drawer__body {
    border-top: 1px solid #44464a;

    .drawer_box {
      .my-tabs {
        position: sticky;
        top: 0;
        padding: 10px 20px 0;
        background-color: var(--secondary);
        z-index: 99;
      }

      .form_box {
        padding: 20px 20px 90px;
      }

      .handle_box {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        border-top: 1px solid #44464a;
        align-items: center;
        justify-content: center;
        padding: 16px 0;
        background-color: var(--secondary);

        .hand_btn {
          // padding: 8px 20px;
          margin: 0 20px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .cancel {
          background-color: #555761;

          &:hover {
            background-color: #5c5e66;
          }
        }

        .confirm {
          background-color: var(--redary);

          &:hover {
            background-color: #e76751;
          }
        }
      }
    }
  }
}

::v-deep .el-tabs {
  .el-tabs__active-bar {
    background-color: var(--redary);
  }

  .el-tabs__item {
    font-size: 16px;
    color: var(--white);
  }

  .el-tabs__nav-wrap::after {
    height: 0;
  }
}

// 13寸
@media screen and (max-width: 1366px) {
  ::v-deep .el-drawer {
    width: 50% !important;
  }
}

// 15.6寸

@media screen and (max-width: 1920px) and (min-width: 1367px) {
  ::v-deep .el-drawer {
    width: 40% !important;
  }
}

// 大于15.6
@media screen and (min-width: 1921px) {
  ::v-deep .el-drawer {
    width: 30%;
  }
}
</style>

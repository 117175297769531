<template>
  <!-- 私聊 -->
  <div class="private_chat">
    <ul class="u_list">
      <li
        class="u_item"
        v-for="item in privateChat"
        :class="{ active: activePrivate.id == item.id }"
        :key="item.friend_id"
        @click="friendItemClick(item)"
      >
        <div class="u_img">
          <img :src="item.avatar" :alt="item.friend_name" />
        </div>
        <div class="u_info_box">
          <div class="u_name text-overflow">{{ item.friend_name }}</div>
          <div class="u_msg text-overflow">
            {{ item.lastMsgData && item.lastMsgData.content }}
          </div>
        </div>
        <div
          class="hint"
          v-if="item.lastMsgData && item.lastMsgData.rdfecnt == 0"
        >
          <img src="@/assets/images/Group 82@2x.png" alt="新消息" />
        </div>
      </li>
    </ul>
    <div class="chat_right">
      <!-- 消息展示 -->
      <div class="show_msg_box" ref="msg_box">
        <MsgTemplate
          :chattingRecordsList="activePrivateMsg"
          :aiImg="aiImg"
          :showLoadMore="isLoadMore"
          :showLoadAll="showLoadAll"
          :showNewMessage="showNewMessage"
          @showNewMessageFun="showNewMessageFun"
          @loadMoreMsg="loadMore"
          v-if="activePrivateMsg.length"
          ref="msgTemCom"
        />
        <template v-else>
          <div class="empty_box">暂无历史消息</div>
        </template>
      </div>
      <!-- 发送框 -->
      <SendCom
        @aiStyleText="aiStyleText"
        @SendMsgClick="SendMsgClick"
        :sendBoxPlaceholder="sendBoxPlaceholder"
        ref="sendBox"
        @sendImg="sendImg"
        @sendFile="sendFile"
        @sendShare="sendShare"
        @disabledFlg="disabledFlg"
        :disabled="disabled"
        :friendItem="friendItem"
        :num="2"
      />
    </div>
  </div>
</template>
<script>
import MsgTemplate from "@/components/MsgTemplate";
import SendCom from "@/components/MsgTemplate/SendCom";
import { sendSock } from "@/utils/ws.js";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      sendVal: "", //输入框发送值
      aiImg: [],
      sendMsg: "", //机器人回复消息
      aiTimes: null,
      sendMessageType: "", //机器人回复消息类型
      disabled: false, //输入框是否禁用
      friendItem: {}, //单个好友点击
      showNewMessage: false,
      showLoadAll: false,
      isLoadMore: false,
      aiStyleTexts: {},
      sdImageTime: null,
    };
  },
  created() {
    // this.getAllFriend();
  },
  mounted() {
    // this.friendItemClick(this.privateChat[0]);
    let waitLoadAiMsgList = localStorage.getItem("waitLoadAiMsgList");
    if (!waitLoadAiMsgList) {
      localStorage.setItem("waitLoadAiMsgList", JSON.stringify([]));
    }
  },
  methods: {
    loadMore() {
      if (this.isLoadMore == true) return;
      if (this.personMSgSorter.pageNum != this.personMSgSorter.recordTotal) {
        let page = ++this.personMSgSorter.pageNum;

        this.isLoadMore = true;
        this.setPersonMSgSorter({
          page,
          size: this.personMSgSorter.pageSize,
          total: this.personMSgSorter.recordTotal,
        });
        this.getAllRecordList(this.activePrivate);
        // this.$refs.msgTemCom.setScrollTop();
      } else {
        this.showLoadAll = true;
        setTimeout(() => {
          this.showLoadAll = false;
        }, 1000);
      }
    },
    showNewMessageFun(e) {
      this.showNewMessage = e;
    },
    // 发送消息回调函数
    sendCallback(e, data) {
      // 已关闭连接
      if (e == 3 || e == 0 || e == 2) {
        console.log("已关闭", "发送" + data);
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        setTimeout(() => {
          sendSock(data, this.sendCallback);
          loading.close();
        }, 2000);
      } else {
        console.log("已发送", data);
      }
    },
    // 发素材
    sendShare(data) {
      let time = new Date().getTime();
      let sendParams = {
        type: "person-message",
        data: {
          sessionId: this.activePrivate.sessionId,
          senderId: this.userInfo.uid,
          senderName: this.userInfo.username,
          senderAvatar: this.userInfo.avatar,
          receiverId: this.activePrivate.friend_id,
          receiverName:
            this.activePrivate.notation == "" ||
            this.activePrivate.notation == null ||
            this.activePrivate.notation == undefined
              ? this.activePrivate.friend_name
              : this.activePrivate.notation,
          receiverAvatar: this.activePrivate.avatar,
          sendTime: time,
          content: data,
          messageType: "share",
        },
      };
      sendSock(sendParams, this.sendCallback);
      this.$message.success("素材发送成功");
    },
    // 单个好友点击
    friendItemClick(item) {
      this.friendItem = item;
      if (this.activePrivate == item) return;
      this.setACtivePreson(item);
      //  设置消息已读
      // this.setMsgRead(item);
    },
    //选择发送文件 img
    sendImg(file) {
      this.$http({
        url: this.$http.adornUrl("/app/common/upload"),
        method: "post",
        data: file,
        headers: {
          "Content-Type": "multipart/form-data; boundary=----",
        },
      }).then(({ data }) => {
        if (data.code == 0) {
          let time = new Date().getTime();
          let sendParams = {
            type: "person-message",
            data: {
              sessionId: this.activePrivate.sessionId,
              senderId: this.userInfo.uid,
              senderName: this.userInfo.username,
              senderAvatar: this.userInfo.avatar,
              receiverId: this.activePrivate.friend_id,
              receiverName:
                this.activePrivate.notation == "" ||
                this.activePrivate.notation == null ||
                this.activePrivate.notation == undefined
                  ? this.activePrivate.friend_name
                  : this.activePrivate.notation,
              receiverAvatar: this.activePrivate.avatar,
              sendTime: time,
              content: data.result,
              messageType: "imgfile",
            },
          };
          sendSock(sendParams, this.sendCallback);
        } else {
          this.$message.error("发送图片失败");
        }
      });
    },
    aiStyleText(data) {
      this.aiStyleTexts = data;
    },
    // 机器人发送禁用
    disabledFlg(i) {
      this.disabled = i;
    },
    //选择发送文件 file
    sendFile(file) {
      console.log(file, "114");
    },
    // 私聊发送
    SendMsgClick(msg) {
      let time = new Date().getTime();
      this.sendVal = msg;

      let sendParams = {
        type: "person-message",
        data: {
          sessionId: this.activePrivate.sessionId,
          senderId: this.userInfo.uid,
          senderName: this.userInfo.username,
          senderAvatar: this.userInfo.avatar,
          receiverId: this.activePrivate.friend_id,
          receiverName: this.activePrivate.friend_name,
          receiverAvatar: this.activePrivate.avatar,
          sendTime: time,
          content: msg,
          messageType: "text",
        },
      };
      // console.log(sendParams, "私聊");
      sendSock(sendParams, this.sendCallback);
      if (this.activePrivate.friend_id == 6) {
        // 判断是否是指令消息;
        if (msg.indexOf("/w") !== -1) {
          this.getAiText();
        }
        // return;
        if (msg.indexOf("/t") !== -1) {
          this.getAiImg();
        }
        if (msg.indexOf("/s") !== -1) {
          this.getAiSdImg();
        }
      }
      this.$refs.sendBox.sendVal = "";
    },

    getAiText() {
      // let extractedText = this.sendVal.slice(prefix.length);
      let prefixMsg = this.sendVal.trim().slice(0, 2);
      let promptMsg = this.sendVal.slice(2).trim();
      this.$http({
        url: this.$http.adornUrl("/ai/chat1"),
        method: "get",
        params: this.$http.adornParams({
          prefix: prefixMsg,
          prompt: promptMsg,
          userId: this.userInfo.uid,
        }),
      }).then(({ data }) => {
        if (data.code == 500) {
          // 次数不够
          this.sendMessageType = "needpay";
          this.sendMsg =
            "您的钱包余额不足，如需使用TWYiA机器人，请充值 或购买套餐。";
          this.disabled = false;
        } else if (data.code == 200) {
          if (data.message == "" || data.message == null) {
            this.sendMsg = "很抱歉，我没有搜索到相关内容，请换一个话题";
          } else {
            this.sendMsg = data.message;
          }
          this.sendMessageType = "text";
        }
        let time = new Date().getTime();
        let sessionId =
          Math.min(this.userInfo.uid, 6).toString() +
          Math.max(this.userInfo.uid, 6).toString();
        let sendParams = {
          type: "person-message",
          data: {
            sessionId: "-" + sessionId,
            senderId: 6,
            // senderName: this.userInfo.username + "的Ai助手",//ibot_TWYiA_1.0
            senderName: "ibot_TWYiA_1.0", //ibot_TWYiA_1.0
            senderAvatar:
              "https://qiniu.twyia.com/duiya/20230830/1427681530564f7786a8f8682471700a.png",
            receiverId: this.userInfo.uid,
            receiverName: this.userInfo.username,
            receiverAvatar: this.userInfo.avatar,
            sendTime: time,
            content: this.sendMsg,
            messageType: this.sendMessageType,
          },
        };

        sendSock(sendParams, this.sendCallback);
        this.disabled = false;
      });
    },
    // /t 指令调用机器人（图片）
    getAiImg() {
      // let extractedText = this.sendVal.slice(prefix.length);
      let prefixMsg = this.sendVal.trim().slice(0, 2);
      let promptMsg = this.sendVal.slice(2).trim();
      let time = new Date().getTime();
      let ids = this.aiStyleTexts.ids; // /t一级菜单 ids
      let styleType = this.aiStyleTexts.styleType; // 二级菜单 模型风格
      // let styleArr = this.aiStyleTexts.styleArr;
      if (styleType == undefined || styleType == "" || styleType == null) {
        this.$message.error("请选择类型风格");
        this.disabled = false;
        return;
      }
      let sessionId =
        Math.min(this.userInfo.uid, 6).toString() +
        Math.max(this.userInfo.uid, 6).toString();
      this.$http({
        url: this.$http.adornUrl("/ai/chat1"),
        method: "get",
        params: this.$http.adornParams({
          prefix: prefixMsg,
          prompt: promptMsg,
          userId: this.userInfo.uid,
          style: styleType,
        }),
      }).then(({ data }) => {
        if (data.code === 500) {
          // debugger;
          this.sendMsg = data.msg;
          // return;
          this.disabled = false;
        } else if (data.code === 200) {
          if (data.message == "" || data.message == null) {
            this.aiNewMeg = "很抱歉，我没有搜索到相关内容，请换一个话题";
          } else {
            this.aiNewMeg = data.message;
          }
        }
        // if (data.code == 200) {
        if (data.data == null) {
          if (data.message == "" || data.message == null) {
            this.sendMsg = data.msg;
            this.sendMessageType = "text";
          } else {
            this.sendMsg = data.message;
            this.sendMessageType = "text";
          }
          let sendPerson = {
            type: "person-message",
            data: {
              sessionId: "-" + sessionId,
              senderId: 6,
              senderName: "ibot_TWYiA_1.0",
              senderAvatar:
                "https://qiniu.twyia.com/duiya/20230830/1427681530564f7786a8f8682471700a.png",
              receiverId: this.userInfo.uid,
              receiverName: this.userInfo.username,
              receiverAvatar: this.userInfo.avatar,
              sendTime: time,
              content: this.sendMsg,
              messageType: this.sendMessageType,
            },
          };
          sendSock(sendPerson, this.sendCallback);
          this.disabled = false;
        } else {
          if (
            data.data.error_msg !== undefined &&
            data.data.error_msg !== null
          ) {
            let error_msg = data.data.error_msg;
            this.disabled = false;
            // return this.$message.error(`${error_msg}`);
            return this.$message.error("请勿输入敏感字符");
          } else {
            if (
              data.data.data !== null &&
              data.data.data !== undefined &&
              prefixMsg == "/t"
            ) {
              let sendPerson = {
                type: "person-message",
                data: {
                  sessionId: "-" + sessionId,
                  senderId: 6,
                  senderName: "ibot_TWYiA_1.0",
                  senderAvatar:
                    "https://qiniu.twyia.com/duiya/20230830/1427681530564f7786a8f8682471700a.png",
                  receiverId: this.userInfo.uid,
                  receiverName: this.userInfo.username,
                  receiverAvatar: this.userInfo.avatar,
                  sendTime: time,
                  content: "",
                  messageType: "img",
                },
              };
              sendSock(sendPerson, this.sendCallback);

              let waitLoadAiMsgList = JSON.parse(
                localStorage.getItem("waitLoadAiMsgList")
              );
              if (!waitLoadAiMsgList) {
                localStorage.setItem("waitLoadAiMsgList", JSON.stringify([]));
              }
              let obj = {
                taskId: data.data.data.taskId,
                msg: "",
              };
              waitLoadAiMsgList.push(obj);
              localStorage.setItem(
                "waitLoadAiMsgList",
                JSON.stringify(waitLoadAiMsgList)
              );
              this.taskId = data.data.data.taskId;
              // this.getImg(data.data.data.taskId);
              // return;
              this.aiTimes = setTimeout(() => {
                this.getImg(data.data.data.taskId);
              }, 15000);
            } else {
              if (data.data.data == null && data.data.data == undefined) {
                this.disabled = false;
                return this.$message.error(
                  "很抱歉，我没有搜索到相关信息，请重新输入内容！"
                );
              }
            }
          }
        }
        // }
      });
    },
    // /t ai查询图片链接 /ai/img1 /ai/chatImg2
    getImg(taskId) {
      let time = new Date().getTime();
      // return
      let waitLoadAiMsgList = JSON.parse(
        localStorage.getItem("waitLoadAiMsgList")
      );
      let end_id = waitLoadAiMsgList.filter((item) => {
        return item.taskId == taskId;
      })[0].msg.data.id;
      this.$http({
        url: this.$http.adornUrl("/ai/chatImg2"),
        method: "get",
        params: this.$http.adornParams({
          userId: this.userInfo.uid,
          taskId: taskId,
          messageId: end_id,
        }),
      }).then(({ data }) => {
        let aiImageUrl = "xiaoxi";
        if (data.code == 200) {
          // this.aiImg = data.message.split(",");
          let arr1 = [];
          let arr2 = [];
          arr1.push(data.message.split(",")[1]);
          arr1.push(data.message.split(",")[2]);
          arr2 = arr1;
          arr2.join(",");
          let sendPerson = {
            type: "end-load-message",
            data: {
              id: end_id,
              content: arr2.join(","),
              receiverId: this.userInfo.uid,
            },
          };
          this.activePrivateMsg.forEach((item) => {
            if (data.message.split(",")[0] == item.id) {
              item.content = arr2.join(",");
            }
          });
          sendSock(sendPerson, this.sendCallback);
          this.disabled = false;
        } else {
          aiImageUrl = data.msg;
          this.disabled = false;
          this.$message.error(data.msg);
        }
        clearTimeout(this.aiTimes);
      });
    },
    // prompt sdImg  快速出图  /s
    getAiSdImg(prefix) {
      // let extractedText = this.sendVal.slice(prefix.length);
      //  let prefixMsg = this.sendVal.trim().slice(0, 2);
      // let promptMsg = this.sendVal.slice(2).trim();
      // this.extractedText = extractedText;
      this.$http({
        url: this.$http.adornUrl("/ai/sdImgCheck"),
        method: "get",
        params: this.$http.adornParams({
          prompt: this.sendVal,
          userId: this.userInfo.uid,
        }),
      }).then(({ data }) => {
        if (data.code == 200) {
          let time = new Date().getTime();
          let sessionId =
            Math.min(this.userInfo.uid, 6).toString() +
            Math.max(this.userInfo.uid, 6).toString();
          let sendParams = {
            type: "person-message",
            data: {
              sessionId: "-" + sessionId,
              senderId: 6,
              // senderName: this.userInfo.username + "的Ai助手",//ibot_TWYiA_1.0
              senderName: "ibot_TWYiA_1.0", //ibot_TWYiA_1.0
              senderAvatar:
                "https://qiniu.twyia.com/duiya/20230830/1427681530564f7786a8f8682471700a.png",
              receiverId: this.userInfo.uid,
              receiverName: this.userInfo.username,
              receiverAvatar: this.userInfo.avatar,
              sendTime: time,
              content: "",
              messageType: "img",
            },
          };
          sendSock(sendParams, this.sendCallback);

          let waitLoadAiMsgList = JSON.parse(
            localStorage.getItem("waitLoadAiMsgList")
          );
          let obj = {
            taskId: time,
            msg: "",
          };
          if (!waitLoadAiMsgList) {
            localStorage.setItem("waitLoadAiMsgList", JSON.stringify([]));
          }
          waitLoadAiMsgList.push(obj);
          localStorage.setItem(
            "waitLoadAiMsgList",
            JSON.stringify(waitLoadAiMsgList)
          );

          this.sdImageTime = setTimeout(() => {
            this.getSdImage(time);
          }, 15000);
          this.disabled = false;
        } else {
          let time = new Date().getTime();
          let sessionId =
            Math.min(this.userInfo.uid, 6).toString() +
            Math.max(this.userInfo.uid, 6).toString();
          let sendParams = {
            type: "person-message",
            data: {
              sessionId: "-" + sessionId,
              senderId: 6,
              // senderName: this.userInfo.username + "的Ai助手",//ibot_TWYiA_1.0
              senderName: "ibot_TWYiA_1.0", //ibot_TWYiA_1.0
              senderAvatar:
                "https://qiniu.twyia.com/duiya/20230830/1427681530564f7786a8f8682471700a.png",
              receiverId: this.userInfo.uid,
              receiverName: this.userInfo.username,
              receiverAvatar: this.userInfo.avatar,
              sendTime: time,
              content:
                "您的钱包余额不足，如需使用TWYiA机器人，请充值 或购买套餐。",
              messageType: "needpay",
            },
          };

          sendSock(sendParams, this.sendCallback);
          this.disabled = false;
        }
      });
    },
    getSdImage(time) {
      let waitLoadAiMsgList = JSON.parse(
        localStorage.getItem("waitLoadAiMsgList")
      );
      let end_id = waitLoadAiMsgList.filter((item) => {
        return item.taskId == time;
      })[0].msg.data.id;

      this.$http({
        url: this.$http.adornUrl("/ai/getSdImg"),
        method: "get",
        params: this.$http.adornParams({
          prompt: this.sendVal,
          userId: this.userInfo.uid,
          messageId: end_id,
        }),
      }).then(({ data }) => {
        if (data.code == 200) {
          let arr1 = [];
          let arr2 = [];
          arr1.push(data.message.split(",")[1]);
          arr1.push(data.message.split(",")[2]);
          arr2 = arr1;
          arr2.join(",");

          let sendPerson = {
            type: "end-load-message",
            data: {
              id: end_id,
              content: arr2.join(","),
              receiverId: this.userInfo.uid,
            },
          };
          this.activePrivateMsg.forEach((item) => {
            if (data.message.split(",")[0] == item.id) {
              item.content = arr2.join(",");
            }
          });
          console.log(sendPerson, "发送end");
          this.activePrivateMsg.forEach((item) => {
            if (data.message.split(",")[0] == item.id) {
              item.content = arr2.join(",");
            }
          });
        } else {
          this.disabled = false;
          this.$message.error(data.msg);
        }
      });
    },
    // 素材图片查询
    // getImgById(id) {
    //   this.$http({
    //     url: this.$http.adornUrl("/ai/getSdImg"),
    //     method: "get",
    //     params: this.$http.adornParams({
    //       prompt: this.extractedText,
    //       userId: this.userInfo.uid,
    //       messageId: id,
    //     }),
    //   }).then(({ data }) => {
    //     if (data.code == 200) {
    //       // this.getAllHistoryRecord();
    //       this.getAllRecordList();
    //     }
    //   });
    // },
    // 消息已读
    setMsgRead(item) {
      let sendData = {
        type: "person-message-read-true",
        data: {
          receiverId: item.friend_id,
          sessionId: item.sessionId,
        },
      };
      sendSock(sendData, this.sendCallback);
    },

    ...mapActions([
      "setACtivePreson",
      "getAllFriend",
      "getAllRecordList",
      "setPersonMSgSorter",
    ]),
  },
  watch: {
    activePrivate: {
      handler(val) {
        this.isLoadMore = false;
        // 切换群组清除输入框数据
        this.$nextTick(() => {
          this.$refs.sendBox.$refs.chatTextarea.innerHTML = "";
          this.$refs.sendBox.sendVal = "";
        });
      },
      deep: true,
      immediate: true,
    },
    msg: {
      handler(val) {
        console.log(val);
        if (
          this.activePrivate.sessionId == val.data.sessionId &&
          val.type == "person-message"
        ) {
          // 自己发消息滑动到底部  / 新其他消息展示新消息提示
          if (this.activePrivateMsg.length) {
            if (val.data.senderId == this.userInfo.uid) {
              this.$refs.msgTemCom.scrollTobottom();
            } else if (this.$refs.msgTemCom.checkScrollBottom()) {
              this.$refs.msgTemCom.scrollTobottom();
            } else {
              this.showNewMessage = true;
            }
          }
          // this.recordList.push(val.data);
        }
        if (val.type == "end-load-message") {
          this.recordList.forEach((item) => {
            if (item.id == val.data.id) {
              item.content = val.data.content;
              let waitLoadAiMsgList = JSON.parse(
                localStorage.getItem("waitLoadAiMsgList")
              );
              waitLoadAiMsgList.forEach((item, index) => {
                if (item.msg.data.id == val.data.id) {
                  waitLoadAiMsgList.splice(index, 1);
                }
              });
              localStorage.setItem(
                "waitLoadAiMsgList",
                JSON.stringify(waitLoadAiMsgList)
              );
            }
          });
        }
        // 监听群Ai待加载消息
        if (
          val.data.content == "" &&
          val.data.messageType == "img" &&
          val.data.senderId == "6" &&
          val.type == "person-message"
        ) {
          let waitLoadAiMsgList = JSON.parse(
            localStorage.getItem("waitLoadAiMsgList")
          );
          if (!waitLoadAiMsgList) {
            localStorage.setItem("waitLoadAiMsgList", JSON.stringify([]));
          }
          if (
            waitLoadAiMsgList[waitLoadAiMsgList.length - 1].msg == "" &&
            val.data.receiverId == this.userInfo.uid
          ) {
            waitLoadAiMsgList[waitLoadAiMsgList.length - 1].msg = val;
            localStorage.setItem(
              "waitLoadAiMsgList",
              JSON.stringify(waitLoadAiMsgList)
            );
          }
          // console.log(waitLoadAiMsgList, "设置Ai待加载Tasid项的msg");
        }
      },
      // immediate: true,
      // deep: true,
    },
    activePrivateMsg: {
      handler(val) {
        this.isLoadMore = false;
        if (val) {
          this.$nextTick(() => {
            const msg_box = this.$refs.msg_box;
            setTimeout(() => {
              msg_box.scrollTop = msg_box.scrollHeight;
            }, 10);
          });
        }
      },
      deep: true,
    },
    privateChat: {
      handler(val) {
        // console.log("监听私聊好友", val);
        // 开始自动选中机器人并在发送/w，/t，/s时自动禁用
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    sendBoxPlaceholder() {
      return this.activePrivate.friend_id == 6
        ? "输入/w /t /s激活机器人  shift+enter换行 enter发送"
        : "发消息";
    },
    ...mapGetters([
      "privateChat",
      "activePrivateMsg",
      "activePrivate",
      "userInfo",
      "personMSgSorter",
    ]),
    msg() {
      return this.$store.getters.wsMsg;
    },
    // userInfo() {
    //   return JSON.parse(localStorage.getItem("User"));
    // },
  },
  // 卸载前清除滚动监听
  beforeDestroy() {},
  components: { MsgTemplate, SendCom },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>

<template>
  <el-dialog
    title="我的反馈"
    :visible.sync="myFeedBackListFlg"
    width="50%"
    :before-close="handleClose"
    append-to-body
    custom-class="my_feed_back_list"
  >
    <div class="box">
      <!-- 菜单 -->
      <ul class="nav">
        <li
          class="nav_item"
          :class="{ active: activeNav == nav.id }"
          v-for="nav in navList"
          :key="nav.id"
          @click="navItemClick(nav)"
        >
          {{ nav.name }}
        </li>
      </ul>
      <!-- 数据 -->
      <div class="data_box" v-if="newList.length">
        <ul class="msg">
          <li class="msg_item" v-for="(item, index) in newList" :key="index">
            <div class="title">{{ item.title }}</div>
            <div class="content">{{ item.context }}</div>
            <div class="handle">
              <div v-if="item.status == 0">客服未读</div>
              <div v-if="item.status == 1">客服已读</div>
              <div v-if="item.status == 2">
                客服已回复：<span>{{ item.returnContext }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- 无数据 -->
      <div class="data_box zanwu" v-else>
        <p>暂无数据</p>
      </div>
    </div>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span> -->
  </el-dialog>
</template>

<script>
export default {
  props: {
    myFeedBackListFlg: {
      type: Boolean,
      default: false,
    },
    FeedbackList: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      disabledFlg: false,
      myList: [],
      newList: [],
      // 菜单
      navList: [
        { id: "0", name: "我的反馈" },
        { id: 1, name: "客服未读" },
        { id: 2, name: "客服已读" },
        { id: 3, name: "客服已回复" },
      ],
      activeNav: 0,
    };
  },
  created() {
    // console.log(this.navList[0], 9);
  },
  mounted() {},
  watch: {
    FeedbackList: {
      handler(val) {
        this.myList = [];
        this.newList = [];
        this.myList = val;

        this.navItemClick(this.navList[0]);
        // console.log(123456789, this.myList);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 单项点击
    navItemClick(nav) {
      this.activeNav = nav.id;

      let arr = [];
      this.newList = [];
      this.myList.forEach((item, index) => {
        //   status:0 客服未读、 1 已读 、2 已回复、 3全部
        if (nav.id == "0") {
          this.newList = this.myList;
          // console.log(111, this.newList.length);
        }
        if (nav.id == 1) {
          if (item.status == 0) {
            // debugger;
            arr.push(item);
            this.newList = arr;
            // console.log(222, this.newList.length);
          }
        }
        if (nav.id == 2) {
          if (item.status == 1) {
            arr.push(item);
            this.newList = arr;
            // console.log(333, this.newList.length);
          }
        }
        if (nav.id == 3) {
          if (item.status == 2) {
            arr.push(item);
            this.newList = arr;
            // console.log(444, this.newList.length);
          }
        }
      });
      // console.log(this.newList, 66);
      // }
      // }

      // console.log(nav, nav.id, nav.name, 123456789);
    },
    handleClose() {
      // this.dataForm.title = "";
      // this.radio = 0;
      // this.$refs["dataForm"].resetFields();
      this.$emit("myFeedBackListFun", false);
      this.disabledFlg = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./dialog";
</style>

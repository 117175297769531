<template>
  <!-- 音频 -->
  <div class="main">
    <div class="Video" v-for="(item, index) in AudioList" :key="index">
      <div class="VidoLeft">
        <div class="icon" @click="AutoAudio(item, index)">
          <!-- play -->
          <!-- :class="audioStatus=='pause' ? 'el-icon-video-pause' :  'el-icon-video-play'" -->
          <i :class="IconFun(item)"></i>
          <!-- <i v-if="audioStatus == 'pause'" class="el-icon-video-pause"> </i>
          <i v-if="audioStatus !== 'pause'" class="el-icon-video-play"> </i> -->
          <img :src="ImageSrc(item.filecover)" alt="" />
        </div>
        <div class="text">
          <div>
            <div>{{ item.title }}</div>
            <div class="textImag">
              <div><img :src="item.appUserEntity.avatar" alt="" /></div>
              <div>{{ item.appUserEntity.username }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="VidoCenter">
        <div
          class="progress-bar-bg"
          :id="`F${item.id}`"
          @click="clickItem($event, item)"
        >
          <div class="progress-bar" :id="item.id"></div>
        </div>
         
      </div>
      <div class="VidoRight">
        <div class="time">
          <i class="el-icon-pie-chart"></i>
          <div class="timeLenght">{{ durationMon(item.duration) }}</div>
        </div>

        <div class="txt" @click.stop="AudioSuccess(item)">详情</div>
      </div>
    </div>

    <div class="page" @click="handleChangeAudioVolume" v-if="AudioItem">
      <audio
        @timeupdate="updateProgress"
        controls
        ref="audioRef"
        loop
        autoplay
        style="display: none"
        :src="AudioSrc"
      >
        <!-- <source :src="AudioSrc" type="audio/mpeg" /> -->
        您的浏览器不支持音频播放
      </audio>
      <div class="audio-right">
        <div class="box">
          <!-- 封面 -->
          <div
            :title="AudioItem.title"
            class="imgCover"
            style="margin-right: 20px"
          >
            <img style :src="ImageSrc(AudioItem.filecover)" alt="" />
          </div>
          <!-- 播放/暂停 -->
          <!-- <i :class="IconFun(item)" @click="playAudio"></i>  AudioIndex-->
          <i
            class="dialogAudioPlay"
            :class="IconFun(AudioItem)"
            @click="AutoAudio(AudioItem, AudioIndex)"
          ></i>
          <!-- <i
            v-if="audioStatus == 'play'"
            class="dialogAudioPlay2 dialogAudioPlay el-icon-video-play"
            @click="playAudio"
          >
          </i>
          <i
            v-if="audioStatus != 'play'"
            class="dialogAudioPlay1 dialogAudioPlay el-icon-video-pause"
            @click="playAudio"
          >
          </i> -->
          <div
            class="progress-bar-bg"
            id="progressBarBg"
            v-dragto="setAudioIcon"
          >
            <div class="progress-bar" id="progressBar"></div>
          </div>
          <div class="audio-time" style="min-height: 10px">
            <span class="audio-length-current" id="audioCurTime">{{
              audioStart
            }}</span
            >/
            <!-- <span class="audio-length-total">{{ duration }}</span> -->
            <span class="audio-length-total">{{
              durationMon(AudioItem.duration)
            }}</span>
          </div>
          <div class="volume">
            <div
              @click.stop="
                () => {
                  return false;
                }
              "
              class="volume-progress"
              v-show="audioHuds"
            >
              <div
                class="volume-bar-bg"
                id="volumeBarBg"
                v-adjuster="handleShowMuteIcon"
              >
                <div class="volume-bar" id="volumeBar"></div>
              </div>
            </div>
            <i
              class="iconfont pl-1"
              :class="audioIcon"
              @click.stop="audioHuds = !audioHuds"
            >
            </i>
          </div>
        </div>

        <!-- 发布人 -->
        <p class="publisherName" style="">
          发布人:<span :title="AudioItem.appUserEntity.username">{{
            AudioItem.appUserEntity.username
          }}</span>
        </p>
      </div>
    </div>

      <Login :showLogin="showLogin" @closeLogin="loginPopChange" />
  </div>
</template>

<script>
import CProgress from "./cprogress.vue";
import audios from "./index2.vue";
import { textC } from "@/utils/index";
import Login from "@/components/Login";
export default {
  components: {
    Login,
    CProgress,
    audios,
  },

  props: {
    AudioMsg: "",
  },
  data() {
    return {
      progress: 0,
      AudioSrc: "",
      AudioList: [],

      AudioItem: "",
      AudioIndex: "",
      total: 0,
      showLogin: false,
      audioStatus: "play",
      audioStart: "0:00",
      duration: "0:00",
      audioVolume: 0.5,
      audioHuds: false,
    };
  },
  mounted() {
    if (this.AudioItem) {
      this.fetch();
    }
  },
  directives: {
    // dragtox: {
    //   update: function (el, binding, vnode) {
    //     let that = vnode.context;
    //     // console.log(1223);
    //   },
    // },
    dragto: {
      inserted: function (el, binding, vnode) {
        el.addEventListener(
          "click",
          (e) => {
            let wdiv = document.getElementById("progressBarBg").clientWidth;
            let audio = vnode.context.$refs.audioRef;
            // 只有音乐开始播放后才可以调节，已经播放过但暂停了的也可以
            let ratemin = e.offsetX / wdiv;
            let rate = ratemin * 100;
            document.getElementById("progressBar").style.width = rate + "%";

            // console.log(rate, 345);
            audio.currentTime = audio.duration * ratemin;
            // audio.play();
            binding.value();
          },
          false
        );
      },
    },
    adjuster: {
      inserted: function (el, binding, vnode) {
        el.addEventListener(
          "click",
          (e) => {
            let hdiv = document.getElementById("volumeBarBg").clientHeight;
            let audio = vnode.context.$refs.audioRef;
            // 只有音乐开始播放后才可以调节，已经播放过但暂停了的也可以
            let ratemin = e.offsetY / hdiv;
            let rate = ratemin * 100;
            document.getElementById("volumeBar").style.height = rate + "%";
            audio.volume = ratemin;
            binding.value(rate / 100);
          },
          false
        );
      },
    },
  },
  computed: {
    audioIcon() {
      if (this.audioHuds) {
        return this.audioVolume < 0.01
          ? "checked icon-jingyin"
          : "checked icon-shengyin";
      } else {
        return "icon-shengyin";
      }
    },
  },
  methods: {
    clickItem(e, e2) {
      if (e2.sysOssEntities[0].url == this.AudioSrc) {
        let that = this;
        that.AudioList.forEach((item) => {
          if (that.AudioSrc == item.sysOssEntities[0].url) {
            let wdiv = document.getElementById(`F${item.id}`).clientWidth;
            let audio = that.$refs.audioRef;
            // 只有音乐开始播放后才可以调节，已经播放过但暂停了的也可以
            let ratemin = e.offsetX / wdiv;
            let rate = ratemin * 100;
            document.getElementById(item.id).style.width = rate + "%";
            // console.log(rate, 345);
            audio.currentTime = audio.duration * ratemin;
          }
        });
      } else {
        return;
      }
    },

    IconFun(e) {
      // console.log(this.audioStatus, 345);
      // console.log(e.Auto, 333);
      if (e.Auto) {
        return "el-icon-video-pause";
      } else {
        return "el-icon-video-play";
      }
    },
    handleChangeAudioVolume() {
      // 点击页面关闭音量键显示
      if (this.$refs.audio) this.$refs.audio.audioHuds = false;
    },
    GetProgress(e) {
      if (e > 0) {
        this.progress = e;
      }
      // console.log(e);
      // ;
    },
    onPercentChange(per) {
      // console.log(per);
      // per
      // this.
      // this.$refs.audio.pause();

      this.AudioList.forEach((item, index) => {
        if (item.Auto == true) {
          let time = Math.ceil((item.duration / 100000) * per);

          this.$refs.audio.currentTime = time;

          // this.flag = true;
          // console.log(123);
          // item.progress = time / (item.duration / 10);
          // this.$refs.audio.play();
          // console.log(time);
          // console.log(value);
          // if(value != Number) return
          // pre
          // item.progress = Math.ceil(value * 100);
        }
      });
    },
    // 登录弹窗操作
    loginPopChange(i) {
      this.showLogin = i;
    },
    AudioSuccess(e) {
      this.AudioItem = "";
      this.AudioSrc = "";
      if (
        localStorage.getItem("token") == null ||
        localStorage.getItem("User") == null
      ) {
        // this.$message.error("请先登录");
        this.loginPopChange(true);
        return;
      } else {
        this.$emit("AudioSuccess", e.id);
      }
    },

    ImageSrc(e) {
      // console.log(e,333);
      if (e) {
        if (textC(e).url) {
          return textC(e).url;
        } else {
          return require("@/assets/images/music/Frame.png");
        }
      } else {
        return require("@/assets/images/music/Frame.png");
      }
    },

    // 计算时长
    durationMon(e) {
      if (e == "") {
        return "00:00";
      } else {
        // console.log(e);
        let time = Math.floor(e / 1000);

        var minute = Math.floor(time / 60);
        var rest_seconds = time % 60;

        return (
          minute.toString().padStart(2, "0") +
          ":" +
          rest_seconds.toString().padStart(2, "0")
        );
      }
    },
    // updateProgress(e) {
    //   if (e.target.currentTime / e.target.duration) {
    //     var value = e.target.currentTime / e.target.duration;
    //     this.AudioList.forEach((item, index) => {
    //       // item.progress = 0;
    //       if (item.Auto == true) {
    //         // console.log(value);
    //         // if(value != Number) return
    //         this.progress = Math.ceil(value * 100);
    //       }
    //     });
    //   }
    // },
    AutoAudio(e1, e2) {
      this.AudioList.forEach((item, index) => {
        if (index == e2) {
          if (this.AudioSrc == item.sysOssEntities[0].url) {
            if (item.Auto == true) {
              item.Auto = false;
              this.playAudio();
              //  this.audioStatus = "pause";
              // this.$refs.audio.pause();
            } else {
              // console.log(2);
              this.playAudio();
              item.Auto = true;
            }
          } else {
            item.Auto = true;
            this.AudioSrc = item.sysOssEntities[0].url;
            this.AudioItem = e1;
            this.AudioIndex = e2;
          }
        } else {
          item.Auto = false;
        } // console.log(item);
        this.$set(this.AudioList, e2, this.AudioList[e2]);
      });
    },

    fetch() {
      let that = this;
      var myVid = this.$refs.audioRef;
      myVid.loop = false;
      // 监听音频播放完毕
      myVid.addEventListener(
        "ended",
        function () {
          that.audioStatus = "play"; // 显示播放icon
          document.getElementById("progressBar").style.width = "0%"; // 进度条初始化
        },
        false
      );
      if (myVid != null) {
        myVid.oncanplay = function () {
          that.duration = that.transTime(myVid.duration); // 计算音频时长
        };
        myVid.volume = 0.5; // 设置音量50%
      }
    },
    // 播放暂停控制
    playAudio() {
      let recordAudio = this.$refs.audioRef; // 获取audio元素
      if (recordAudio.paused) {
        recordAudio.play();
        this.audioStatus = "pause";
      } else {
        recordAudio.pause();
        this.audioStatus = "play";
      }
    },
    // 更新进度条与当前播放时间
    updateProgress(e) {
      var value = e.target.currentTime / e.target.duration;
      if (document.getElementById("progressBar")) {
        document.getElementById("progressBar").style.width = value * 100 + "%";
        // document.getElementById("progressBar1").style.width = value * 100 + "%";

        this.AudioList.forEach((item) => {
          // console.log(item);
          if (item.sysOssEntities[0].url == this.AudioSrc) {
            // console.log(document.getElementById(item.id));
            document.getElementById(item.id).style.width = value * 100 + "%";
          } else {
            document.getElementById(item.id).style.width = 0 + "%";
          }
        });

        // console.log(progressBar1, 33);
        if (e.target.currentTime === e.target.duration) {
          this.audioStatus = "pause";
        }
        // console.log(value * 100, 333);
        this.$emit("GetProgress", value * 100);
      } else {
        this.audioStatus = "pause";
      }

      this.audioStart = this.transTime(this.$refs.audioRef.currentTime);
    },
    /**
     * 音频播放时间换算
     * @param {number} value - 音频当前播放时间，单位秒
     */
    transTime(time) {
      var duration = parseInt(time);
      var minute = parseInt(duration / 60);
      var sec = (duration % 60) + "";
      var isM0 = ":";
      if (minute === 0) {
        minute = "00";
      } else if (minute < 10) {
        minute = "0" + minute;
      }
      if (sec.length === 1) {
        sec = "0" + sec;
      }
      //   console.log(minute + isM0 + sec);
      return minute + isM0 + sec;
    },
    setAudioIcon() {
      this.audioStatus = "pause";
    },
    handleShowMuteIcon(val) {
      this.audioVolume = val;
    },
  },
  watch: {
    deep: true,

    AudioMsg(newVal, oldVal) {
      this.$http({
        url: this.$http.adornUrl("/app/material/list"),
        method: "post",
        data: this.$http.adornData(newVal),
      }).then((data) => {
        data.data.result.data.forEach((element) => {
          // console.log(element);
          element.Auto = false;
          element.progress = 0;
        });

        if (newVal.page == 1) {
          this.AudioList = data.data.result.data;

          // stopLoading()
          // layImage
        } else {
          data.data.result.data.forEach((element) => {
            this.AudioList.push(element);
          });

          //
          // this.layImage
        }
        // console.log(this.AudioLis, 33);
        this.total = data.data.result.total;
        this.$emit("Showtotal", this.total);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
.dialogAudioPlay {
  cursor: pointer;
  font-size: 40px;
  // background-color: #5c5e66;
  margin: auto;
  margin-right: 20px;
}
.volume {
  position: relative;
  .volume-progress {
    position: absolute;
    top: -150px;
    width: 32px;
    height: 140px;
    background: #f6f6f6;
    border-radius: 4px;
    padding-top: 10px;
  }
  .volume-bar-bg {
    margin: 0 auto;
    width: 6px;
    height: 120px;
    background: #dcdcdc;
    border-radius: 100px;
    flex: 1;
    position: relative;
    transform: rotate(180deg);
    cursor: pointer;
    .volume-bar {
      width: 6px;
      height: 50%;
      background: #56bf8b;
      border-radius: 100px;
    }
  }
  .checked {
    color: #56bf8b;
  }
}
.page {
  margin-top: 50px;
}
.audio-right {
  width: 98%;
  // height: 49px;
  height: 50px;
  line-height: 50px;
  background: #5c5e66;
  // background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  padding: 0 15px;
  position: fixed;
  bottom: 0;
  .box {
    width: 80%;
    display: flex;
    // border: 1px solid red;
    .imgCover {
      padding: 2px;
      img {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        object-fit: cover;
      }
    }
    .dialogAudioPlay {
      cursor: pointer;
      font-size: 40px;
    }
    .progress-bar-bg {
      background-color: #fff;
      flex: 1;
      position: relative;
      height: 10px;
      top: 50%;
      transform: translateY(-50%);
      margin-top: -1px;
      cursor: pointer;
      margin: 0 10px;
    }
    .progress-bar {
      background-color: #56bf8b;
      width: 0%;
      height: 10px;
      border-radius: 5px;
    }

    .audio-time {
      overflow: hidden;
      font-size: 14px;
      .audio-length-total {
        float: right;
      }
      .audio-length-current {
        float: left;
      }
    }
  }
  .publisherName {
    // width: 120px;
    margin-left: 20px;
    display: flex;
    span {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 10px;
    }
  }
}

.progress-bar-bg {
  background-color: #fff;
  flex: 1;
  position: relative;
  height: 10px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -1px;
  cursor: pointer;
  margin: 10px 0 10px;
  border-radius: 5px;
}
.progress-bar {
  background-color: #56bf8b;
  width: 0px;
  height: 10px;
  border-radius: 5px;
}
</style>

<template>
  <!-- 消息展示框 -->
  <div class="message_box">
    <div class="go_bottom" v-if="showNewMessage" @click.stop="scrollTobottom">
      <i class="el-icon-d-arrow-right"></i>
      新消息
    </div>
    <div class="load_more" v-if="showLoadMore">
      <i class="el-icon-loading"></i>
      更多消息
    </div>
    <div class="load_all" v-if="showLoadAll">已加载全部</div>
    <div class="msg_tem" ref="msgTem">
      <template v-if="chattingRecordsList.length">
        <ul class="msg_list">
          <li
            class="msg_item"
            v-for="(item, index) in chattingRecordsList"
            :key="index"
          >
            <div class="msg_time">{{ item.showTime }}</div>
            <!-- 系统提示 -->
            <template v-if="item.messageType == 'gpNotice'">
              <div class="notice_msg">
                {{ item.content }}
              </div>
            </template>

            <!-- 用户消息 -->
            <template v-else>
              <div
                class="msg_info"
                :class="{ self: item.senderId == userInfo.uid }"
              >
                <div class="info_img" v-if="item.senderId != userInfo.uid">
                  <img :src="item.senderAvatar" alt="头像" />
                </div>
                <div class="info_main">
                  <div class="user_name">{{ item.senderName }}</div>
                  <div class="content_box">
                    <!-- 文本text -->
                    <template v-if="item.messageType == 'text'">
                      <div class="text" v-html="item.content"></div>
                      <!-- style="white-space: pre-wrap" -->
                    </template>

                    <!-- 分享素材 -->
                    <template v-if="item.messageType == 'share'">
                      <!-- type 1图片、2文字、3视频、4音频、5模型 -->
                      <div class="sharematerial" @click="goDetail(item)">
                        <!-- 图片 模型 素材 -->
                        <template
                          v-if="
                            item.content.type == 1 || item.content.type == 5
                          "
                        >
                          <div class="share_ma_title">
                            {{ item.content.title }}
                          </div>
                          <div class="share_ma_img">
                            <img :src="ShowImage(item)" alt="素材" />
                          </div>
                          <div class="share_ma_user">
                            <div class="user_img_box">
                              <img
                                :src="
                                  item.content.avatar ||
                                  item.content.appUserEntity.avatar
                                "
                                alt="头像"
                              />
                            </div>
                            <div class="sahr_ma_u_name">
                              {{ item.content.author }}
                            </div>
                          </div>
                        </template>
                      </div>
                    </template>
                    <!-- 机器人素材图片 img -->
                    <template v-if="item.messageType == 'img'">
                      <div class="msg_img_box">
                        <template v-if="item.content != ''">
                          <el-image
                            fit="cover"
                            :src="item.content.toString().split(',')[0]"
                            :preview-src-list="[
                              item.content.toString().split(',')[0],
                            ]"
                          >
                          </el-image>
                          <div class="to_lade" @click="aiImgDrawer(item)">
                            导入素材库
                          </div>
                        </template>
                        <template v-else>
                          <div class="is_loading">
                            <i class="el-icon-loading"></i>
                          </div>
                        </template>
                      </div>
                    </template>

                    <!-- 消息图片 -->
                    <template v-if="item.messageType == 'imgfile'">
                      <div class="msg_img_box">
                        <template>
                          <!-- <img :src="item.content" alt="图片" /> -->
                          <el-image
                            fit="cover"
                            :src="item.content"
                            :preview-src-list="[item.content]"
                          >
                          </el-image>
                        </template>
                      </div>
                    </template>
                    <!-- 提示支付 -->
                    <template v-if="item.messageType == 'lacknum'">
                      <div class="msg_pay">
                        <div>
                          您的机器人免费额度已用尽，如需继续使用TWYiA机器人，请购买<span
                            @click="showRechargeFun(true, 2)"
                            >套餐</span
                          >
                          否则将按
                          <span @click="showRechargeFun(true, 1)">用量</span
                          >花费堆币。
                        </div>
                        <div class="not_again">
                          <el-checkbox v-model="notAgain">不在提示</el-checkbox>
                        </div>
                      </div>
                    </template>
                    <template v-if="item.messageType == 'needpay'">
                      <div class="msg_pay_tip">
                        <div>
                          您的钱包余额不足，如需使用TWYiA机器人，请<span
                            @click="showRechargeFun(true, 3)"
                            >充值</span
                          >
                          或购买<span @click="showRechargeFun(true, 2)"
                            >套餐</span
                          >。
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="info_img" v-if="item.senderId == userInfo.uid">
                  <img :src="item.senderAvatar" alt="头像" />
                </div>
              </div>
            </template>
          </li>
        </ul>
      </template>

      <template v-else>
        <div class="empty_box">暂无更多消息</div>
      </template>
      <!-- 充电 -->
      <Recharge
        v-if="showRecharge"
        :showRecharge="showRecharge"
        :rechargeType="rechargeType"
        @CloseRecharge="showRechargeFun"
      />
      <!-- 详情 -->
      <Detail
        :detailshow="detailshow"
        :detailType="detailType"
        :PictureSuccess="PictureSuccess"
        :ModlSuccess="ModlSuccess"
        @closeDetailDialog="DetailDialog"
      />
      <!-- 导入素材库 -->
      <aiImgUpLoadDrawer
        :drawerFlg="drawerFlg"
        :aiImgsUrlArr="aiImgsUrlArr"
        @closeDrawer="closeDrawer"
      ></aiImgUpLoadDrawer>
      <!-- :detailId="detailId" -->
    </div>
  </div>
</template>

<script>
import Recharge from "@/components/Recharge";
import Detail from "@/components/Detail";
import UpLoadDrawer from "@/components/Home/UpLoadDrawer";
import aiImgUpLoadDrawer from "@/components/MsgTemplate/aiImgUploadDrawer";
import { FupdateTime } from "@/utils";
import { enjoyList } from "@/utils/enjoy";

import hljs from "highlight.js";
import MarkDownIt from "markdown-it";
import "highlight.js/styles/atom-one-dark.css";
import ClipboardJS from "clipboard";

// 消息类型  "gpNotice"
export default {
  props: {
    // 展示历史消息加载完
    showLoadAll: {
      type: Boolean,
      default: false,
    },
    showLoadMore: {
      type: Boolean,
      default: false,
    },
    showNewMessage: {
      type: Boolean,
      default: false,
    },
    // 聊天记录
    chattingRecordsList: {
      type: Array,
      default: () => [],
    },
    // aiImg: {
    //   type: Array,
    //   default: () => [],
    // },
  },

  data() {
    return {
      drawerFlg: false,
      notAgain: false, //不在提示
      showRecharge: false,
      rechargeType: 1,
      // 素材1 模型2
      detailType: 1,
      detailshow: false,
      // 素材id
      PictureSuccess: null,
      // 模型id
      ModlSuccess: null,
      aiImgUrl: "", //ai生成的图片
      aiImgsUrlArr: [],

      msgScrollOldHeight: 0, //盒子滚动初始高度
      msgScrollNewHeight: 0, //加载消息后的盒子高度
      clipboard: "",
    };
  },

  mounted() {
    const msgTem = this.$refs.msgTem;
    if (msgTem) {
      this.$nextTick(() => {
        msgTem.addEventListener("scroll", this.msgScrollHnadle);
        setTimeout(() => {
          this.msgScrollOldHeight = msgTem.scrollHeight;
          msgTem.scrollTop = msgTem.scrollHeight;
        }, 200);
      });
      let _this = this;
      // document.querySelectorAll("pre code").forEach((el) => {
      this.clipboard = new ClipboardJS(".copy-btn");
      this.clipboard.on("success", function (e) {
        _this.$message.success("复制成功");
        // e.clearSelection();
      });
      this.clipboard.on("error", function (e) {
        _this.$message.error("复制失败");
      });
    }
  },
  beforeDestroy() {
    this.clipboard.destroy();
  },
  methods: {
    // 返回滚动条是否是在最底部
    checkScrollBottom() {
      const msgTem = this.$refs.msgTem;
      const scrollHeight = msgTem.scrollHeight;
      const clientHeight = msgTem.clientHeight;
      const scrollTop = msgTem.scrollTop;
      if (scrollTop + clientHeight >= scrollHeight - 500) {
        return true;
      } else {
        return false;
      }
    },

    // 加载更多后设置滚动条位置
    // setScrollTop() {
    //   const msgTem = this.$refs.msgTem;
    //   msgTem.scrollTop = 500;
    // },
    // 滚动条到底部
    scrollTobottom() {
      const msgTem = this.$refs.msgTem;
      this.$nextTick(() => {
        setTimeout(() => {
          msgTem.scrollTop = msgTem.scrollHeight;
        }, 50);
        this.$emit("showNewMessageFun", false);
      });
    },
    // 触顶加载更多
    msgScrollHnadle() {
      const msgTem = this.$refs.msgTem;
      if (msgTem.scrollTop === 0) {
        this.$emit("loadMoreMsg");
      }
      if (msgTem.scrollTop + msgTem.clientHeight >= msgTem.scrollHeight) {
        this.$emit("showNewMessageFun", false);
      }
    },
    // ai图片导入素材库
    aiImgDrawer(item) {
      let arr1 = [];
      this.drawerFlg = true;
      arr1 = item.content.toString().split(",");
      arr1.unshift(item.id);
      this.aiImgsUrlArr = arr1;
      console.log("导入", item.content, this.aiImgsUrlArr);
    },
    closeDrawer(i) {
      this.drawerFlg = i;
    },
    //  分享展示图片
    ShowImage(e) {
      if (e.content.type == 1) {
        return e.content.sysOssEntities[0].url;
      } else if (e.content.type == 5) {
        return e.content.assgraph.split(",")[0];
      }
    },

    // 去分享详情
    goDetail(item) {
      if (item.content.type == 1) {
        this.detailType = 1;
        this.PictureSuccess = item.content.id;
        this.detailshow = true;
      }
      if (item.content.type == 5) {
        this.detailType = 2;
        this.ModlSuccess = item.content.id;
        this.detailshow = true;
      }
    },

    // 详情
    DetailDialog(i) {
      this.detailshow = i;
    },

    // 充电
    showRechargeFun(i, type) {
      if (type == 1) {
        this.showRecharge = i;
        this.rechargeType = type;
        return;
      }
      if (type == 3) {
        this.$router.push("/usercenter/4");
        return;
      }
      if (type == 2) {
        this.$router.push("/usercenter/2");
        return;
      }
    },
  },
  watch: {
    chattingRecordsList: {
      handler(val) {
        val.forEach((item) => {
          try {
            item.content = JSON.parse(item.content);
          } catch (err) {
            item.content = item.content;
          }
          // 处理文本消息中得表情展示
          if (item.messageType == "text") {
            let reg = /\[(.*?)\]/g;
            let enjoyArr = [];
            for (const en in enjoyList) {
              enjoyArr.push({
                name: en,
                src: enjoyList[en],
              });
            }
            if (item.content) {
              item.content = String(item.content);
              let matchs = item.content.match(reg);
              if (matchs) {
                const replacedText = item.content.replace(reg, (match) => {
                  let enjoy = enjoyArr.find((item) => item.name == match);
                  if (enjoy) {
                    return `<img src="${enjoy.src}"   />`;
                  } else {
                    return match;
                  }
                });
                item.content = replacedText;
              }

              // mackdown处理展示消息内容
              const md = new MarkDownIt({
                html: true,
                linkify: true,
                typographer: true,
                breaks: true,
                highlight: function (str, lang) {
                  if (lang && hljs.getLanguage(lang)) {
                    try {
                      let highlightedHtml = hljs.highlight(str, {
                        language: lang,
                      }).value;
                      let lineNum = highlightedHtml.split("\n").length - 1;
                      let lineNumbersRowsStart = `<span aria-hidden="true" class="line-numbers-rows">`;
                      let lineNumbersRowsEnd = `</span>`;
                      for (let i = 0; i < lineNum; i++) {
                        lineNumbersRowsStart += `<span></span>`;
                      }
                      const lineNumbersRows =
                        lineNumbersRowsStart + lineNumbersRowsEnd;
                      let languageName = `<b class="language-name">${lang}</b>`;
                      var d = new Date().getTime();
                      if (
                        window.performance &&
                        typeof window.performance.now === "function"
                      ) {
                        d += performance.now();
                      }
                      const codeIndex =
                        "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
                          /[xy]/g,
                          function (c) {
                            var r = (d + Math.random() * 16) % 16 | 0;
                            d = Math.floor(d / 16);
                            return (c == "x" ? r : (r & 0x3) | 0x8).toString(
                              16
                            );
                          }
                        );
                      let textAreaHtml = `<textarea class="copy-textarea" id="copy${codeIndex}">${str}</textarea>`;
                      let copyButton = `<button class="copy-btn el-icon-document-copy"  data-clipboard-action="copy" data-clipboard-target="#copy${codeIndex}" type="button">  </button>`;
                      return `<pre><code class="language-${lang} hljs">${highlightedHtml}</code>${lineNumbersRows}${languageName}${copyButton}${textAreaHtml}</pre>`;
                    } catch (__) {}
                  }
                  return "";
                },
              });
              item.content = md.render(item.content);
            }
          }
        });

        // 加载更多后设置滚动条位置
        const msgTem = this.$refs.msgTem;
        if (msgTem) {
          this.$nextTick(() => {
            msgTem.scrollTop = msgTem.scrollHeight - this.msgScrollOldHeight;
            this.msgScrollOldHeight = msgTem.scrollHeight;
          });
        }

        for (let i = 0; i < val.length; i++) {
          if (val[i] && val[i - 1]) {
            let time1 = val[i].sendTime;
            let time2 = val[i - 1].sendTime;
            let diff = Math.abs(time1 - time2) / 1000 / 60;
            if (diff > 3) {
              val[i].showTime = FupdateTime(JSON.parse(time1));
            } else {
              val[i].showTime = "";
            }
          }
          val[0].showTime = FupdateTime(JSON.parse(val[0].sendTime));
          // if (this.aiImgUrl2 != null) {
          //   if (this.aiImgUrl2.data.id == this.chattingRecordsList[i].id) {
          //     this.chattingRecordsList[i].content = this.aiImgUrl2.data.content;
          //   }
          // }
        }

        // });
      },
      immediate: true,
    },
    // aiImg: {
    //   handler(val) {
    //     // console.log(val, "接收到新消息------");
    //     // return;
    //     this.aiImgsUrlArr = val;
    //     if (val != "") {
    //       for (let i = 0; i < this.chattingRecordsList.length; i++) {
    //         if (val[0] == this.chattingRecordsList[i].id) {
    //           let arr1 = [];
    //           let arr2 = [];
    //           arr1.push(val[1]);
    //           arr1.push(val[2]);
    //           arr2 = arr1;
    //           arr2.join(",");
    //           this.chattingRecordsList[i].content = arr2;
    //         }
    //         this.aiImgsUrlArr = val;
    //       }
    //     }
    //   },
    //   deep: true,
    //   // immediate: true,
    // },
    // msg: {
    //   handler(val) {
    //     // localStorage.setItem("aiImgUrl", JSON.stringify(val));
    //     console.log(
    //       val,
    //       // JSON.parse(localStorage.getItem("aiImgUrl")),
    //       // JSON.parse(localStorage.getItem("aiImgUrl")).data.id,
    //       "接收到新消息111222"
    //     );
    //     // if (val.type == "end-load-message") {
    //     //   for (let i = 0; i < this.chattingRecordsList.length; i++) {
    //     //     if (val.data.id == this.chattingRecordsList[i].id) {
    //     //       this.chattingRecordsList[i].content = val.data.content;
    //     //     }
    //     //   }
    //     // }
    //     // else if (val.data.messageType == "text" && val.data.senderId != "6") {
    //     //   this.chattingRecordsList.push(val.data);
    //     // }
    //   },
    //   // immediate: true,
    //   deep: true,
    // },
    // msgAiImg: {
    //   handler(val) {
    //     console.log(val, "接收到aiImg新消息123--");
    //     if (val.type == "end-load-message") {
    //       for (let i = 0; i < this.chattingRecordsList.length; i++) {
    //         if (
    //           val.data.id == this.chattingRecordsList[i].id &&
    //           this.chattingRecordsList[i].messageType == "img"
    //         ) {
    //           this.chattingRecordsList[i].content = val.data.content;
    //         }
    //       }
    //     }
    //   },
    //   deep: true,
    // },
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem("User"));
    },
    msg() {
      return this.$store.getters.wsMsg;
    },
    // msgAiImg() {
    //   return this.$store.getters.wsMsgAiImg;
    // },
    // 监听空白新消息
    // aiImgUrl1() {
    //   if (JSON.parse(localStorage.getItem("aiImgUrl")) != null) {
    //     return JSON.parse(localStorage.getItem("aiImgUrl"));
    //   }
    // },
    // ai回复最新的imgUrl
    // aiImgUrl2() {
    //   if (JSON.parse(localStorage.getItem("aiImgUrl2")) != null) {
    //     return JSON.parse(localStorage.getItem("aiImgUrl2"));
    //   }
    // },
  },
  components: {
    Recharge,
    Detail,
    UpLoadDrawer,
    aiImgUpLoadDrawer,
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>

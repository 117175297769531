<template>
  <div class="pic_detail">
    <div class="pic_box">
      <div class="img_box">
        <!-- <img :src="item.url" alt="素材" /> -->

        <!-- <div class="draw_same">
            <div class="btn">画同款</div>
            <DrawSame />
          </div> -->

        <el-image
          class="detail_img"
          :src="ImgSrc(AudioSuccsess.filecover)"
          :preview-src-list="[ImgSrc(AudioSuccsess.filecover)]"
        >
        </el-image>
      </div>
      <!-- <img class="crosswise" src="@/assets/images/bjimg1.jpg" alt="素材" /> -->
    </div>
    <div class="info_matter">
      <div class="info_box_com">
        <div class="top_box">
          <div class="user_about">
            <div class="u_img">
              <img :src="AudioSuccsess.avatar" alt="头像" />
            </div>
            <div class="u_name">{{ AudioSuccsess.author }}</div>
          </div>
          <div class="btn_gz" @click="follow()">
            {{ AudioSuccsess.isFollow ? "已关注" : "关注" }}
          </div>
        </div>
        <div class="title">{{ AudioSuccsess.title }}</div>
        <div class="content">{{ AudioSuccsess.content }}</div>
        <div class="collect_like">
          <div class="cl_item" @click.stop="FavoritePictures(AudioSuccsess)">
            <img
              v-if="AudioSuccsess.scistate == 0"
              src="@/assets/images/shoucang.png"
              alt="收藏"
            />
            <img v-else src="@/assets/images/Frame@2x-4.png" alt="未收藏" />
            <span>{{ AudioSuccsess.collectCount }}</span>
          </div>
          <div class="cl_item" @click.stop="LikeImage(AudioSuccsess)">
            <img
              v-if="AudioSuccsess.sclikeistate == 2"
              src="@/assets/images/fav-1.png"
              alt="喜欢"
            />
            <img v-else src="@/assets/images/fav1.png" alt="未喜欢" />
            <span>{{ AudioSuccsess.likeCount }}</span>
          </div>
        </div>
        <div class="data_list">
          <div class="data_item">
            <div class="item_name">题材分类</div>
            <div class="item_text">{{ GetTags() }}</div>
          </div>
          <div class="data_item">
            <div class="item_name">谁可以看</div>
            <div class="item_text">
              {{ AudioSuccsess.isopen == 0 ? "公开" : "私有" }}
            </div>
          </div>
          <div class="data_item">
            <div class="item_name">授权信息</div>
            <div class="item_text">{{ accreditStr }}</div>
          </div>
          <!-- <div class="data_item">
              <div class="item_name">使用价格</div>
              <div class="item_text">45元</div>
            </div> -->
        </div>
        <div class="share">
          <!--  v-if=""  user.uid !== AudioSuccsess.uid?100%:45%-->
          <div
            :style="funWidth()"
            class="upload_btn"
            @click="sendTopGroupFun(true)"
          >
            分享到聊天
          </div>
          <div
            class="upload_btn"
            v-if="user.uid == AudioSuccsess.uid"
            @click="uploadBtnClick(AudioSuccsess)"
          >
            编辑
          </div>
        </div>
      </div>
    </div>
    <ShareToGroup
      v-if="sharToGroupShow"
      :materialData="AudioSuccsess"
      :sharToGroupShow="sharToGroupShow"
      @sendTopGroupFun="sendTopGroupFun"
    />
    <!-- 上传弹窗 -->
    <!-- <UpLoadDrawer
        :uploadDrawer="uploadDrawer"
        :activeTypes="activeType"
        @uploadDrawerhandleClose="uploadDrawerhandleClose"
      /> -->
    <!-- 编辑上传弹窗 -->
    <UpLoadDrawerUpdate
      :AudioSuccsess="AudioSuccsess"
      :showImagModel="showImagModel"
      @beforeClose="beforeClose"
    >
    </UpLoadDrawerUpdate>
  </div>
</template>
<script>
import { textC } from "@/utils/index";
import DrawSame from "@/components/Detail/DrawSame";
import ShareToGroup from "@/components/Detail/ShareToGroup";
import { text } from "@/utils/index";
// import UpLoadDrawer from "@/components/Home/UpLoadDrawer";
import UpLoadDrawerUpdate from "../UpLoadDrawers/Audio/index.vue";
export default {
  props: {
    // 图片Id
    newAudio: "",
  },
  data() {
    return {
      DefaultImag: require("@/assets/images/FrameImg.png"),
      showImagModel: false,
      activeType: "1",
      //图片详情
      AudioSuccsess: "",
      sharToGroupShow: false, //分享到群聊
      accreditStr: "",
      page: 1,
      fList: [],
      user: "", //个人信息
      editUpdateFlg: false, //编辑上传弹窗
    };
  },
  components: {
    DrawSame,
    ShareToGroup,
    // UpLoadDrawer,
    UpLoadDrawerUpdate,
  },
  created() {
    // this.GetImageSuccess();
  },
  mounted() {
    this.GetImageSuccess();
    this.user = JSON.parse(localStorage.getItem("User"));
  },
  watch: {
    deep: true,
    immediate: true,
    newAudio(newVal) {
      this.GetImageSuccess();
      console.log(555555, newVal);
    },
  },
  methods: {
    ImgSrc(e) {
      if (e) {
        if (textC(e).url) {
          return textC(e).url;
        } else {
          return require("@/assets/images/music/Frame.png");
        }
      } else {
        return require("@/assets/images/music/Frame.png");
      }
    },

    beforeClose(e) {
      console.log(e);
      this.showImagModel = e;
    },
    // 本人：分享到聊天 。非本人：分享到聊天 ，编辑
    funWidth() {
      if (this.user.uid == this.AudioSuccsess.uid) {
        return;
      } else {
        return "width:100%";
      }
    },
    // 编辑/上传按钮点击
    uploadBtnClick() {
      // console.log(this.AudioSuccsess, 23333);
      this.showImagModel = true;
      let c = JSON.parse(JSON.stringify(this.AudioSuccsess));
      this.AudioSuccsess = c;
    },
    // 获取图片信息
    GetImageSuccess() {
      this.$http({
        url: this.$http.adornUrl("/app/material/detail"),
        method: "get",
        params: this.$http.adornParams({
          id: this.newAudio,
        }),
      }).then(({ data }) => {
        if (data.code == 0) {
          this.AudioSuccsess = data.result;
          console.log(this.AudioSuccsess, 878);
          // this.followList()
          this.GetAccreditType();
          console.log("弹窗打开");
        }
      });
    },
    // 获取题材分类
    GetTags() {
      if (this.AudioSuccsess) {
        return this.AudioSuccsess.tagList.join(",");
      }
    },
    // 获取授权信息
    GetAccreditType() {
      if (this.AudioSuccsess) {
        this.$http({
          url: this.$http.adornUrl("/app/material/getAccreditType"),
          method: "get",
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.accreditStr = text(
              data.accreditType,
              this.AudioSuccsess.accreditType
            );
          } else {
            this.$message.error(data.msg);
          }
        });
      }
    },
    // 收藏
    FavoritePictures(e) {
      if (e.scistate == 0) {
        this.AddFavorite(e);
      } else {
        this.EditFavorite(e);
      }
    },
    // 添加收藏
    AddFavorite(e) {
      this.$http({
        url: this.$http.adornUrl("/app/material/addCollect"),
        method: "post",
        data: this.$http.adornData({
          id: e.id, //素材id
        }),
      }).then((data) => {
        this.AudioSuccsess.scistate = 1;
        this.AudioSuccsess.collectCount++;
        this.$message({
          message: "收藏成功",
          type: "success",
          duration: 500,
        });
      });
    },
    //取消收藏
    EditFavorite(e) {
      this.$http({
        url: this.$http.adornUrl("/app/material/cancel"),
        method: "post",
        data: this.$http.adornData({
          id: e.id, //素材id
        }),
      }).then((data) => {
        this.AudioSuccsess.scistate = 0;
        this.AudioSuccsess.collectCount--;
        this.$message({
          message: "取消成功",
          type: "success",
          duration: 500,
        });
      });
    },
    //点赞
    LikeImage(e) {
      if (e.sclikeistate == 0) {
        this.AddLike(e);
      } else {
        this.EditLike(e);
      }
    },
    // 添加点赞
    AddLike(e) {
      this.$http({
        url: this.$http.adornUrl("/app/material/addLike"),
        method: "post",
        data: this.$http.adornData({
          id: e.id, //素材id
        }),
      }).then((data) => {
        this.AudioSuccsess.sclikeistate = 2;
        this.AudioSuccsess.likeCount++;
        this.$message({
          message: "添加成功",
          type: "success",
          duration: 500,
        });
      });
    },
    //取消点赞
    EditLike(e) {
      this.$http({
        url: this.$http.adornUrl("/app/material/cancelLike"),
        method: "post",
        data: this.$http.adornData({
          id: e.id, //素材id
        }),
      }).then((data) => {
        this.AudioSuccsess.sclikeistate = 0;
        this.AudioSuccsess.likeCount--;
        this.$message({
          message: "取消成功",
          type: "success",
          duration: 500,
        });
      });
    },
    // 关注/取消关注
    follow() {
      let url = this.AudioSuccsess.isFollow
        ? "/app/user/cancelFollow"
        : "/app/user/addFollow ";
      this.$http({
        url: this.$http.adornUrl(url),
        method: "post",
        data: this.$http.adornData({
          id: this.AudioSuccsess.uid,
        }),
      }).then((data) => {
        if (data.data.code === 0) {
          this.AudioSuccsess.isFollow = !this.AudioSuccsess.isFollow;
          this.$message({
            message: `${data.data.msg}`,
            type: "success",
            duration: 1500,
          });
        } else {
          this.$message({
            message: `${data.data.msg}`,
            type: "warning",
            duration: 1500,
          });
        }
      });
    },

    sendTopGroupFun(i) {
      this.sharToGroupShow = i;
    },
  },
};
</script>

<style lang="scss" scoped>
.pic_detail {
  height: 100vh;

  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;

  .pic_box {
    // flex: 1;
    height: calc(100vh - 40px);
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep .img_box {
      position: relative;

      display: flex;
      align-items: center;

      .detail_img {
        max-height: calc(100vh - 40px);
        object-fit: contain;

        img {
          width: 100% !important;
          height: auto !important;
          object-fit: contain;
          max-height: calc(100vh - 40px);
          max-width: 100%;
        }
      }
    }

    .draw_same {
      position: absolute;
      bottom: 20px;
      right: 20px;
      z-index: 9999;

      .btn {
        padding: 10px 25px;
        border-radius: 5px;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.7);
      }

      &:hover {
        ::v-deep .draw_main_box {
          display: block;
        }
      }
    }
  }

  .info_matter {
    // flex: 1;
    display: flex;

    .info_box_com {
      // min-width: 500px;
      width: 500px;
      padding: 40px;
      background: #1d1f21;

      .top_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .user_about {
          display: flex;
          align-items: center;

          .u_img {
            position: relative;
            width: 50px;
            height: 50px;
            overflow: hidden;
            border-radius: 50%;
            margin-right: 10px;

            img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }

        .btn_gz {
          cursor: pointer;
          padding: 8px 20px;
          border-radius: 5px;
          background: var(--primary);
        }
      }

      .title {
        margin: 10px 0;
        font-size: 18px;
      }

      .content {
        color: var(--senary);
        margin: 10px 0;
      }

      .collect_like {
        display: flex;
        padding-bottom: 30px;
        border-bottom: 1px solid #4b4b4b;

        .cl_item {
          display: flex;
          align-items: center;
          margin-right: 20px;
          cursor: pointer;

          img {
            width: 30px;
            height: 30px;
          }

          span {
            font-size: 20px;
          }
        }
      }

      .data_list {
        padding-top: 20px;

        .data_item {
          display: flex;
          align-items: center;
          padding: 10px 0;

          .item_name {
            margin-right: 10px;
          }

          .item_text {
            flex: 1;
            padding: 10px;
            background-color: #292929;
            border-radius: 5px;
          }
        }
      }

      .share {
        margin-top: 80px;
        text-align: center;
        padding: 15px 0;

        display: flex;
        border-radius: 5px;
        cursor: pointer;

        .upload_btn {
          width: 45%;
          height: 50px;
          margin: 0 10px;
          border-radius: 5px;
          text-align: center;
          line-height: 50px;
          background-color: var(--redary);
        }
      }
    }
  }
}
</style>

<template>
  <!-- 音频 文字详情 -->
  <el-dialog
    :visible.sync="DetailDialogShow"
    width="30%"
    :before-close="handleClose"
    append-to-body
    custom-class="wm_detail"
  >
    <div class="info_box_com">
      <div class="top_box">
        <div class="user_about">
          <div class="u_img">
            <img src="@/assets/images/avatai2.jpg" alt="头像" />
          </div>
          <div class="u_name">用户昵称</div>
        </div>
        <div class="btn_gz">关注</div>
      </div>
      <div class="title">这是标题</div>
      <div class="content">这是内筒介绍及大宋的是撒大苏打</div>
      <div class="collect_like">
        <div class="cl_item">
          <img v-if="0" src="@/assets/images/shoucang.png" alt="收藏" />
          <img v-else src="@/assets/images/Frame@2x-4.png" alt="已收藏" />
          <span>{{ 10 }}</span>
        </div>
        <div class="cl_item">
          <img v-if="1" src="@/assets/images/fav1.png" alt="收藏" />
          <img v-else src="@/assets/images/fav-1.png" alt="已收藏" />
          <span>{{ 10 }}</span>
        </div>
      </div>
      <div class="data_list">
        <div class="data_item">
          <div class="item_name">题材分类</div>
          <div class="item_text">古风</div>
        </div>
        <div class="data_item">
          <div class="item_name">谁可以看</div>
          <div class="item_text">仅自己</div>
        </div>
        <div class="data_item">
          <div class="item_name">授权信息</div>
          <div class="item_text">不保留任何权利</div>
        </div>
        <!-- <div class="data_item">
          <div class="item_name">使用价格</div>
          <div class="item_text">45元</div>
        </div> -->
      </div>
      <div class="share" @click="sendTopGroupFun(true)">分享到聊天</div>
    </div>
    <div class="close" @click="handleClose"><i class="el-icon-close"></i></div>

    <ShareToGroup
      v-if="sharToGroupShow"
      :sharToGroupShow="sharToGroupShow"
      @sendTopGroupFun="sendTopGroupFun"
    />
  </el-dialog>
</template>

<script>
export default {
  props: {
    DetailDialogShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sharToGroupShow: false, //分享到群聊
    };
  },
  methods: {
    sendTopGroupFun(i) {
      this.sharToGroupShow = i;
    },
    handleClose() {
      this.$emit("wmDialogShow", false);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .wm_detail {
  position: relative;
  .el-dialog__header {
    display: none;
  }
  .info_box_com {
    min-width: 500px;
    padding: 40px;
    background: #1d1f21;
    .top_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .user_about {
        display: flex;
        align-items: center;
        .u_img {
          position: relative;
          width: 50px;
          height: 50px;
          overflow: hidden;
          border-radius: 50%;
          margin-right: 10px;
          img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .btn_gz {
        cursor: pointer;
        padding: 8px 20px;
        border-radius: 5px;
        background: var(--primary);
      }
    }
    .title {
      margin: 10px 0;
      font-size: 18px;
    }
    .content {
      color: var(--senary);
      margin: 10px 0;
    }
    .collect_like {
      display: flex;
      padding-bottom: 30px;
      border-bottom: 1px solid #4b4b4b;
      .cl_item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
        }
        span {
          font-size: 20px;
        }
      }
    }
    .data_list {
      padding-top: 20px;
      .data_item {
        display: flex;
        align-items: center;
        padding: 10px 0;
        .item_name {
          margin-right: 10px;
        }
        .item_text {
          flex: 1;
          padding: 10px;
          background-color: #292929;
          border-radius: 5px;
        }
      }
    }
    .share {
      margin-top: 80px;
      text-align: center;
      padding: 15px 0;
      background-color: var(--redary);
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    cursor: pointer;
    i {
      font-size: 18px;
    }
  }
}
</style>

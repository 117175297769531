<template>
  <!-- 聊天发送框 -->
  <div class="send_box">
    <div class="send_main">
      <!-- 模型风格选择 -->
      <div class="send_style_box" ref="sendStyle">
        <ul class="send_style">
          <li>
            <el-tree
              class="my-el-tree"
              :data="data"
              :props="defaultProps"
              accordion
              @node-click="handleNodeClick"
            >
            </el-tree>
          </li>
          <!-- <li
            v-for="(item, index) in styleArr"
            :key="item.values"
            :class="{ active: aiStyleIndex == index }"
            @click="aiStyleClick(index, item)"
          >
            <div>
              <span>{{ item.names }}</span>
              <div
                style="height: 100%"
                v-if="aiStyleIndex == index"
                ref="childrenArrList"
              >
                <div v-for="(it, i) in item.ArrList" :key="i">
                  <span>{{ it.names }}</span>
                </div>
              </div>
            </div>
          </li> -->
        </ul>
      </div>
      <div class="chatTextarea-father-box">
        <div class="chatTextarea-father">
          <div
            :contentEditable="!disabled"
            ref="chatTextarea"
            id="chatTextarea"
            class="chatTextarea"
            :class="{ no_drop: disabled, empty: isEmpty }"
            @keyup="handkeKeyUp"
            @keydown="handleKeyDown"
            @blur="chatTextareaClick"
            :placeholder="sendBoxPlaceholder"
          ></div>
          <span
            @click="aiStyleFun(aiStyleText)"
            v-if="flg"
            class="chatTextarea-father-styleText"
          >
            #{{ aiStyleText }}
          </span>
          <!-- <span
            @click="aiStyleFun(aiStyleText)"
            class="chatTextarea-father-styleText"
          >
            #写实风格
          </span> -->
        </div>
      </div>

      <div class="handle">
        <el-popover
          popper-class="send_enjoy_box"
          trigger="click"
          width="600"
          placement="top-end"
          ref="enjoyPop"
          :disabled="disabled"
        >
          <ul class="enjoy_box">
            <li
              v-for="(item, index) in enjoyDataList"
              :key="index"
              @click.stop="wxEmojisClick(item)"
              :title="item.name"
            >
              <img :src="item.src" :alt="item.name" />
            </li>
          </ul>
          <i
            slot="reference"
            class="icon iconfont icon-icon_xiaolian-mian"
            :class="{ no_drop: disabled }"
          ></i>
        </el-popover>
        <el-popover
          popper-class="send_file_box"
          placement="top-end"
          width="200"
          trigger="click"
          :disabled="disabled"
        >
          <ul class="add_file_box">
            <li @click="showMaterial(true)">素材</li>
            <li @click="checkFileSend">图片</li>
          </ul>
          <i
            slot="reference"
            class="icon el-icon-circle-plus-outline"
            :class="{ no_drop: disabled }"
          ></i>
        </el-popover>
      </div>
    </div>
    <!-- 我的素材弹窗 -->
    <MineMaterial
      v-if="mineMaterialShow"
      :mineMaterialShow="mineMaterialShow"
      @closeMaterial="showMaterial"
      @confirmSend="confirmSend"
    />
  </div>
</template>

<script>
import MineMaterial from "@/components/MineMaterial";
import { enjoyList } from "@/utils/enjoy";
import sendChatMsg from "@/utils/sendChatMsg";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    flags: {
      type: Boolean,
      default: false,
    },
    sendBoxPlaceholder: {
      type: String,
      default: "发消息",
    },
    friendItem: {
      type: Object,
    },
    num: {
      type: Number,
    },
  },
  data() {
    return {
      //发送的文字
      sendVal: "",
      // 我的素材弹窗
      mineMaterialShow: false,
      // 表情
      enjoyDataList: [],
      endIndex: "", // 光标最后停留位置
      node: "", // 获取到节点
      chatBlurIndex: "",
      isEmpty: true,
      defaultProps: {
        children: "ArrList",
        label: "names",
      },
      // styleArr: [
      //   {
      //     value: "1",
      //     names: "写实风格",
      //   },
      //   {
      //     value: "2",
      //     names: "探索无限",
      //   },
      //   {
      //     value: "3",
      //     names: "古风",
      //   },
      //   {
      //     value: "4",
      //     names: "二次元",
      //   },
      //   {
      //     value: "5",
      //     names: "浮世绘",
      //   },
      //   {
      //     value: "6",
      //     names: "low poly",
      //   },
      //   {
      //     value: "7",
      //     names: "未来主义",
      //   },
      //   {
      //     value: "8",
      //     names: "像素风格",
      //   },
      //   {
      //     value: "9",
      //     names: "赛博朋克",
      //   },
      //   {
      //     value: "10",
      //     names: "洛丽塔风格",
      //   },
      //   {
      //     value: "11",
      //     names: "巴洛克风格",
      //   },
      //   {
      //     value: "12",
      //     names: "超现实主义",
      //   },
      //   {
      //     value: "13",
      //     names: "水彩画",
      //   },
      //   {
      //     value: "14",
      //     names: "蒸汽波艺术",
      //   },
      //   {
      //     value: "15",
      //     names: "油画",
      //   },
      //   {
      //     value: "16",
      //     names: "卡通画",
      //   },
      // ],
      styleArr: [
        {
          value: "1",
          names: "分类1",
          ArrList: [
            { values: "1", names: "写实风格" },
            { values: "2", names: "探索无限" },
            { values: "3", names: "古风" },
            { values: "4", names: "二次元" },
            { values: "5", names: "浮世绘" },
            { values: "6", names: "low poly" },
            { values: "7", names: "未来主义" },
            { values: "8", names: "像素风格" },
          ],
        },
        {
          value: "2",
          names: "分类2",
          ArrList: [
            { values: "9", names: "赛博朋克" },
            { values: "10", names: "洛丽塔风格" },
            { values: "11", names: "巴洛克风格" },
            { values: "12", names: "超现实主义" },
            { values: "13", names: "水彩画" },
            { values: "14", names: "蒸汽波艺术" },
            { values: "15", names: "油画" },
            { values: "16", names: "卡通画" },
          ],
        },
      ],
      data: [
        {
          label: "分类1",
          ids: "1",
          children: [
            { fatherIds: "1", value: "1", label: "写实风格" },
            { fatherIds: "1", value: "2", label: "探索无限" },
            { fatherIds: "1", value: "3", label: "古风" },
            { fatherIds: "1", value: "4", label: "二次元" },
            { fatherIds: "1", value: "5", label: "浮世绘" },
            { fatherIds: "1", value: "6", label: "low poly" },
            { fatherIds: "1", value: "7", label: "未来主义" },
            { fatherIds: "1", value: "8", label: "像素风格" },
          ],
        },
        {
          label: "分类2",
          ids: "2",
          children: [
            { fatherIds: "2", value: "9", label: "赛博朋克" },
            { fatherIds: "2", value: "10", label: "洛丽塔风格" },
            { fatherIds: "2", value: "11", label: "巴洛克风格" },
            { fatherIds: "2", value: "12", label: "超现实主义" },
            { fatherIds: "2", value: "13", label: "水彩画" },
            { fatherIds: "2", value: "14", label: "蒸汽波艺术" },
            { fatherIds: "2", value: "15", label: "油画" },
            { fatherIds: "2", value: "16", label: "卡通画" },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
        // labels: "labels",
      },
      ids: "", //一级菜单ids
      aiStyleText: "", //ai绘图样式文本
      aiStyleIndex: null, //ai绘图样式索引
      arr1: [],
      str: "",
      flg: false,
    };
  },
  watch: {
    flags: {
      handler(val) {
        this.flg = val;
      },
    },
    sendVal: {
      handler(val) {
        this.$nextTick(() => {
          let sendStyle = this.$refs.sendStyle;
          if (val == "/t" && this.flg == false) {
            // this.flg = true;
            sendStyle.style.display = "block";
          } else if (val == "" || val == "/" || this.aiStyleText == "") {
            this.flg = false;
            this.aiStyleText = "";
            sendStyle.style.display = "none";
          }
        });

        if (val.length > 0) {
          this.isEmpty = false;
        } else {
          this.isEmpty = true;
        }
      },
      immediate: true,
      deep: true,
    },
    // str: {
    //   handler(val) {
    //     // this.sendVal = val;
    //     this.$nextTick(() => {
    //       this.$refs.chatTextarea.innerHTML = val;
    //     });
    //     console.log(val.split(""), 1111111111);
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    // aiStyleText: {
    //   handler(val) {
    //     if (val != "") {
    //       this.aiStyleText = val;
    //     }
    //     console.log("类型风格--", val);
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },

  mounted() {
    let arr = [];
    for (const en in enjoyList) {
      arr.push({
        name: en,
        src: enjoyList[en],
      });
    }
    this.enjoyDataList = arr;
  },
  methods: {
    // ai/t生图style类型风格选择
    aiStyleFun(text) {
      this.$nextTick(() => {
        let sendStyle = this.$refs.sendStyle;
        sendStyle.style.display = "block";
        console.log("点击了类型风格切换", text, this.aiStyleIndex);
      });
    },
    //点击表情包
    wxEmojisClick(item) {
      // let wxEmojis = this.wxEmojis(item);
      // console.log("wxEmojis", wxEmojis);
      this.$refs.chatTextarea.focus();
      this.insertHtmlAtCaret(
        `<img class="inp_img" src="${item.src}" data-name="${item.name}"/>`
      );

      this.sendVal = this.$refs.chatTextarea.innerHTML;
      this.$refs.enjoyPop.doClose();
    },
    //选择表情包后在聊天框内插入html
    insertHtmlAtCaret(html) {
      return sendChatMsg.insertHtmlAtCaret(html, this.chatBlurIndex);
    },
    insertTextAtCaret(text) {
      return sendChatMsg.insertTextAtCaret(text, this.chatBlurIndex);
    },
    //点击聊天框
    chatTextareaClick() {
      // console.log("点击聊天框");
      //this.$refs.chatTextarea.focus();
      // 获取选定对象
      var selection = getSelection();
      // 设置最后光标对象
      this.chatBlurIndex = selection.getRangeAt(0);
    },
    handleNodeClick(data) {
      this.flg = true;
      let sendStyle = this.$refs.sendStyle;
      this.ids = data.ids != undefined ? data.ids : data.fatherIds; // 一级菜单ids值
      this.aiStyleText =
        data.value != undefined || null || "" ? data.label : ""; //二级菜单label值
      // [it.names, this.styleArr]
      this.$emit("aiStyleText", {
        styleType: this.aiStyleText,
        ids: this.ids,
      });
      if (data.value != undefined || null || "") {
        sendStyle.style.display = "none";
      }

      console.log(
        // data.fatherIds,
        // data.value,
        data.label,
        // this.aiStyleText,
        // this.ids,
        "二级菜单"
      );
    },
    aiStyleClick(i, it) {
      // console.log(i, it, it.ArrList, 99999999);
      // this.flg = true;
      let sendStyle = this.$refs.sendStyle;
      // sendStyle.style.display = "none";
      if (this.aiStyleIndex != "" && this.aiStyleIndex == i) {
        console.log("相同");
      }
      this.aiStyleIndex = i;
      this.aiStyleText = it.names;
      // [it.names, this.styleArr]
      this.$emit("aiStyleText", {
        styleType: this.aiStyleText,
        styleArr: this.styleArr,
      });
      // this.arr1.push(this.aiStyleText);
      // this.str = this.arr1.join("");
      // this.sendVal = this.str;
      // console.log("点击", this.sendVal);
    },
    // 键盘抬起事件
    handkeKeyUp(e) {
      // if (this.showAt()) {
      const node = this.getRangeNode();
      const endIndex = this.getCursorIndex();
      this.node = node;
      this.endIndex = endIndex;
      this.sendVal = this.$refs.chatTextarea.innerHTML;
      // this.sendVal = this.str;
      // console.log("键盘抬起时间", this.sendVal, this.str);
      // this.position = this.getRangeRect();
      // this.queryString = this.getAtUser() || "";
      // this.atDialogShow = true;
      // } else {
      //   this.atDialogShow = false;
      // }
    },
    // 是否展示 @
    showAt() {
      return sendChatMsg.showAt();
    },

    // 键盘按下事件
    handleKeyDown(e) {
      if (e.shiftKey && e.code === "Enter") {
        // e.preventDefault();
      } else if (e.ctrlKey && e.code === "KeyV") {
        e.preventDefault();
        navigator.clipboard
          .readText()
          .then((text) => {
            this.chatTextareaClick();
            this.insertTextAtCaret(text);
          })
          .catch((err) => {
            console.error("无法读取剪贴板内容：", err);
          });
      } else if (e.key === "Enter") {
        // 发送
        e.preventDefault();
        this.sendVal = this.$refs.chatTextarea.innerHTML;
        let enjoyNode = document.querySelectorAll(".chatTextarea img");
        //遍历表情包，将表情包节点替换成转义表情包字符：[微笑]
        for (let i = 0; i < enjoyNode.length; i++) {
          for (let j = 0; j < this.enjoyDataList.length; j++) {
            if (
              enjoyNode[i].getAttribute("data-name") ==
              this.enjoyDataList[j].name
            ) {
              // 将表情包节点替换成转义表情包字符：[微笑]
              this.sendVal = this.sendVal.replace(
                enjoyNode[i].outerHTML.toString(),
                this.enjoyDataList[j].name
              );
            }
          }
        }

        this.sendVal = this.trimHtml(this.sendVal);
        this.sendMsg();

        this.$refs.chatTextarea.innerHTML = "";
      }
    },

    //从html代码中获取纯文本
    trimHtml(str) {
      return sendChatMsg.trimHtml(str);
    },

    // 获取节点
    getRangeNode() {
      return sendChatMsg.getRangeNode();
    },
    // 获取光标位置
    getCursorIndex() {
      return sendChatMsg.getCursorIndex();
    },

    // 发送素材
    confirmSend(data) {
      this.$emit("sendShare", data);
    },

    // 展示关闭素材弹窗
    showMaterial(i) {
      if (this.disabled != false) {
        this.$message("ai生图中，请稍后...");
      } else if (this.disabled == false) {
        this.mineMaterialShow = i;
      }
    },

    // 发送文件
    checkFileSend() {
      //
      if (this.disabled != false) {
        this.$message("ai生图中，请稍后...");
      } else if (this.disabled == false) {
        let input = document.createElement("input");
        let file = "";
        input.type = "file";
        input.style.display = "none";
        document.body.appendChild(input);
        input.click();
        let formData = new FormData();
        formData.append("type", "head");
        input.onchange = () => {
          file = input.files[0];
          formData.append("Image", file);
          if (file.type.startsWith("image/")) {
            // 图片上传
            this.$emit("sendImg", formData);
          } else {
            // 文件上传
            this.$emit("sendFile", formData);
          }
          document.body.removeChild(input);
        };
      }
    },
    disabledFun() {
      this.$emit("disabledFlg", true);
    },
    // 发送消息
    sendMsg() {
      //  || "/t" || "/s" .indexOf("/w") !== -1 .substr(1)
      let sendValStr = this.sendVal.trim().toString();
      if (this.num == 1) {
        if (
          sendValStr.slice(0, 2) == "/w" ||
          sendValStr.slice(0, 2) == "/t" ||
          sendValStr.slice(0, 2) == "/s"
        ) {
          localStorage.removeItem("aiImgUrl2");
          this.disabledFun();
        }
      }
      if (this.num == 2) {
        if (
          this.friendItem.friend_id == "6" &&
          (sendValStr.slice(0, 2) == "/w" ||
            sendValStr.slice(0, 2) == "/t" ||
            sendValStr.slice(0, 2) == "/s")
        ) {
          localStorage.removeItem("aiImgUrl2");
          this.disabledFun();
        }
      }
      // if ((this.num == 2 && this.friendItem.friend_id == "6")(sendValStr.slice(0, 2) == "/w" ||sendValStr.slice(0, 2) == "/t" ||sendValStr.slice(0, 2) == "/s")) {
      //   // console.log("召唤机器人", sendValStr);
      //   this.disabledFun();
      // }

      if (this.sendVal.trim() != "") {
        localStorage.removeItem("aiImgUrl2");
        this.$emit("SendMsgClick", this.sendVal);
      } else {
        this.$message.warning("不能发送空消息");
      }
    },
  },
  components: {
    MineMaterial,
  },
};
</script>
<style lang="scss" scoped>
.send_box {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 20px 20px;

  ::v-deep .send_main {
    position: relative;

    .el-textarea {
      padding: 5px 0;
    }

    .el-textarea__inner {
      background-color: var(--blackary);
      border: 0;
      color: #fff;
      height: 50px !important;
      padding-right: 120px;
      resize: none;
      line-height: 40px;
    }

    .handle {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);

      .icon {
        font-size: 20px;
        margin: 0 8px;
        cursor: pointer;
        color: #d4d4d4;
      }

      .no_drop::before {
        cursor: no-drop !important;
      }
    }
  }
}
.chatTextarea {
  height: 50px;
  // background: #26282b;
  padding: 5px 0px 5px 10px;
  border: none;
  color: #fff;
  resize: none;
  line-height: 22px;
  position: relative;
  overflow-y: auto;
  word-wrap: break-word;
  width: 100%;
  // border: 1px solid red;
  max-width: 80%;

  // display: flex;
  // justify-content: center;
  // align-items: center;
  &.empty::before {
    content: attr(placeholder);
    font-size: 14px;
  }
}
.no_drop {
  cursor: no-drop !important;
}
.send_style_box {
  background-color: rgba(0, 0, 0, 0.5);
  // background-color: rgba(255, 255, 255, 0.1);
  height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: none;
  position: absolute;
  bottom: 5.5vh;
  transition: all 0.5s;
  border-radius: 5px;
  .send_style {
    padding: 10px;
    border-radius: 5px;
    li {
      height: 50px;
      line-height: 50px;
      text-align: center;
      margin-bottom: 10px;
      cursor: pointer;
      // padding: 0 20px;
      .my-el-tree {
        // background-color: rgba(0, 0, 0, 0.5);
        background-color: transparent;
        width: 125px;
      }
      ::v-deep .el-tree-node__content {
        height: 100%;
        transition: all 0.5s;
        border-radius: 5px;
        margin-bottom: 10px;
        &:hover {
          background-color: #2b2d31;
        }
      }
      ::v-deep .el-tree-node.is-current > .el-tree-node__content {
        background-color: #2b2d31 !important;
      }
    }
    .active {
      // background-color: #2b2d31;
    }
  }
  .active {
    background-color: #2b2d31;
  }
}
.chatTextarea-father-box {
  .chatTextarea-father {
    position: relative;
    width: 100%;
    background: #26282b;
    display: flex;
    align-items: center;
    height: 50px;
    padding-right: 50px;
    .chatTextarea-father-styleText {
      // margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      height: 50px;
      // padding: 5px 0 5px 10px;
      // position: absolute;
      // left: 30px;
      // top: 5px;
    }
  }
}
</style>

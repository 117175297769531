<template>
  <el-form
    :model="uploadForm"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    class="demo-ruleForm"
    label-position="top"
  >
    <el-form-item label="上传素材" prop="fileUrl">
      <el-upload
        class="upload-demo"
        drag
        multiple
        v-model="uploadForm.fileUrl"
        name="Image"
        :action="url"
        :before-upload="beforeUpload"
        :on-success="handleBgImageSuccess"
        :limit="1"
        ref="mYupload"
        :on-preview="handlePreview"
        list-type="picture"
      >
        <div><i class="el-icon-plus"></i>添加素材</div>
      </el-upload>
      <!-- 预览图片 -->
      <el-dialog
        append-to-body
        title="预览"
        :visible.sync="dialogVisible"
        width="50%"
        custom-class="dialogPreviewImg"
      >
        <span>
          <p>
            <img class="imsg" :src="dialogUrl" alt="" />
          </p>
        </span>
      </el-dialog>
    </el-form-item>
    <el-form-item label="标题" prop="title">
      <el-input
        v-model="uploadForm.title"
        placeholder="请输入标题"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item label="请选择题材分类" prop="tags">
      <el-checkbox-group
        v-model="uploadForm.tags"
        size="medium"
        :max="3"
        fill="#e35d44"
      >
        <el-checkbox-button
          v-for="(item, index) in tagList"
          :key="index"
          :label="item"
          >{{ item.tagName }}</el-checkbox-button
        >
      </el-checkbox-group>
    </el-form-item>
    <el-form-item label="谁可以看" prop="permission">
      <el-radio
        fill="#e35d44"
        v-for="(item, index) in astrictList"
        :key="index"
        v-model="uploadForm.permission"
        :label="item.value"
        >{{ item.name }}</el-radio
      >
    </el-form-item>
    <el-form-item label="授权信息" prop="accreditType">
      <!--   v-model="accreditArrValue" -->
      <el-select
        v-model="uploadForm.accreditType"
        placeholder="请选择"
        :popper-append-to-body="false"
      >
        <el-option
          v-for="item in accreditArr"
          :key="item.value"
          :label="item.label"
          :value="item"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="内容简介" prop="content">
      <el-input
        type="textarea"
        v-model="uploadForm.content"
        placeholder="请填写简介"
      ></el-input>
    </el-form-item>

    <!-- <el-form-item class="use_price" label="使用价格" prop="cut">
      <el-radio fill="#e35d44" v-for="(item, index) in CutList" :key="index" v-model="uploadForm.cut"
        style="color: #ffffff" :label="item.cutValue">{{ item.cutName }}</el-radio>
      <el-input v-model="uploadForm.cut" placeholder="堆币" clearable size="small" class="price_inp"></el-input>
    </el-form-item> -->
  </el-form>
</template>

<script>
import { textA } from "@/utils/index";
export default {
  props: {
    ImageType: "",
    activeType: "",
  },
  data() {
    return {
      accreditArrValue: "",
      accreditArr: "", //授权信息
      dialogVisible: false,
      dialogUrl: "",
      uploadForm: {
        sucaiID: "", //图片Id
        fileUrl: "", //素材文件
        title: "", //标题
        tags: [], //题材标签
        strTags: "", //题材标签字符串
        permission: "", //权限
        content: "", //内容简介
        cut: "", //0 普通贴 1 付费贴 2 堆币贴 使用价格
        price: "", //堆币数数量
        basemodel: "", // 基础模型
        modeltype: "", //模型类型：
        modelversion: "", //模型版本号：
        relevancegp: "", //关联我的群聊：【群id】
      },
      rules: {
        // 文件类型
        fileUrl: [
          {
            required: true,
            message: "文件类型不能为空",
            trigger: ["blur", "change"],
          },
        ],
        // 标题
        title: [
          {
            required: true,
            message: "标题不能为空",
            trigger: "blur",
          },
        ],
        // // 题材标签
        tags: [
          {
            type: "array",
            required: true,
            message: "最少选择一个，最多选择三个",
            trigger: "change",
          },
        ],
        // 权限
        permission: [
          { required: true, message: "请选择权限", trigger: "change" },
        ],
        // 授权信息
        accreditType: [
          { required: true, message: "请选择一个授权信息", trigger: "change" },
        ],
        // 内容简介
        content: [
          { required: true, message: "内容简介不能为空", trigger: "blur" },
        ],
        cut: [
          {
            required: true,
            message: "请输入堆币数",
            trigger: ["blur"],
          },
        ],
        // 基础模型
        basemodel: [
          {
            required: true,
            message: "请选择基础模型",
            trigger: ["blur", "change"],
          },
        ],
        // modeltype 模型类型：
        modeltype: [
          {
            required: true,
            message: "请选择模型类型",
            trigger: ["blur", "change"],
          },
        ],
        // 模型版本号
        modelversion: [
          {
            required: true,
            message: "模型版本号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        // 关联我的群聊：【群id】
        relevancegp: [
          {
            required: true,
            message: "请选择关联群聊",
            trigger: ["blur", "change"],
          },
        ],
      },
      checked: false,
      //   题材分类数据

      tagList: [],
      //   谁可以看数据
      astrictList: [
        {
          name: "公开",
          value: 0,
        },
        {
          name: "私有",
          value: 1,
        },
      ],
      // 使用价格
      CutList: [
        {
          cutName: "免费",
          cutValue: 0,
        },
        {
          cutName: "金额",
          cutValue: 1,
        },
        {
          cutName: "堆币",
          cutValue: 2,
        },
      ],
      //   授权数据
      accreditTypeList: [{}],
      url: "",
    };
  },
  mounted() {
    this.ShowtagList();
    this.ShowaccreditTypeList();
    this.url = this.$http.adornUrl("/app/common/upload");
  },
  watch: {
    ImageType(newVal) {
      let tags = [];
      this.uploadForm.tags.forEach((item) => {
        tags.push(item.id);
        // console.log(1111, tags);
      });
      if (this.uploadForm.title == "") {
        this.$message.error("请输入标题");
        return;
      } else if (tags.length == 0) {
        this.$message.error("请选择题材分类");
        return;
      } else if (
        this.uploadForm.accreditType == "" ||
        this.uploadForm.accreditType == null
      ) {
        this.$message.error("请选择授权信息");
        return;
      } else if (this.uploadForm.fileUrl == "") {
        this.$message.error("请选择素材");
        return;
      } else if (this.uploadForm.content == "") {
        this.$message.error("内容不能为空");
        return;
      }

      // this.uploadForm.accreditType = this.accreditArrValue.value;
      this.curNoDropFlgFun();
      let data = {
        // 配合比赛
        isforcepe: 0,
        //素材作者
        author: JSON.parse(localStorage.getItem("User")).username,
        //题材分类
        cateid: 1,
        filepath: "",
        //是否免费：0.免费 1.付费
        isfree: 0,
        //权限：0.公开 1.私有
        isopen: this.uploadForm.permission,
        //素材标题
        title: this.uploadForm.title,
        //素材价格
        price: 0,
        //题材标签
        // tags: JSON.stringify(tags),
        tags: tags.join(","),
        // 素材类型：1图片、2文字、3视频、4音频、5模型
        type: 1,
        uid: JSON.parse(localStorage.getItem("User")).uid,
        content: this.uploadForm.content,
        // 时长
        duration: "",
        //授权类型
        accreditType: this.uploadForm.accreditType.value,
        ossEntities: [
          {
            baseId: "", //文件关联业务id
            baseType: "", //文件种类
            createDate: "", //创建时间
            fileType: 1, //文件类型：1图片、2文字、3视频、4音频
            id: this.uploadForm.fileUrl, //id
            url: this.uploadForm.sucaiUrl,
          },
          //文件路径
        ], //文件

        //基础模型
        basemodel: "",
        //模型类型
        modeltype: "",
        //模型版本号
        modelversion: "",
        //关联我的群聊
        relevancegp: "",
      };
      // return;
      this.$http({
        url: this.$http.adornUrl("/app/material/add"),
        method: "post",
        data: this.$http.adornData(data),
      }).then((data) => {
        // console.log(1223);
        if (data.data.code === 0) {
          // console.log(data,1232);
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.$router.push({ name: "home" });
          location.reload();
          // this.uploadForm = {
          //   sucaiID: "", //图片Id
          //   fileUrl: "", //素材文件
          //   title: "", //标题
          //   tags: [], //题材标签
          //   strTags: "", //题材标签字符串
          //   permission: "", //权限

          //   content: "", //内容简介
          //   cut: "", //0 普通贴 1 付费贴 2 堆币贴 使用价格
          //   price: "", //堆币数数量
          //   basemodel: "", // 基础模型
          //   modeltype: "", //模型类型：
          //   modelversion: "", //模型版本号：
          //   relevancegp: "", //关联我的群聊：【群id】
          // }
          // // 清空授权
          // this.accreditArrValue = ""
          // // 清空上传
          // this.$refs['mYupload'].clearFiles();
        }
      });
    },
  },
  methods: {
    curNoDropFlgFun() {
      this.$emit("curNoDropFlgFun", false);
    },
    // 上传图片
    handleBgImageSuccess(response, file, fileList) {
      console.log(response, this.activeType, 348);
      this.uploadForm.sucaiUrl = response.result;
      this.uploadForm.fileUrl = response.id;
    },
    beforeUpload(file) {
      console.log("上传判断", file, this.activeType);
      let isJPG = file.type === "image/jpeg";
      let isPng = file.type === "image/png";
      let iswebp = file.type === "image/webp";
      let isGif = file.type === "image/gif";
      // 1图片、2视频、3音频、4文字
      if (this.activeType == "1" && !isJPG && !isPng && !iswebp && !isGif) {
        this.$message({
          message: "当前格式不支持",
          type: "warning",
        });
        return isJPG && isPng && iswebp;
      }
    },
    // 点击文件预览弹出框
    handlePreview(file) {
      console.log(file);
      this.dialogVisible = true;
      this.dialogUrl = file.response.result;
      // this.$alert('<img src="' + file.url + '" style="width:100%" />', '预览图片');
    },
    // 显示所有的授权信息
    ShowaccreditTypeList() {
      this.$http({
        url: this.$http.adornUrl("/app/material/getAccreditType"),
        method: "get",
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.accreditArr = textA(data.accreditType);
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    // 显示所有的题材分类
    ShowtagList() {
      this.$http({
        url: this.$http.adornUrl("/app/post/tagPost"),
        method: "get",
        params: this.$http.adornParams({
          // 题材分类id
          cateid: 1,
        }),
      }).then((data) => {
        if (data.data.code === 0) {
          this.tagList = data.data.tagList;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../component.scss";
</style>

<template>
  <!-- 画同款-->
  <div class="draw_main_box">
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item class="form_item" label="提示词">
        <el-input type="textarea" readonly v-model="form.name"></el-input>
        <div class="copy"><i class="el-icon-document-copy"></i>复制</div>
      </el-form-item>
      <el-form-item class="form_item" label="反向提示词">
        <el-input type="textarea" readonly v-model="form.name"></el-input>
        <div class="copy"><i class="el-icon-document-copy"></i>复制</div>
      </el-form-item>
      <el-form-item label="采样">
        <el-input readonly v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="模型">
        <el-input readonly v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="相关性">
        <el-input readonly v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="步数">
        <el-input readonly v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="随即种">
        <el-input readonly v-model="form.name"></el-input>
      </el-form-item>
    </el-form>
    <div class="handle_btn">
      <div class="btn">立即出图</div>
      <div class="btn">专业出图</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: "1.6",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.draw_main_box {
  display: none;
  background: var(--blackary) !important;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 30px 20px 20px;
  width: 500px;
  border-radius: 5px;
  z-index: 10;

  .form_item {
    position: relative;
    .copy {
      position: absolute;
      top: 5px;
      right: 10px;
      cursor: pointer;
      &:hover {
        color: var(--redary);
        i {
          color: var(--redary);
        }
      }

      i {
        margin-right: 2px;
      }
    }
  }
  ::v-deep .el-input__inner {
    background: #292929;
    color: #fff;
    border: 0;
    cursor: unset;
  }
  ::v-deep .el-form-item__label {
    color: #fff;
  }
  ::v-deep .el-textarea__inner {
    height: 90px;
    resize: none;
    background: #292929;
    cursor: unset;
    border: 0;
  }
  .handle_btn {
    display: flex;
    // justify-content: center;
    .btn {
      flex: 1;
      text-align: center;
      padding: 15px 0;
      background: var(--redary);
      margin-right: 20px;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background: var(--redary_h);
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
</style>

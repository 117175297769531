<template>
  <div class="fans_box">
    <template v-if="fansList && fansList.length">
      <ul class="user_list">
        <li class="user_item" v-for="(item, index) in fansList" :key="index">
          <div class="item_left">
            <div class="user_img">
              <img :src="item.avatar" alt="头像" />
            </div>
            <div class="user_info">
              <div class="user_name">{{ item.username }}</div>
              <div class="user_id">ID:{{ item.uid }}</div>
            </div>
          </div>
          <div class="ltem_right">
            <div class="btn cancel" @click="delFans(item)">移除</div>
            <template v-if="item.hasFollow == 2">
              <div class="btn cancel" style="cursor: no-drop">互相关注</div>
            </template>
            <template v-if="item.hasFollow == 0">
              <div class="btn confirm" @click="followMember(item)">回关</div>
            </template>
          </div>
        </li>
      </ul>
    </template>
    <template v-else>
      <div class="empty_box">暂无粉丝</div>
    </template>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      pageFans: 1,
      fansList: [],
    };
  },
  created() {
    this.getUserFans();
  },
  methods: {
    // 移除粉丝
    delFans(item) {
      this.$confirm(`您确定要移除粉丝 ${item.username} 吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl("/app/user/delFans"),
            method: "get",
            params: this.$http.adornParams({
              uid: item.uid,
            }),
          }).then(({ data }) => {
            if (data.code == 0) {
              this.getUserFans();
              this.$message.success(data.msg);
            } else {
              this.$message.error(data.msg);
            }
          });
        })
        .catch(() => {});
    },
    // 回关
    followMember(item) {
      console.log(item);
      this.$http({
        url: this.$http.adornUrl("/app/user/addFollow"),
        method: "post",
        data: this.$http.adornData({
          id: item.uid,
        }),
      }).then(({ data }) => {
        console.log(data);
        if (data.code == 0) {
          this.getUserFans();
          this.getAllFriend();
          this.$message.success(data.msg);
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    // 获取粉丝
    getUserFans() {
      this.$http({
        url: this.$http.adornUrl("/app/user/userFans"),
        method: "get",
        params: this.$http.adornParams({
          page: this.pageFans,
        }),
      }).then(({ data }) => {
        this.fansList = data.result.data;
        console.log("我的粉丝", this.fansList);
      });
    },
    ...mapActions(["getAllFriend"]),
  },
};
</script>


<style lang="scss" scoped>
@import "./index.scss";
</style>
<template>
  <div class="empty_box">
    <el-empty :image-size="imgSize" :description="description"></el-empty>
  </div>
</template>
<script>
export default {
  props: {
    // 图片大小
    imgSize: {
      type: Number,
      default: 200,
    },
    // 提示文字
    description: {
      default: "暂无数据",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>



<template>
  <el-form
    :model="uploadForm"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    class="demo-ruleForm"
    label-position="top"
  >
    <el-form-item label="上传素材" prop="fileUrl">
      <el-upload
        class="upload-demo"
        drag
        multiple
        v-model="uploadForm.fileUrl"
        name="Image"
        :action="url"
        :before-upload="beforeUploadAidio"
        :on-success="handleBgImageSuccessAidio"
        :limit="1"
        ref="mYupload"
        list-type="picture"
      >
        <div><i class="el-icon-plus"></i>添加素材</div>
      </el-upload>
    </el-form-item>

    <el-form-item label="上传音乐封面" prop="fileUrl">
      <el-upload
        class="upload-demo"
        drag
        multiple
        v-model="uploadForm.AudioUrlL"
        name="Image"
        :action="url"
        :before-upload="beforeUpload"
        :on-success="handleBgImageSuccess"
        :limit="1"
        ref="mYupload"
        :on-preview="handlePreview"
        list-type="picture"
      >
        <div><i class="el-icon-plus"></i>添加素材</div>
      </el-upload>
      <!-- 预览图片 -->
      <el-dialog
        append-to-body
        title="预览"
        :visible.sync="dialogVisible"
        width="50%"
        custom-class="dialogPreviewImg"
      >
        <span>
          <p>
            <img class="imsg" :src="dialogUrl" alt="" />
          </p>
        </span>
      </el-dialog>
    </el-form-item>
    <el-form-item label="标题" prop="title">
      <el-input
        v-model="uploadForm.title"
        placeholder="请输入标题"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item label="请选择题材分类" prop="tags">
      <el-checkbox-group
        v-model="uploadForm.tags"
        size="medium"
        :max="3"
        fill="#e35d44"
      >
        <el-checkbox-button
          v-for="(item, index) in tagList"
          :key="index"
          :label="item"
          >{{ item.tagName }}</el-checkbox-button
        >
      </el-checkbox-group>
    </el-form-item>
    <el-form-item label="谁可以看" prop="permission">
      <el-radio
        fill="#e35d44"
        v-for="(item, index) in astrictList"
        :key="index"
        v-model="uploadForm.permission"
        :label="item.value"
        >{{ item.name }}</el-radio
      >
    </el-form-item>
    <el-form-item label="授权信息" prop="accreditType">
      <!--   v-model="accreditArrValue" -->
      <el-select
        v-model="uploadForm.accreditType"
        placeholder="请选择"
        :popper-append-to-body="false"
      >
        <el-option
          v-for="item in accreditArr"
          :key="item.value"
          :label="item.label"
          :value="item"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="内容简介" prop="content">
      <el-input
        type="textarea"
        v-model="uploadForm.content"
        placeholder="请填写简介"
      ></el-input>
    </el-form-item>

    <!-- <el-form-item class="use_price" label="使用价格" prop="cut">
      <el-radio
        fill="#e35d44"
        v-for="(item, index) in CutList"
        :key="index"
        v-model="uploadForm.cut"
        style="color: #ffffff"
        :label="item.cutValue"
        >{{ item.cutName }}</el-radio
      >
      <el-input
        v-model="uploadForm.cut"
        placeholder="堆币"
        clearable
        size="small"
        class="price_inp"
      ></el-input>
    </el-form-item> -->
  </el-form>
</template>
<script>
import { textA } from "@/utils/index";
export default {
  props: {
    AudioType: "",
    activeType: "",
  },
  data() {
    return {
      duration: 0,
      voiceUrl: "",
      accreditArr: "",
      dialogVisible: false,
      dialogUrl: "",
      url: "",
      uploadForm: {
        filecover: "",
        filecoverId: "",
        AudioUrlL: "",
        fileUrl: "", //素材文件
        title: "", //标题
        tags: [], //题材标签
        strTags: "", //题材标签字符串
        permission: -1, //权限
        accreditType: null, //授权信息
        content: "", //内容简介
        cut: "", //0 普通贴 1 付费贴 2 堆币贴 使用价格
        price: "", //堆币数数量
        basemodel: "", // 基础模型
        modeltype: "", //模型类型：
        modelversion: "", //模型版本号：
        relevancegp: "", //关联我的群聊：【群id】
      },
      rules: {
        // 文件类型
        fileUrl: [
          {
            required: true,
            message: "文件类型不能为空",
            trigger: ["change"],
          },
        ],
        // 标题
        title: [
          {
            required: true,
            message: "标题不能为空",
            trigger: "blur",
          },
        ],
        // // 题材标签
        tags: [
          {
            type: "array",
            required: true,
            message: "最少选择一个，最多选择三个",
            trigger: "change",
          },
        ],
        // 权限
        permission: [
          { required: true, message: "请选择权限", trigger: "change" },
        ],
        // 授权信息
        accreditType: [
          { required: true, message: "请选择一个授权信息", trigger: "change" },
        ],
        // 内容简介
        content: [
          { required: true, message: "内容简介不能为空", trigger: "blur" },
        ],
        cut: [
          {
            required: true,
            message: "请输入堆币数",
            trigger: ["blur", "change"],
          },
        ],
        // 基础模型
        basemodel: [
          {
            required: true,
            message: "请选择基础模型",
            trigger: ["blur", "change"],
          },
        ],
        // modeltype 模型类型：
        modeltype: [
          {
            required: true,
            message: "请选择模型类型",
            trigger: ["blur", "change"],
          },
        ],
        // 模型版本号
        modelversion: [
          {
            required: true,
            message: "模型版本号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        // 关联我的群聊：【群id】
        relevancegp: [
          {
            required: true,
            message: "请选择关联群聊",
            trigger: ["blur", "change"],
          },
        ],
      },

      //   题材分类数据

      tagList: [
        "AI",
        "人物",
        "动物",
        "人物",
        "动物",
        "人物",
        "动物",
        "人物",
        "动物",
      ],
      //   谁可以看数据
      astrictList: [
        {
          name: "公开",
          value: 0,
        },
        {
          name: "私有",
          value: 1,
        },
      ],
      // 使用价格
      CutList: [
        {
          cutName: "免费",
          cutValue: 0,
        },
        {
          cutName: "金额",
          cutValue: 1,
        },
        {
          cutName: "堆币",
          cutValue: 2,
        },
      ],
      //   授权数据
      accreditTypeList: [{}],
    };
  },
  mounted() {
    this.url = this.$http.adornUrl("/app/common/upload");
    // 题材分类
    this.ShowtagList();
    this.ShowaccreditTypeList();
  },
  methods: {
    getTimes(file) {
      var content = file;
      // 获取录音时长
      var url = URL.createObjectURL(content); // 重点
      // 经测试，发现audio也可获取视频的时长
      this.$refs.audio = new Audio(url);
      this.$refs.audio.addEventListener("loadedmetadata", (_event) => {
        // console.log(12312321);
        this.duration = parseInt(this.$refs.audio.duration) * 1000;
        console.log(this.duration, 222);
      });
    },

    // 上传音频成功
    handleBgImageSuccessAidio(response) {
      if (response.code == 0) {
        this.uploadForm.sucaiUrl = response.result;
        this.uploadForm.fileUrl = response.id;
      } else {
        this.$message({
          message: response.msg,
          type: "warning",
        });
      }
    },
    // 上传音频判断类型
    beforeUploadAidio(file) {
      console.log(file, 2333);
      if (Number(file.size / 1024 / 1024) >= 100) {
        this.$message({
          message: "文件大小不可超过50M",
          type: "warning",
        });
        return false;
      } else if (
        file.type != "audio/mpeg" &&
        file.type != "audio/flac" &&
        file.type != "audio/wav" &&
        file.type != "audio/aac" &&
        file.type != "audio/aac" &&
        file.type != "audio/m4a" &&
        file.type != "audio/wma" &&
        file.type != "audio/ape" &&
        file.type != "audio/mid" &&
        file.type != "audio/ogg" &&
        file.type != "audio/aif" &&
        file.type != "audio/pcm"
      ) {
        this.$message({
          message: "当前格式不支持",
          type: "warning",
        });
        return false;
      }
      this.getTimes(file);
    },

    // 上传封面
    handleBgImageSuccess(response, file, fileList) {
      // console.log("音乐封面", this.activeType);
      this.uploadForm.filecover = response.result;
      this.uploadForm.filecoverId = response.id;
    },
    beforeUpload(file) {
      let isJPG = file.type === "image/jpeg";
      let isPng = file.type === "image/png";
      let iswebp = file.type === "image/webp";
      // 1图片、2视频、3音频、4文字
      if (this.activeType == "3" && !isJPG && !isPng && !iswebp) {
        this.$message({
          message: "当前格式不支持",
          type: "warning",
        });
        return isJPG && isPng && iswebp;
      }
    },
    // 点击文件预览弹出框
    handlePreview(file) {
      console.log(file);
      this.dialogVisible = true;
      this.dialogUrl = file.response.result;
      // this.$alert('<img src="' + file.url + '" style="width:100%" />', '预览图片');
    },
    // 显示音乐题材分类
    ShowtagList() {
      this.$http({
        url: this.$http.adornUrl("/app/post/tagPost"),
        method: "get",
        params: this.$http.adornParams({
          // 题材分类id
          cateid: 3,
        }),
      }).then((data) => {
        if (data.data.code === 0) {
          this.tagList = data.data.tagList;
        }
      });
    },

    // 显示所有的授权信息
    ShowaccreditTypeList() {
      this.$http({
        url: this.$http.adornUrl("/app/material/getAccreditType"),
        method: "get",
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.accreditArr = textA(data.accreditType);
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    AudioCurNoDropFlgFun() {
      this.$emit("AudioCurNoDropFlgFun", false);
    },
  },
  watch: {
    AudioType(newVal) {
      console.log(1223);

      let tags = [];
      this.uploadForm.tags.forEach((item) => {
        tags.push(item.id);
        // console.log(1111, tags);
      });
      if (this.uploadForm.title == "") {
        this.$message.error("请输入标题");
        return;
      } else if (tags.length == 0) {
        this.$message.error("请选择题材分类");
        return;
      } else if (
        this.uploadForm.accreditType == "" ||
        this.uploadForm.accreditType == null
      ) {
        this.$message.error("请选择授权信息");
        return;
      } else if (this.uploadForm.sucaiUrl == "") {
        this.$message.error("请选择素材");
        return;
      } else if (this.uploadForm.content == "") {
        this.$message.error("内容不能为空");
        return;
      } else if (this.uploadForm.permission == -1) {
        console.log(this.uploadForm.permission, 33);
        this.$message.error("权限不能为空");
        return;
      }

      this.AudioCurNoDropFlgFun();
      let data = {
        // 配合比赛
        isforcepe: 0,
        //素材作者
        author: JSON.parse(localStorage.getItem("User")).username,
        //题材分类
        cateid: 3,
        filepath: "",
        //是否免费：0.免费 1.付费
        isfree: 0,
        //权限：0.公开 1.私有
        isopen: this.uploadForm.permission,
        //素材标题
        title: this.uploadForm.title,
        //素材价格
        price: 0,
        //题材标签
        // tags: JSON.stringify(tags),
        tags: tags.join(","),
        // 素材类型：1图片、2文字、3视频、4音频、5模型
        type: 4,
        uid: JSON.parse(localStorage.getItem("User")).uid,
        content: this.uploadForm.content,
        // 时长
        duration: this.duration,
        //封面
        filecover: [
          {
            baseId: "", //文件关联业务id
            baseType: "", //文件种类
            createDate: "", //创建时间
            fileType: 4, //文件类型：1图片、2文字、3视频、4音频
            id: this.uploadForm.filecoverId, //id
            url: this.uploadForm.filecover,
          },
          //文件路径
        ],

        //授权类型
        accreditType: this.uploadForm.accreditType.value,
        ossEntities: [
          {
            baseId: "", //文件关联业务id
            baseType: "", //文件种类
            createDate: "", //创建时间
            fileType: 4, //文件类型：1图片、2文字、3视频、4音频
            id: this.uploadForm.fileUrl, //id
            url: this.uploadForm.sucaiUrl,
          },
          //文件路径
        ], //文件

        //基础模型
        basemodel: "",
        //模型类型
        modeltype: "",
        //模型版本号
        modelversion: "",
        //关联我的群聊
        relevancegp: "",
      };
      // return;
      this.$http({
        url: this.$http.adornUrl("/app/material/add"),
        method: "post",
        data: this.$http.adornData(data),
      }).then((data) => {
        this.$emit("AudioCurNoDropFlgFun", true);
        // console.log(1223);
        if (data.data.code === 0) {
          // console.log(data,1232);
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.$router.push({ name: "home" });
          location.reload();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../component.scss";
</style>

<template>
  <div class="msg_com">
    <!-- 菜单 -->
    <ul class="nav">
      <li
        class="nav_item"
        :class="{ active: activeNav == nav.id }"
        v-for="nav in navList"
        :key="nav.id"
        @click="navItemClick(nav)"
      >
        {{ nav.name }}
      </li>
    </ul>
    <!-- 数据 -->
    <div class="data_box">
      <!-- 活动通知 -->
      <template v-if="activeNav == 1">
        <ul class="msg" v-if="systemMsgList.length">
          <li
            class="msg_item"
            v-for="(msg, index) in systemMsgList"
            :key="index"
          >
            <div class="title">{{ msg.title }}</div>
            <div class="content">{{ msg.content }}</div>
            <div class="handle">
              <div>查看详情</div>
              <i class="el-icon-arrow-right"></i>
            </div>
          </li>
        </ul>
        <template v-else>
          <div class="empty">
            <i class="el-icon-chat-dot-round"></i>
            <div>暂无消息</div>
          </div>
        </template>
      </template>

      <!-- 系统通知 -->
      <template v-if="activeNav == 2">
        <!-- 系统消息通知 -->
        <ul class="msg" v-if="newsystemInfoList.length">
          <li
            class="msg_item"
            v-for="(msg, index) in newsystemInfoList"
            :key="index"
          >
            <div class="title">{{ msg.title }}</div>
            <div class="content">{{ msg.content }}</div>
            <div class="handle">
              <div>查看详情</div>
              <i class="el-icon-arrow-right"></i>
            </div>
          </li>
        </ul>
      </template>

      <!-- 入群申请 -->
      <template v-if="activeNav == 3">
        <ul class="ask_box" v-if="applyforjoinList.length">
          <li
            class="ask_item"
            v-for="(item, index) in applyforjoinList"
            :key="index"
          >
            <div class="a_left">
              <div class="user">
                <div class="img">
                  <img :src="item.senderAvatar" alt="头像" />
                </div>
                <img
                  class="msg_img"
                  src="@/assets/images/Group 82@2x.png"
                  alt="消息"
                  v-if="!item.isRead"
                />
              </div>
              <div class="user_name_box">
                <div>{{ item.senderName }}</div>
                <div class="ask_msg">
                  <div class="ask_con" v-if="item.noticetype == 0">
                    入群申请： {{ item.receiverName }}
                  </div>
                  <div class="ask_con" v-if="item.noticetype == 1">
                    代表战队参加比赛申请： {{ item.receiverName }}
                  </div>
                  <div class="msg_time">{{ item.updateTime }}</div>
                </div>
              </div>
            </div>
            <div class="ask_handle">
              <div
                class="h_btn confirm"
                v-if="item.id && item.isRead == false"
                @click="refuseGroupApply(item)"
              >
                拒绝
              </div>
              <div
                class="h_btn cancel"
                v-if="item.isRead == true && item.isagree == 1"
              >
                已拒绝
              </div>
              <div
                class="h_btn confirm"
                v-if="item.id && item.isRead == false"
                @click="agreApply(item)"
              >
                同意
              </div>
              <div
                class="h_btn cancel"
                v-if="item.isRead == true && item.isagree == 0"
              >
                已同意
              </div>
            </div>
          </li>
        </ul>
        <template v-else>
          <div class="empty">
            <i class="el-icon-chat-dot-round"></i>
            <div>暂无申请</div>
          </div>
        </template>
      </template>

      <!-- 入群邀请 -->
      <template v-if="activeNav == 4">
        <ul class="ask_box" v-if="1">
          <li class="ask_item" v-for="(item, index) in 10" :key="index">
            <div class="a_left">
              <div class="user">
                <div class="img">
                  <img src="@/assets/images/avatai2.jpg" alt="头像" />
                </div>
                <img
                  class="msg_img"
                  src="@/assets/images/Group 82@2x.png"
                  alt="消息"
                />
              </div>
              <div class="user_name_box">
                <div>这是昵称</div>
                <div class="ask_msg">
                  <div class="ask_con">
                    入群邀请：萨达姆纳爱斯什么的拿手机大家哈顺利打开
                  </div>
                  <div class="msg_time">2023-06-18</div>
                </div>
              </div>
            </div>
            <div class="ask_handle">
              <div class="h_btn cancel">拒绝</div>
              <div class="h_btn confirm">同意</div>
            </div>
          </li>
        </ul>
        <template v-else>
          <div class="empty">
            <i class="el-icon-chat-dot-round"></i>
            <div>暂无邀请</div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import { sendSock } from "@/utils/ws.js";
export default {
  data() {
    return {
      // 选中的
      activeNav: 1,
      // 菜单
      navList: [
        { id: 1, name: "活动消息" },
        { id: 2, name: "系统通知" },
        { id: 3, name: "入群请求" },
        // { id: 4, name: "入群邀请" },
      ],
      systemMsgList: [], //系统活动消息
      systemInfoList: [], //系统通知消息
      newsystemInfoList: [], //最新通知消息
      applyforjoinList: [], //申请入群
      permissionyn: "", //是否有权限
      token: null,
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    if (this.token != null) {
      // this.getUserList();
    }
    this.getSystemData();
    this.getSystemInfoList();
    this.getApplyForJoinData();
  },
  methods: {
    // 拒绝
    refuseGroupApply(item) {
      console.log(item, "点击拒绝");
      // this.getCheckUidMes(item);
      // {"reject" 群聊申请拒绝}
      if (this.permissionyn == 0) {
        let sendArrOn = {
          type: "zlgroup-reject",
          data: {
            id: item.id,
            noticetype: item.noticetype,
          },
        };
        sendSock(sendArrOn);
        // this.$message({
        //   message: "已拒绝该用户加入群组",
        //   type: "success",
        // });
        this.getApplyForJoinData();
      } else {
        this.$message.error("对不起，您没有处理权限");
      }
    },
    //同意用户申请
    agreApply(item) {
      console.log(item, "点击同意");
      // return;
      // this.getCheckUidMes(item);
      if (this.permissionyn == 0) {
        let sendArrAgree = {
          type: "zlperson-apply-agree",
          data: {
            id: item.id,
            noticetype: item.noticetype,
          },
        };
        sendSock(sendArrAgree);
        this.$bus.$emit("refresh-UserList"); //刷新用户数据
        this.getApplyForJoinData();
        //同意
        this.$http({
          url: this.$http.adornUrl("/app/notice/agreeGpuid"),
          method: "post",
          data: this.$http.adornData({
            id: item.id,
            noticetype: item.noticetype,
          }),
        }).then((data) => {
          // this.permissionyn = data.data.permission;
          console.log("已经同意".data);
        });
        this.getApplyForJoinData();
        console.log("刷新数据");
      } else {
        this.$message.error("对不起，您没有处理权限");
      }
    },
    // 检查用户是否有处理权限
    // getCheckUidMes(item) {
    //   this.$http({
    //     url: this.$http.adornUrl("/app/notice/checkUidMes"),
    //     method: "post",
    //     data: this.$http.adornData({
    //       id: item.id,
    //       noticetype: item.noticetype,
    //     }),
    //   }).then((data) => {});
    // },
    // 获取活动通知
    getSystemData() {
      this.$http({
        url: this.$http.adornUrl("/app/notice/getEventNotices"),
        method: "get",
        params: this.$http.adornParams({
          mestype: 2,
        }),
      }).then((data) => {
        if (data.data.code === 0) {
          this.systemMsgList = data.data.noticeList;
          console.log("系统活动通知", this.systemMsgList);
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    //获取系统消息
    getSystemInfoList() {
      this.$http({
        url: this.$http.adornUrl("/app/user/systemInfoList"),
        method: "POST",
        data: this.$http.adornData({
          page: 1,
          uid: this.userInfo.uid,
        }),
      }).then((data) => {
        if (data.data.code === 0) {
          console.log(data);
          this.systemInfoList = data.data.result;
          this.newsystemInfoList = data.data.result.reverse();
          // console.log("系统消息通知", this.systemInfoList);
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    // 获取消息
    //  获取获取IM通知列表 { "group-apply" 群聊申请消息} {"person-apply"申请好友消息}  {"person-apply-agree" 群聊申请通过消息 }
    getApplyForJoinData() {
      this.$http({
        url: this.$http.adornUrl("/app/notice/listAll"),
        method: "get",
      }).then(({ data }) => {
        if (data.code == 0) {
          this.applyforjoinList = data.data.noticeList;
        } else {
          this.$message.error(data.msg);
        }
        console.log(data, "申请列表");
      });
    },

    // 单项点击
    navItemClick(nav) {
      this.activeNav = nav.id;
    },
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem("User"));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>

<template>
  <div class="attention_box">
    <template v-if="followList && followList.length">
      <div class="search">
        <el-input placeholder="搜索" v-model.trim="searchVal"></el-input>
      </div>
      <ul class="user_list">
        <li class="user_item" v-for="(item, index) in followList" :key="index">
          <div class="item_left">
            <div class="user_img">
              <img :src="item.avatar" alt="头像" />
            </div>
            <div class="user_info">
              <div class="user_name">{{ item.username }}</div>
              <!-- <div class="user_id">ID:121534</div> -->
            </div>
          </div>
          <div class="ltem_right">
            <div class="btn confirm" @click="unfollow(item)">取消关注</div>
          </div>
        </li>
      </ul>
    </template>
    <template v-else>
      <div class="empty_box">暂无关注</div>
    </template>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      searchVal: "",
      pageFollow: 1,
      followList: [],
    };
  },
  created() {
    this.getFollow();
  },
  methods: {
    // 获取关注
    getFollow() {
      this.$http({
        url: this.$http.adornUrl("/app/user/follow"),
        method: "get",
        params: this.$http.adornParams({
          page: this.pageFollow,
        }),
      }).then(({ data }) => {
        this.followList = data.result.data;
        console.log(data);
        console.log("我的关注", this.followList);
      });
    },
    // 取消关注
    unfollow(item) {
      this.$confirm(`您确定要取消关注 ${item.username} 吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl("/app/user/cancelFollow"),
            method: "post",
            data: this.$http.adornData({
              id: item.uid,
            }),
          }).then(({ data }) => {
            if (data.code == 0) {
              this.getFollow();
              this.getAllFriend();
              this.$message.success(data.msg);
            } else {
              this.$message.error(data.msg);
            }
            console.log(data);
          });
        })
        .catch(() => {});
    },
    ...mapActions(["getAllFriend"]),
  },
};
</script>


<style lang="scss" scoped>
@import "./index.scss";
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/index.scss'
import '@/assets/font/iconfont.css'
import httpRequest from "@/utils/httpRequest"; // api: https://github.com/axios/axios
import Bus from '@/utils/bus';
import VueQr from 'vue-qr'

import { isAuth } from "@/utils";
import VueLazyload from 'vue-lazyload'  // 引入

Vue.use(VueLazyload, {
  preLoad: 1.3,

  attempt: 1
})  // 不带参数注册指令
import VDistpicker from 'v-distpicker'
Vue.component('v-distpicker', VDistpicker)
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.prototype.globalData = {
  currentUser: null,
};
Vue.use(ElementUI);
Vue.prototype.$http = httpRequest; // ajax请求方法
Vue.prototype.$bus = Bus; // bus
Vue.prototype.isAuth = isAuth; // 权限方法
Vue.config.productionTip = false

// 挂载全局
window.SITE_CONFIG = {};
window.SITE_CONFIG = {};
window.SITE_CONFIG["baseUrl"] = "https://api.twyia.com";//线上 https://api.twyia.com/doc.html
// window.SITE_CONFIG["baseUrl"] = "http://192.168.10.26:8080"//本地
// cdn地址 = 域名 + 版本号
window.SITE_CONFIG["domain"] = "./"; // 域名
window.SITE_CONFIG["version"] = ""; // 版本号(年月日时分)
window.SITE_CONFIG["cdnUrl"] =
  window.SITE_CONFIG.domain + window.SITE_CONFIG.version;
new Vue({
  router,
  store,
  components: { VueQr },
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '@/views/home'

Vue.use(VueRouter)
// 重写vuerouter的push和replace方法，处理编程式路由多次点击报错问题
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject);
  } else {
    originPush.call(
      this,
      location,
      () => { },
      () => { }
    );
  }
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      showHeader: true,
      keepAlive: true,
    },

  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import("@/views/chat"),
    meta: {
      showHeader: true,
      keepAlive: false,
    },
  },
  {
    path: '/logins',
    name: 'logins', component: () => import("@/views/logins"),
    meta: {
      showHeader: true,
      keepAlive: false,
    },
  },
  {
    path: '/usercenter/:type',
    name: 'usercenter',
    component: () => import("@/views/usercenter"),
    meta: {
      showHeader: false,
      keepAlive: true,
    },
  },
  //返图
  {
    path: '/backgraph/:id',
    name: 'backgraph',
    component: () => import("@/views/backgraph/index.vue"),
    meta: {
      showHeader: true,
      // keepAlive: true,
    },
  },
  //大赛首页
  {
    path: '/gamehome',
    name: 'gamehome',
    component: () => import("@/views/gamehome/gamehome.vue"),
    redirect: '/gamehome/gamedetail',
    meta: {
      showHeader: false,
      authRequired: true,
      keepAlive: false,
    },
    children: [
      { path: 'gamedetail', name: 'gamedetail', component: () => import("@/views/gamehome/components/gamedetail.vue") },
      { path: 'task', name: 'task', component: () => import("@/views/gamehome/components/task.vue") },
      { path: 'rank', name: 'rank', component: () => import("@/views/gamehome/components/rank.vue") },
      { path: 'all', name: 'all', component: () => import("@/views/gamehome/components/all.vue") },
    ]
  },
  // 专业出图
  {
    path: '/major',
    name: 'major',
    component: () => import("@/views/major/index.vue"),
    meta: {
      showHeader: true,
      // keepAlive: true,
    },
  },
  {
    path: '*',
    name: 'notfind',
    component: () => import("@/views/notfind"),
    meta: {
      showHeader: false,
      keepAlive: false,
    },
  },
  // apk下载
  {
    path: '/Download',
    name: 'Download',
    component: () => import("@/views/Download/DownloadApk.vue"),
    meta: {
      showHeader: false,
      keepAlive: false,
    },
  },
  // 训练模型
  {
    path: '/drill',
    name: 'drill',
    component: () => import("@/views/drill/index.vue"),
    meta: {
      showHeader: true,
      keepAlive: true,
    },
  },
  // 在线生图
  {
    path: '/onlinemap',
    name: 'onlinemap',
    component: () => import("@/views/onlinemap"),
    meta: {
      showHeader: true,
      keepAlive: false,
    },
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

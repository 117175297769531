<template>
  <div style="position: absolute">
    <el-dialog
      title="问题反馈"
      :visible.sync="showFeedBasck"
      width="25%"
      :before-close="handleClose"
      append-to-body
      custom-class="feed_back"
    >
      <div class="box">
        <el-form
          :rules="dataRule"
          ref="dataForm"
          :model="dataForm"
          label-width="80px"
        >
          <el-form-item prop="title">
            <div
              class="radios"
              v-for="(item, index) in contentList"
              :key="index"
            >
              <el-radio
                @change="radioFun(item, item.value)"
                v-model="radio"
                :label="item.value"
                >{{ item.titleContText }}</el-radio
              >
            </div>
          </el-form-item>
          <el-form-item class="inp" prop="context">
            <el-input
              type="textarea"
              :rows="6"
              placeholder="描述你遇到的问题"
              v-model="dataForm.context"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn">
          <el-button type="primary" :disabled="disabledFlg" @click="subMitFun()"
            >确 定</el-button
          >
        </div>
      </span>
    </el-dialog>
    <!-- 登录弹出框 -->
    <loginProUp
      :showLogin="showLogin"
      @closeLogin="loginPopChange"
    ></loginProUp>
  </div>
</template>

<script>
import loginProUp from "@/components/Login/index.vue";
export default {
  components: {
    loginProUp,
  },
  props: {
    showFeedBasck: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disabledFlg: false,
      showLogin: false,
      radio: 0,
      textarea: "",
      contentList: [
        {
          value: 1,
          titleContText: "使用中发生了崩溃",
        },
        {
          value: 2,
          titleContText: "模型、图片、prompt数据丢失了",
        },
        {
          value: 3,
          titleContText: "操作按钮反馈不及时、体验差",
        },
        {
          value: 4,
          titleContText: "其他",
        },
      ],
      dataForm: {
        title: "", //反馈及建议标题
        context: "", // 用户反馈及建议内容
      },
      dataRule: {
        title: [{ required: true, message: "请选择反馈事项", trigger: "blur" }],
        context: [{ required: true, message: "内容不能为空", trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 登录弹窗操作
    loginPopChange(i) {
      this.showLogin = i;
    },
    handleClose() {
      this.dataForm.title = "";
      this.radio = 0;
      this.$refs["dataForm"].resetFields();
      this.$emit("feedBackFun", false);
      this.disabledFlg = false;
    },
    radioFun(e, e2) {
      this.dataForm.title = e.titleContText;
      this.radio = e.value;
      console.log(1, e, e2);
    },
    // /app/user/feedback 用户反馈表
    subMitFun() {
      let data = {
        title: this.dataForm.title,
        context: this.dataForm.context,
      };
      let token = localStorage.getItem("token");
      if (token) {
        this.$refs["dataForm"].validate((valid) => {
          if (valid) {
            this.disabledFlg = true;
            this.$http({
              url: this.$http.adornUrl("/app/user/feedback"),
              Headers: localStorage.getItem("token"),
              method: "post",
              data: this.$http.adornData(data),
            }).then((data) => {
              console.log(data, 22);
              if (data && data.data.code === 200) {
                this.$message({
                  message: `${data.data.message}`,
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.$refs["dataForm"].resetFields();
                    this.handleClose();
                  },
                });
              } else {
                this.$message.error(data.data.message);
                this.disabledFlg = false;
              }
            });
          }
        });
      } else {
        // 未登录无token弹出登录
        this.loginPopChange(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>

import Vue from "vue";
import axios from "axios";
import router from "@/router";
import qs from "qs";
import merge from "lodash/merge";
import ElementUI from "element-ui";
import { Message } from "element-ui";
Vue.use(ElementUI);

import { clearLoginInfo } from "@/utils";

const http = axios.create({
  timeout: 1000 * 120,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});
axios.defaults.withCredentials = true;
// axios.defaults.baseURL = process.env.VUE_APP_API_URL
// axios.interceptors.request.use(function (config) {
//   config.withCredentials = true; //携带cookie
//   config.headers.common['SessionID'] = window.localStorage.getItem('SessionID') || ''; //携带SessionID
//   return config;
// }, function (error) {
//   return Promise.reject(error);
// });
// axios.post('/login',)
//   .then(function (response) {
//     window.localStorage.setItem('SessionID', response.headers.sessionid); //将返回的SessionID存储到本地
//   })
//   .catch(function (error) {
//     console.log(error);
//   });
/**
 * 请求拦截
 */
http.interceptors.request.use(
  (config) => {
    config.headers["token"] = localStorage.getItem("token"); // 请求头带上token
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 响应拦截
 */
http.interceptors.response.use(
  (response) => {
    if (response.data && response.data.code === 401) {
      // 401, token失效
      //  console.log(response.data.msg,333);
      Message.error(response.data.msg);

      localStorage.removeItem("User");
      clearLoginInfo();
      router.push({ name: "home" }); //material
    }
    // if (localStorage.getItem('User') == null || localStorage.getItem('token') == null) {
    //   clearLoginInfo()
    //   router.push({ name: 'home' }) //material
    // }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  // console.log(window.SITE_CONFIG.baseUrl + actionName);
  return (
    (process.env.NODE_ENV !== "production" && process.env.OPEN_PROXY
      ? "/api/"
      : window.SITE_CONFIG.baseUrl) + actionName
  );
  // return (window.SITE_CONFIG.baseUrl) + actionName
};
/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
  var defaults = {
    t: new Date().getTime(),
  };
  return openDefultParams ? merge(defaults, params) : params;
};

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = "json") => {
  var defaults = {
    t: new Date().getTime(),
  };
  data = openDefultdata ? merge(defaults, data) : data;
  return contentType === "json" ? JSON.stringify(data) : qs.stringify(data);
};

export default http;

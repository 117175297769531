<template>
  <div>
    <el-dialog
      title="我的素材"
      :visible.sync="dialogVisible"
      width="70%"
      append-to-body
      class="my-dialog"
    >
      <span>
        <!-- 图片库 -->
        <!-- 题材标签 -->
        <div class="nav_three">
          <el-tag
            class="el-tag-theme"
            v-for="(item, index) in tagList"
            :key="index"
            @click="ClicktagList(item, index)"
          >
            <p>
              {{ item.tagName }}
            </p>
            <span :class="{ active: isIndex == index }"> </span>
          </el-tag>
        </div>
        <div class="picture">
          <ul class="pic_list" ref="picList" @scroll="handleScroll($event)">
            <li
              class="list_item"
              v-for="(item, index) in ImgList"
              :key="index"
              :style="{
                backgroundImage: 'url(' + item.sysOssEntities[0].url + ')',
              }"
            >
              <div class="item_top">
                <div class="item_type free">
                  {{ item.isfree == 0 ? "免费" : "付费" }}
                </div>
                <el-checkbox v-model="item.checked"></el-checkbox>
              </div>
              <div class="item_user">
                <div class="user_img">
                  <img :src="item.appUserEntity.avatar" alt="头像" />
                </div>
                <div class="user_name">{{ item.author }}</div>
              </div>
            </li>
          </ul>
        </div>
      </span>
      <span slot="footer" class="dialog-footer handle_box">
        <div class="hand_btn cancel" @click="dialogVisible = false">取消</div>
        <div class="hand_btn confirm" @click="Saveimage()">确定</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    relevanceImgsList: "",
  },
  data() {
    return {
      radio: 0,
      checked: false,
      dialogVisible: false,
      tagList: [], //题材标签集合
      userInfo: {},
      page: 1,
      totals: "",
      tagListObj: {
        cateid: 1,
        id: null,
        isEnable: 0,
        tagName: "全部",
      },
      isIndex: -1,
      ImgList: [],
      ImgListUrl: "",
      opacity: "",
    };
  },
  mounted() {
    this.getTagPost(); //题材标签
    this.userInfo = JSON.parse(localStorage.getItem("User"));
    this.ClicktagList(this.tagListObj, 0);
    this.tagMsg();
    console.log(this.relevanceImgsList, 367);
  },

  computed: {
    visualViewport() {
      // console.log("可视区域", window.visualViewport);
      return window.visualViewport;
    },
  },
  watch: {},
  methods: {
    Scoal() {
      // console.log(1223);
      this.page++;
      if (this.totals == 0) {
        this.totals = 1;
      }
      if (this.totals > this.page) {
        this.tagMsg();
      }
    },

    Saveimage() {
      let arr = [];
      this.ImgList.forEach((item) => {
        console.log(item);
        if (item.checked) {
          arr.push(item.sysOssEntities[0].url);
        }
      });
      if (arr.length > 5) {
        this.$message.error("最多选中五张图片");
        return;
      }
      this.$emit("ShowImgListUrl", arr);
      this.dialogVisible = false;
    },
    // isMaterial 0：我的
    init(inNum) {
      this.dialogVisible = true;
      //   console.log(this.$refs.picList, 11);
      console.log(inNum);
    },

    // 滚动条刷新
    handleScroll(e) {
      let el = e.target;
      if (
        el.scrollTop + el.clientHeight >= el.scrollHeight &&
        this.page !== this.totals
      ) {
        //控制页数
        this.page++;
        //调用后台接口
        this.tagMsg(this.tagsId);
      }
    },
    //点击题材标签
    ClicktagList(e, index) {
      this.page = 1;
      this.ImgList = [];
      this.isIndex = index;
      this.tagsId = e.id;
      this.tagMsg(e.id);

      //   console.log(e, e.id, 555);
    },
    // /app/post/tagPost 题材标签集合
    getTagPost() {
      this.$http({
        url: this.$http.adornUrl("/app/post/tagPost"),
        method: "get",
        params: this.$http.adornParams({
          // 题材分类id
          cateid: 1,
        }),
      }).then((data) => {
        if (data.data.code === 0) {
          data.data.tagList.unshift(this.tagListObj);
          this.tagList = data.data.tagList;
          // console.log("data---", this.tagList);
        }
      });
    },
    // 素材列表分页
    tagMsg(tags) {
      let data = {
        page: this.page,
        uid: this.userInfo.uid,
        isMaterial: 0, //素材库判断：0.我的 1.素材库 2.收藏
        scistate: "", // 收藏状态：0.未收藏 1.已收藏
        isopen: null, //权限：0.公开 1.私有
        cateid: 1, //素材类型：1图片、2文字、3视频、4音频
        tags: tags, //题材分类
        type: 1, //素材类型：1图片、2文字、3视频、4音频、5模型
        isforcepe: 1,
      };
      this.$http({
        url: this.$http.adornUrl("/app/material/list"),
        method: "post",
        data: this.$http.adornData(data),
      }).then((data) => {
        if (data.data.result) {
          this.totals = data.data.result.total;
          data.data.result.data.forEach((item) => {
            // item.checked = false;
            this.relevanceImgsList.forEach((son) => {
              if (item.sysOssEntities[0].url == son) {
                item.checked = true;
              } else {
                if (!item.checked) {
                  item.checked = false;
                }
              }
            });
          });

          if (this.page == 1) {
            this.ImgList = data.data.result.data;
            this.totals = data.data.result.total; // console.log(123);
          } else {
            data.data.result.data.forEach((element) => {
              this.ImgList.push(element);
            });
          }
          console.log(this.ImgList, 3334);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./dialog-index";
</style>

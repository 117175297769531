<template>
  <div class="footer" :class="{ hide_footer: !showFooter }">
    <div class="left">
      <div class="agreement">
        © FEEOTEC 2023
        <span @click="dealItemClick(1)">用户服务协议</span>
        <span @click="dealItemClick(2)">隐私政策</span>
      </div>
    </div>
    <div class="center">
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502006679"
      >
        <img style="width: auto" src="@/assets/images/beianImg.png" alt="" />
        <p class="p">鄂公网安备 42018502006679号</p>
      </a>
      <!-- <img style="width: auto" src="@/assets/images/beianImg.png" alt="" />
      <p class="p">鄂公网安备 42018502006679号</p> -->
      <a
        target="_blank"
        href=" https://beian.miit.gov.cn/#/Integrated/recordQuery "
      >
        <span>鄂ICP备2023008233号</span>
      </a>
    </div>
    <Dialog
      :showDialog="dealDialogShow"
      @closeDialog="closeDealDialog"
      :title="dealDialogTitle"
      :countent="dealDialogCountent"
      :modal="true"
      ref=""
    />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";

export default {
  props: {
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dealDialogTitle: "", //协议弹窗标题
      dealDialogShow: false, //协议弹窗展示
      dealDialogCountent: "", //协议内容
      privacyText: "", //个人隐私政策
      protocolText: "", //用户端——查询用户服务协议
    };
  },
  created() {
    // this.getPrivacy(); //个人隐私政策
    // this.getProtocol(); //用户端——查询用户服务协议
  },
  methods: {
    // 隐私/用户服务点击
    dealItemClick(i) {
      // i-> 1用户 2 隐私
      if (i == 1) {
        this.dealDialogTitle = "用户服务协议";
      } else if (i == 2) {
        this.dealDialogTitle = "隐私政策";
      }
      this.dealDialogShow = true;
      this.getPrivacyProtocol(i);
      // console.log(i, "----------");
    },
    closeDealDialog(i) {
      this.dealDialogShow = i;
    },
    // 个人隐私政策'/app/system/privacy'---用户端——查询用户服务协议'/app/system/protocol'
    getPrivacyProtocol(i) {
      // console.log(i, "*******");
      let url =
        i === 1 ? "/app/system/protocol" : i === 2 ? "/app/system/privacy" : "";
      this.$http({
        url: this.$http.adornUrl(url),
        method: "get",
        params: this.$http.adornParams({}),
      }).then((data) => {
        this.dealDialogCountent = data.data.result;
        // console.log(
        //   `${i == 1 ? "用户服务协议" : i == 2 ? "个人隐私政策" : ""}`,
        //   this.dealDialogCountent
        // );
      });
    },
  },
  components: {
    Dialog,
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 99;
  background: rgba(0, 0, 0, 0.7);
  transition: all 0.4s;
  &.hide_footer {
    bottom: -100px;
  }
  .left {
    .agreement {
      width: 30%;
      color: #939393;
      span {
        margin: 0 10px;
        cursor: pointer;
        color: #5da3b1;
        &:hover {
          color: #6da6b3;
        }
      }
    }
  }
  .center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;

    a {
      display: flex;
      img {
        width: auto;
      }
      .p {
        float: left;
        // height: 20px;
        line-height: 20px;
        margin: 0px 10px 0px 0px;
        color: #939393;
      }
    }
    span {
      color: #939393;
      // height: 20px;
      line-height: 20px;
    }
  }
}
</style>

<template>
  <div class="frind">
    <template v-if="friendList && friendList.length">
      <div class="search">
        <el-input
          placeholder="搜索15位朋友"
          v-model.trim="searchVal"
        ></el-input>
      </div>
      <ul class="user_list">
        <li class="user_item" v-for="(item, index) in friendList" :key="index">
          <div class="item_left">
            <div class="user_img">
              <img :src="item.avatar" alt="头像" />
            </div>
            <div class="user_info">
              <div class="user_name">{{ item.friend_name }}</div>
              <!-- <div class="user_id">ID:121534</div> -->
            </div>
          </div>
          <div class="ltem_right">
            <div
              class="btn confirm"
              v-if="item.friend_id != 6"
              @click="unfollow(item)"
            >
              取消关注
            </div>
            <div class="btn cancel" @click="senPerson(item)">发送私信</div>
          </div>
        </li>
      </ul>
    </template>
    <template v-else> <div class="empty_box">暂无朋友</div></template>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      searchVal: "",
      friendList: [],
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 发送私信
    senPerson(item) {
      console.log(item);
      this.$emit("changeActive", "1");
      this.setPersonFriend(item.friend_id);
    },

    // 朋友栏 获取好友列表
    getDataList() {
      this.$http({
        url: this.$http.adornUrl("/app/friend/list"),
        method: "get",
        params: this.$http.adornParams({}),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.friendList = data.data;
          console.log("好友列表", this.friendList);
        } else {
          this.$message.error(data.msg);
        }
      });
    },

    // 取消关注
    unfollow(item) {
      console.log(item, 888);
      this.$confirm(`您确定要取消关注 ${item.friend_name} 吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl("/app/user/cancelFollow"),
            method: "post",
            data: this.$http.adornData({
              id: item.friend_id,
            }),
          }).then(({ data }) => {
            if (data.code == 0) {
              this.getDataList();
              this.getAllFriend();
              this.$message.success(data.msg);
            } else {
              this.$message.error(data.msg);
            }
            console.log(data);
          });
        })
        .catch(() => {});
    },
    ...mapActions(["setPersonFriend", "getAllFriend"]),
  },
};
</script>


<style lang="scss" scoped>
@import "./index.scss";
</style>
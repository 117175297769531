<template>
  <!-- 我的素材  聊天发送素材展示 -->
  <el-dialog
    title="我的素材"
    :visible.sync="mineMaterialShow"
    width="70%"
    :before-close="handleClose"
    custom-class="min_mater"
    append-to-body
  >
    <div class="material_body" ref="comDataBox">
      <!-- 二级菜单 -->
      <div class="nav_box">
        <div class="nav_second">
          <el-tag
            class="el-tag"
            :class="item.id == cateListTypeId ? 'active' : ''"
            v-for="(item, index) in cateListType"
            :key="index"
            @click="clickCateListType(item)"
          >
            <span>
              {{ item.cateName }}
            </span>
          </el-tag>
        </div>
        <!-- 三级菜单 -->
        <div class="nav_three">
          <el-tag
            class="el-tag-theme"
            v-for="(item, index) in tagList"
            :key="index"
            @click="ClicktagList(item)"
          >
            <p>
              {{ item.tagName }}
            </p>
            <span :class="{ active: tagListItem.id == item.id }"> </span>
          </el-tag>
        </div>
      </div>
      <div class="content_box">
        <!-- 图片库 -->
        <template v-if="cateListTypeId == 1">
          <div class="picture">
            <ul class="pic_list" v-if="tagMsg.length">
              <li
                class="list_item"
                v-for="(item, index) in tagMsg"
                :key="index"
                :style="{
                  backgroundImage: 'url(' + item.sysOssEntities[0].url + ')',
                }"
              >
                <div class="item_top">
                  <div class="item_type free">
                    {{ item.isfree == 0 ? "免费" : "付费" }}
                    <!-- 免费 -->
                  </div>
                  <div class="handle_box">
                    <input
                      type="radio"
                      name="radio"
                      :value="item"
                      v-model="checkItem"
                    />
                  </div>
                </div>
                <div class="item_user">
                  <div class="user_img">
                    <img :src="item.appUserEntity.avatar" alt="头像" />
                  </div>
                  <div class="user_name">{{ item.author }}</div>
                </div>
              </li>
            </ul>
            <div v-else class="empty">暂无</div>
          </div>
        </template>
        <!-- 视频库 -->
        <template v-if="cateListTypeId == 2"><Empty /> </template>
        <!-- 音频库 -->
        <template v-if="cateListTypeId == 3"><Empty /> </template>
        <!-- 文字库 -->
        <template v-if="cateListTypeId == 4"><Empty /> </template>
        <!-- 模型库 -->
        <template v-if="cateListTypeId == 5">
          <div class="model">
            <ul class="model_list" v-if="tagMsg.length">
              <li
                class="list_item"
                v-for="(item, index) in tagMsg"
                :key="index"
                :style="{
                  backgroundImage: 'url(' + item.sysOssEntities[0].url + ')',
                }"
              >
                <div class="item_top">
                  <div class="item_type free">
                    {{ item.isfree == 0 ? "免费" : "付费" }}
                    <!-- 免费 -->
                  </div>
                  <div class="handle_box">
                    <input
                      type="radio"
                      name="radio"
                      :value="item"
                      v-model="checkItem"
                    />
                  </div>
                </div>
                <div class="info">
                  <div class="item_name">{{ item.title }}</div>
                  <div class="item_user">
                    <div class="user_img">
                      <img :src="item.appUserEntity.avatar" alt="头像" />
                    </div>
                    <div class="user_name">{{ item.author }}</div>
                  </div>
                </div>
              </li>
            </ul>
            <div v-else class="empty">
              <Empty />
            </div>
          </div>
        </template>
      </div>
      <div class="handle_box_bottom">
        <div class="hand_btn cancel" @click="handleClose">取消</div>
        <div class="hand_btn confirm" @click="shareSend">发送</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getTagPost, getTagMsg, shwocateListType } from "@/utils/index";
import Empty from "@/components/Empty";
export default {
  props: {
    // 展示
    mineMaterialShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 二级菜单
      cateListType: [],
      //当前二级菜单Id
      cateListTypeId: 1,
      // 三级菜单
      tagList: [],
      // 选中三级
      tagListItem: {},
      tagMsg: [], //数据
      page: 1,
      dataTotal: 0,

      checkItem: {}, //input选中的
    };
  },
  created() {
    // this.shwocateListType();
    this.getCateList();
    // this.getTagList();
    this.getData();
  },
  mounted() {
    this.$nextTick(() => {
      let comDataBox = this.$refs.comDataBox;
      comDataBox.addEventListener("scroll", this.scrollTObottom);
    });
  },
  methods: {
    // 发送点击
    shareSend() {
      this.$emit("confirmSend", this.checkItem);
      this.handleClose();
      // console.log(this.checkItem);
    },
    // 获取二级
    async getCateList() {
      const res = await shwocateListType();
      if (res.code == 0) {
        this.cateListType = res.cateList;
        this.getTagList(this.cateListType[0].id);
      } else {
        this.$message.error(res.msg);
      }
    },
    // 二级点击
    clickCateListType(item) {
      console.log(item);
      this.cateListTypeId = item.id;
      this.page = 1;
      this.getData();
    },
    // 获取三级
    async getTagList() {
      const res = await getTagPost(1);
      console.log(res, 666);
      if (res.code == 0) {
        this.tagList = res.tagList;
        this.tagList.unshift({
          cateid: 1,
          id: null,
          isEnable: 0,
          tagName: "全部",
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    // 三级点击
    ClicktagList(item) {
      this.tagListItem = item;
      console.log(item);
      this.page = 1;
      this.getData();
    },
    // 获取数据
    async getData() {
      const res = await getTagMsg(
        this.page,
        this.userInfo.uid,
        this.tagListItem.id,
        this.cateListTypeId,
        this.cateListTypeId
      );
      console.log(res, 147);
      if (res.code == 0) {
        this.tagMsg = res.result.data;

        this.dataTotal = res.result.total;
      }
    },
    // 滑动触底
    scrollTObottom() {
      const comDataBox = this.$refs.comDataBox;
      if (
        comDataBox.scrollTop + comDataBox.clientHeight >=
        comDataBox.scrollHeight - 100
      ) {
        if (this.page < this.dataTotal) {
          this.page++;
          this.getData();
        }
      }
    },
    // 关闭
    handleClose() {
      this.$emit("closeMaterial", false);
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  components: {
    Empty,
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
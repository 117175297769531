<template>
  <el-form
    :model="uploadForm"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    class="demo-ruleForm"
    label-position="top"
  >
    <el-form-item label="上传素材" prop="fileUrl">
      <div>友情提示: 文件上传期间请勿切换页面，否则上传可能会中断</div>
      <form method="post" enctype="multipart/form-data">
        <input name="key" type="hidden" value="" />
        <input name="token" type="hidden" value="" />
        <input name="accept" type="hidden" />
        <input
          id="input-file"
          class="upload"
          type="file"
          value=""
          @change="getFile($event)"
        />
        <br />
        <el-progress :percentage="msg"></el-progress>

        <br />
        <BR />
        <div class="upFen">
          <input type="button" @click="submitForm($event)" value="上传" />
        </div>
      </form>
      <!-- <el-upload
        class="upload-demo"
        drag
        multiple
        v-model="uploadForm.fileUrl"
        name="Image"
        :action="url"
        :before-upload="beforeUpload"
        :on-success="handleBgImageSuccess"
        :limit="1"
        :on-preview="handlePreview"
        ref="mYupload"
      >
        <div><i class="el-icon-plus"></i>添加素材</div>

        <el-dialog
          append-to-body
          title="预览"
          :visible.sync="dialogVisible"
          width="50%"
          custom-class="dialogPreviewImg"
        >
          <span>
            <p>
              <img class="imsg" :src="dialogUrl" alt="" />
            </p>
          </span>
        </el-dialog>
      </el-upload> -->
    </el-form-item>
    <el-form-item label="模型名称" prop="title">
      <el-input
        v-model="uploadForm.title"
        placeholder="模型名称"
        clearable
      ></el-input>
    </el-form-item>

    <el-form-item label="基础模型" prop="basemodel">
      <!-- <el-cascader :options="accreditTypeList" clearable></el-cascader> -->
      <el-select
        v-model="uploadForm.basemodel"
        placeholder="请选择"
        :popper-append-to-body="false"
      >
        <el-option
          v-for="item in basicModelArr"
          :key="item.value"
          :label="item.label"
          :value="item"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="模型类型" prop="modeltype">
      <!-- <el-cascader :options="accreditTypeList" clearable></el-cascader> -->
      <el-select
        v-model="uploadForm.modeltype"
        placeholder="请选择"
        :popper-append-to-body="false"
      >
        <el-option
          v-for="item in TypeModelArr"
          :key="item.value"
          :label="item.label"
          :value="item"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="请输入版本号 " prop="modelversion">
      <el-input
        v-model="uploadForm.modelversion"
        placeholder="请输入版本号"
        clearable
      ></el-input>
    </el-form-item>

    <el-form-item label="请选择题材分类" prop="tags">
      <el-checkbox-group
        v-model="uploadForm.tags"
        size="medium"
        :max="3"
        fill="#e35d44"
      >
        <el-checkbox-button
          v-for="(item, index) in tagList"
          :key="index"
          :label="item"
          >{{ item.tagName }}</el-checkbox-button
        >
      </el-checkbox-group>
    </el-form-item>
    <el-form-item label="谁可以看" prop="permission">
      <el-radio
        fill="#e35d44"
        v-for="(item, index) in astrictList"
        :key="index"
        v-model="uploadForm.permission"
        :label="item.value"
        >{{ item.name }}</el-radio
      >
    </el-form-item>
    <el-form-item label="授权信息" prop="accreditType">
      <el-select
        v-model="uploadForm.accreditType"
        placeholder="请选择"
        :popper-append-to-body="false"
      >
        <el-option
          v-for="item in accreditArr"
          :key="item.value"
          :label="item.label"
          :value="item"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="内容简介" prop="content">
      <el-input
        type="textarea"
        v-model="uploadForm.content"
        placeholder="请填写简介"
      ></el-input>
    </el-form-item>

    <!-- <el-form-item class="use_price" label="使用价格" prop="cut">
      <el-radio fill="#e35d44" v-for="(item, index) in CutList" :key="index" v-model="uploadForm.cut"
        style="color: #ffffff" :label="item.cutValue">{{ item.cutName }}</el-radio>
      <el-input v-model="uploadForm.cut" placeholder="堆币" clearable size="small" class="price_inp"></el-input>
    </el-form-item> -->
    <!--  @change="handleChange" -->
    <!-- 关联群聊 -->
    <el-form-item label="关联我的群聊" class="relevance-group">
      <el-collapse class="my-collapse">
        <el-collapse-item title="请选择群聊" name="1">
          <div class="collapse-content">
            <el-radio-group v-model="relevancegp">
              <div
                class="text"
                v-for="(item, index) in relevanceGroup"
                :key="index"
              >
                <div class="imgs">
                  <img class="imgs" :src="item.media" alt="" />
                  <span> {{ item.groupname }}</span>
                </div>

                <div class="radio">
                  <el-radio :key="item.id" :label="item.id">
                    {{ item.value }}
                  </el-radio>
                </div>
              </div>
            </el-radio-group>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-form-item>
    <!-- 关联样图 -->
    <div @click="dialogSampFun()">
      <el-form-item
        label="关联样图"
        prop="accreditType"
        class="relevance-group master-drawing"
      >
        <!-- <i class="el-icon-arrow-down"></i> -->
        <!-- 已关联  -->
        <div class="relevance-box">
          <p>已关联（{{ nums }}/5）</p>
          <ul>
            <li
              class="relevance-li"
              v-for="(item, index) in relevanceImgsList"
              :key="index"
            >
              <img :src="item" alt="" />
            </li>
          </ul>
        </div>
      </el-form-item>
    </div>
    <dialog-sample
      ref="dialogSample"
      v-show="dialogSampleFlg == true"
    ></dialog-sample>
  </el-form>
</template>
<script>
import * as qiniu from "qiniu-js";
import { textA } from "@/utils/index";
import dialogSample from "./dialog.vue";
export default {
  props: {
    ModelTye: "",
  },
  components: {
    dialogSample,
  },
  data() {
    return {
      // 模型上传数据
      compareChunks: "",
      putExtra: {},
      config: {
        region: qiniu.region.z0,
      },
      f: { width: "0%" },
      msg: 0,
      token: "上传token",
      //
      dialogVisible: false,
      relevancegp: "", //关联我的群聊：【群id】
      dialogSampleFlg: false, //关联样图
      groupID: "", //群组id
      accreditArr: "",
      basicModelArr: "",
      TypeModelArr: "",
      url: "",
      uploadForm: {
        fileUrl: "", //素材文件
        title: "", //标题
        tags: [], //题材标签
        strTags: "", //题材标签字符串
        permission: "", //权限
        accreditType: null, //授权信息
        content: "", //内容简介
        cut: "", //0 普通贴 1 付费贴 2 堆币贴 使用价格
        price: "", //堆币数数量
        basemodel: "", // 基础模型
        modeltype: "", //模型类型：
        modelversion: "", //模型版本号：
        sucaiUrl: "", //模型地址不能为空
      },
      rules: {
        // 文件类型
        fileUrl: [
          {
            required: true,
            message: "文件类型不能为空",
            trigger: ["blur", "change"],
          },
        ],
        // 标题
        title: [
          {
            required: true,
            message: "标题不能为空",
            trigger: "blur",
          },
        ],
        // // 题材标签
        tags: [
          {
            type: "array",
            required: true,
            message: "最少选择一个，最多选择三个",
            trigger: "change",
          },
        ],
        // 权限
        permission: [
          { required: true, message: "请选择权限", trigger: "change" },
        ],
        // 授权信息
        accreditType: [
          { required: true, message: "请选择一个授权信息", trigger: "change" },
        ],
        // 内容简介
        content: [
          { required: true, message: "内容简介不能为空", trigger: "blur" },
        ],
        cut: [
          {
            required: true,
            message: "请输入堆币数",
            trigger: ["blur", "change"],
          },
        ],
        // 基础模型
        basemodel: [
          {
            required: true,
            message: "请选择基础模型",
            trigger: ["blur", "change"],
          },
        ],
        // modeltype 模型类型：
        modeltype: [
          {
            required: true,
            message: "请选择模型类型",
            trigger: ["blur", "change"],
          },
        ],
        // 模型版本号
        modelversion: [
          {
            required: true,
            message: "模型版本号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        // 关联我的群聊：【群id】
        relevancegp: [
          {
            required: true,
            message: "请选择关联群聊",
            trigger: ["blur", "change"],
          },
        ],
      },

      //   题材分类数据

      tagList: [
        "AI",
        "人物",
        "动物",
        "人物",
        "动物",
        "人物",
        "动物",
        "人物",
        "动物",
      ],
      //   谁可以看数据
      astrictList: [
        {
          name: "公开",
          value: 0,
        },
        {
          name: "私有",
          value: 1,
        },
      ],
      // 使用价格
      CutList: [
        {
          cutName: "免费",
          cutValue: 0,
        },
        {
          cutName: "金额",
          cutValue: 1,
        },
        {
          cutName: "堆币",
          cutValue: 2,
        },
      ],
      //   授权数据
      accreditTypeList: [{}],
      // 关联群组
      relevanceGroup: [],
      // 已关联图片
      relevanceImgsList: [],
      //预览图片地址
      dialogUrl: "",
    };
  },
  mounted() {
    this.foundationModel();
    this.TypeModel();
    this.ShowaccreditTypeList();
    this.ShowtagList();
    this.ShowRelevanceGroup();
    this.url = this.$http.adornUrl("/app/common/upload");
    this.$bus.$on("ImgListUrl", (data) => {
      this.relevanceImgsList = data;
      // console.log("接收",this.relevanceImgsList);
    });
  },
  methods: {
    // 上传模型
    getFile(event) {
      this.file = event.target.files[0];
      // console.log(event, this.file.name, 123);
    },
    submitForm(event) {
      // console.log(event, this.file.name, 456);

      if (this.file == undefined) {
        return;
      } else if (
        this.file.name.substr(this.file.name.length - 11) != "safetensors" &&
        this.file.name.substr(this.file.name.length - 4) != "ckpt" // safetensors
      ) {
        this.$message.error("文件格式不正确");
        return;
      }

      // console.log(event.preventDefault);

      event.preventDefault();
      let subObject = {
        next: this.next,
        error: this.error,
        complete: this.complete,
      };
      let options = {
        quality: 0.95,
        noCompressIfLarger: false,
      };
      let that = this;
      that
        .$http({
          url: that.$http.adornUrl("/app/bigFile1/get/token"),
          method: "get",
        })
        .then(({ data }) => {
          that.token = data.data;
          // that.dataObj = {
          //   key: null,
          //   token: data.data,
          //   file: file
          // }
          // console.log(data,333);
          let observable = qiniu.upload(
            this.file,
            `sd/${this.file.name}`,
            this.token,
            this.putExtra,
            this.config
          );
          observable.subscribe(subObject);
        });

      /* 上传前本地压缩 */
      // qiniu.compressImage(this.file, options).then(data => {
      //   let observable = qiniu.upload(data.dist, this.file.name, this.token, this.putExtra, this.config)
      //   observable.subscribe(subObject)
      // })
      /* 原始文件直传 */
    },
    //
    next(response) {
      let total = response.total;
      //  this.msg = +total.percent.toFixed(2);
      this.msg = +total.percent.toFixed(0);
      this.f.width = this.msg + "%";
    },
    error(response) {
      this.$message.error("上传失败，请重新上传");
    },
    complete(response) {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.uploadForm.sucaiUrl = `https://qiniu.twyia.com/${response.key}`;
      console.log(this.uploadForm.sucaiUrl);
    },

    // 点击文件预览弹出框
    handlePreview(file) {
      console.log(file);
      this.dialogVisible = true;
      this.dialogUrl = file.response.result;
      // this.$alert('<img src="' + file.url + '" style="width:100%" />', '预览图片');
    },
    //  关联群聊
    ShowRelevanceGroup() {
      this.$http({
        url: this.$http.adornUrl("/app/friend/GetZlUidGroupList"),
        method: "get",
      }).then(({ data }) => {
        if (data && data.code === 0) {
          // console.log(data, 333);
          if (data.groupData.length == 0) {
            this.relevanceGroup = [];
          } else {
            this.relevanceGroup = data.groupData;
          }
        }
      });
    },

    // 关联样图
    dialogSampFun() {
      // console.log("点击了");
      this.dialogSampleFlg = true;
      this.$nextTick(() => {
        this.$refs.dialogSample.init(1);
      });
    },
    // 显示所有的授权信息
    ShowaccreditTypeList() {
      this.$http({
        url: this.$http.adornUrl("/app/material/getAccreditType"),
        method: "get",
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.accreditArr = textA(data.accreditType);
        } else {
          this.$message.error(data.msg);
        }
      });
    },

    // 显示所有的题材分类
    ShowtagList() {
      this.$http({
        url: this.$http.adornUrl("/app/post/tagPost"),
        method: "get",
        params: this.$http.adornParams({
          // 题材分类id
          cateid: 5,
        }),
      }).then((data) => {
        if (data.data.code === 0) {
          this.tagList = data.data.tagList;
        }
      });
    },
    //  查询基础模型
    foundationModel() {
      this.$http({
        url: this.$http.adornUrl("/app/material/getCommonConfig"),
        method: "get",
        params: this.$http.adornParams({
          key: "pc_basic_model",
        }),
      }).then((data) => {
        this.basicModelArr = textA(data.data.value);
      });
    },
    //模型类型
    TypeModel() {
      this.$http({
        url: this.$http.adornUrl("/app/material/getCommonConfig"),
        method: "get",
        params: this.$http.adornParams({
          key: "pc_model_type",
        }),
      }).then((data) => {
        this.TypeModelArr = textA(data.data.value);
        // console.log(this.TypeModelArr, 33);
      });
    },
    // 上传图片
    handleBgImageSuccess(response, file, fileList) {
      this.uploadForm.sucaiUrl = response.result;
      this.uploadForm.fileUrl = response.id;
    },
    beforeUpload(file) {
      // type: "video/mp4" "audio/mpeg"  "image/png" "image/jpeg" "image/webp" || "audio/ogg"
      let isVideo = file.type === "video/mp4";
      let isAudio = file.type === "audio/mpeg";
      let isJPG = file.type === "image/jpeg";
      let isPng = file.type === "image/png";
      let iswebp = file.type === "image/webp";
      // 1图片、2视频、3音频、4文字
      if (this.cateid == 1 && !isJPG && !isPng && !iswebp) {
        this.$message.error("请上传图片素材!");
        return isJPG && isPng && iswebp;
      }
      if (this.cateid == 2 && !isVideo) {
        this.$message.error("请上传视频素材!");
        return isVideo;
      }
      if (this.cateid == 3 && !isAudio) {
        this.$message.error("请上传音频素材!");
        return isAudio;
      }
    },
    ModelCurNoDropFlgFun() {
      this.$emit("ModelCurNoDropFlgFun", false);
    },
  },
  watch: {
    ModelTye(newVal) {
      let tags = [];
      this.uploadForm.tags.forEach((item) => {
        tags.push(item.id);
      });
      if (this.uploadForm.title == "") {
        this.$message.error("请输入标题");
        return;
      } else if (this.accreditArrValue == "") {
        this.$message.error("请选择授权信息");
        return;
      } else if (this.uploadForm.content == "") {
        this.$message.error("内容不能为空");
        return;
      } else if (
        this.uploadForm.accreditType == "" ||
        this.uploadForm.accreditType == null
      ) {
        this.$message.error("请选择授权信息");
        return;
      } else if (this.uploadForm.sucaiUrl == "") {
        this.$message.error("模型不能为空");
        return;
      } else if (this.uploadForm.modeltype == "") {
        this.$message.error("模型类型不能为空");
        return;
      } else if (this.relevanceImgsList.length == 0) {
        this.$message.error("请至少选择一张关联样图");
        return;
      } else if (tags.length == 0) {
        this.$message.error("请选择题材分类");
        return;
      }

      // let accreditType = this.uploadForm.accreditType.value;
      let basemodel = this.uploadForm.basemodel.value;
      let modeltype = this.uploadForm.modeltype.value;
      // console.log(accreditType);
      // return
      this.ModelCurNoDropFlgFun();
      let data = {
        // 配合比赛
        isforcepe: 0,
        //素材作者
        author: JSON.parse(localStorage.getItem("User")).username,
        //题材分类
        cateid: 5,
        filepath: "",
        //是否免费：0.免费 1.付费
        isfree: 0,
        //权限：0.公开 1.私有
        isopen: this.uploadForm.permission,
        //素材标题
        title: this.uploadForm.title,
        //素材价格
        price: 0,
        //题材标签
        // tags: JSON.stringify(tags),
        tags: tags.join(","),
        // 素材类型：1图片、2文字、3视频、4音频、5模型
        type: 5,
        uid: JSON.parse(localStorage.getItem("User")).uid,
        content: this.uploadForm.content,
        // 时长
        duration: "",
        //授权类型
        accreditType: this.uploadForm.accreditType.value,
        ossEntities: [
          {
            baseId: "", //文件关联业务id
            baseType: "", //文件种类
            createDate: "", //创建时间
            fileType: 1, //文件类型：1图片、2文字、3视频、4音频
            id: 0, //id
            url: this.uploadForm.sucaiUrl,
          },
          //文件路径
        ], //文件

        //基础模型
        basemodel: basemodel,
        //模型类型
        modeltype: modeltype,
        //模型版本号
        modelversion: this.uploadForm.modelversion,
        //关联我的群聊
        relevancegp: this.relevancegp,
        // 关联图片列表
        assgraphList: this.relevanceImgsList,
      };
      // return;
      this.$http({
        url: this.$http.adornUrl("/app/material/add"),
        method: "post",
        data: this.$http.adornData(data),
      }).then((data) => {
        this.$emit("ModelCurNoDropFlgFun", true);
        // console.log(1223);
        if (data.data.code === 0) {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.$router.push({ name: "home" });
          location.reload();
        } else {
          this.$message.error(`${data.data.msg}`);
        }
      });
    },
  },
  computed: {
    nums() {
      return this.relevanceImgsList.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../component.scss";

// 已关联样图
.relevance-box {
  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      // flex: 20%;
      margin-right: 10px;

      img {
        width: 90px;
        object-fit: cover;
      }
    }
  }
}

.upFen {
  input {
    width: 50px;
    height: 25px;
    border-radius: 3px;
    color: #000;
  }
}
</style>

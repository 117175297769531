<template>
  <div id="app">
    <template v-if="$route.meta.showHeader">
      <Header />
    </template>
    <div class="main">
      <template v-if="$route.meta.keepAlive">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </template>
      <template v-else>
        <router-view></router-view>
      </template>
    </div>
    <el-backtop :bottom="100">
      <div class="top">UP</div>
    </el-backtop>
  </div>
</template>
<script>
import Header from "@/components/Header";
// import { createWebSocket } from "@/utils/webSocket.js";
import { initWebSocket, webSocketClose } from "@/utils/ws.js";
import { mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  components: {
    Header,
  },
  mounted() {
    // createWebSocket(this.global_callback);
    // initWebSocket();
  },
  methods: {
    // websocket的回调函数，msg表示收到的消息
    ...mapActions(["getAllFriend"]),
  },
  computed: {
    isLogin() {
      return localStorage.getItem("token") == null ? false : true;
    },
  },
  watch: {
    isLogin: {
      handler(val) {
        // console.log(val);
        if (val) {
          initWebSocket();
          this.getAllFriend();
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  background-color: var(--secondary);
}
#app {
  // min-width: 700px;
}
::v-deep .el-backtop {
  width: 50px;
  height: 50px;
  .top {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: #222222;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0.7);
    color: #b3b3b3;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.6s;

    &:hover {
      background-color: #111;
      color: #fff;
    }
  }
}
</style>

<template>
  <div class="header">
    <div class="logo">
      <a href="/">
        <img src="@/assets/images/logo/logoBai (1).png" alt="logo" />
      </a>
    </div>
    <!-- 首页搜索框 -->
    <!-- <div class="search">
      <el-input class="my-input" suffix-icon="el-icon-search" autosize placeholder="搜索" v-model.trim="searchVal">
      </el-input>
    </div> -->
    <div class="handle_box">
      <!-- <div v-if="isLogin" class="invite">邀请好友</div> -->
      <!-- 加入我们 -->
      <div class="Two-dimensional">
        <el-popover
          placement="bottom-start"
          trigger="click"
          :visible-arrow="false"
          popper-class="my_popper"
        >
          <div
            class="img_text_con_ws"
            v-for="(item, index) in addWsList"
            :key="index"
          >
            <span
              :class="{ active: isAddWsIndex == index }"
              @click="addWsFun(item, index)"
              >{{ item.name }}</span
            >
          </div>

          <div slot="reference" class="entrance_game">加入我们</div>
        </el-popover>
      </div>
      <!-- 下载安装App二维码 -->
      <div style="position: relative">
        <div class="Two-dimensional">
          <el-popover
            placement="bottom-start"
            trigger="click"
            :visible-arrow="false"
            popper-class="my_popper"
          >
            <div
              class="img_text_con"
              v-for="(row, index) in DataList"
              :key="index"
            >
              <vue-qr
                :ref="'qrCode' + row.sourceName"
                :logoSrc="row.logoSr"
                :text="row.text"
                :size="120"
                :correctLevel="row.correctLevel"
              ></vue-qr>
              <!--   @click="download(row.sourceName)"-->
              <span class="texts" @click="openDownloadFun()">下载堆鸦App</span>
            </div>

            <div slot="reference" class="entrance_game">App下载</div>
          </el-popover>
        </div>
      </div>

      <div class="entrance_game" @click="GoGameHome()">大赛入口</div>
      <p class="entrance_game" @click="feedBackFun(true)">
        <i class="el-icon-chat-dot-round"></i> 反馈及建议
      </p>
      <template v-if="!isLogin">
        <div class="login" @click="loginPopChange(true)">登录</div>
      </template>
      <!-- 测试账号 -->
      <!-- <template v-if="!isLogin">
        <div class="login" @click="logins()">登录</div>
      </template> -->
      <template v-else>
        <ul class="nav">
          <li class="nav_item" @click="navItemClick('/')">
            <el-tooltip
              effect="dark"
              content="素材库"
              placement="bottom"
              :enterable="false"
            >
              <div class="content">
                <img
                  v-if="$route.path == '/'"
                  src="@/assets/images/file.png"
                  alt="素材库"
                />
                <img v-else src="@/assets/images/file-1.png" alt="素材库" />
              </div>
            </el-tooltip>
          </li>
          <li class="nav_item" @click="navItemClick('/chat')">
            <el-tooltip
              effect="dark"
              content="群组"
              placement="bottom"
              :enterable="false"
            >
              <div class="content">
                <img
                  v-if="$route.path == '/chat'"
                  src="@/assets/images/Frame.png"
                  alt="群组"
                />
                <img v-else src="@/assets/images/Frame-1.png" alt="群组" />
              </div>
            </el-tooltip>
          </li>
          <li class="nav_item" @click="msgNavClick(true)">
            <el-tooltip
              effect="dark"
              content="我的消息"
              placement="bottom"
              :enterable="false"
            >
              <div class="content">
                <img src="@/assets/images/msg.png" alt="我的消息" />
                <div class="badge" v-if="isLogin && msgNum != 0">
                  {{ msgNum }}
                </div>
              </div>
            </el-tooltip>
          </li>
          <li class="nav_item">
            <div class="photo">
              <div class="photo_box" @click="userinfoshowFn()">
                <img :src="UserPhoto" alt="头像" />
              </div>
              <div
                class="userinfo_tab"
                v-show="userinfoshow == true"
                ref="main"
              >
                <div class="tab_content">
                  <div class="r_userinfo">
                    <ul>
                      <li>普通会员</li>
                      <li v-bind:title="userInfo.integral">
                        堆分：{{ userInfo.integral }}
                      </li>
                      <li v-bind:title="userInfo.pilecoin">
                        堆币：{{ userInfo.pilecoin }}
                      </li>
                      <li>机器人套餐：{{ mymeal.tcname }}</li>
                    </ul>
                    <div class="r_workinfo">
                      <div class="work_text">
                        <span>生成文字:</span>
                        <span
                          v-bind:title="
                            mymeal.sumtextNum -
                            mymeal.textNum +
                            '/' +
                            mymeal.sumtextNum
                          "
                          >{{ mymeal.sumtextNum - mymeal.textNum }}/{{
                            mymeal.sumtextNum
                          }}</span
                        >
                      </div>
                      <div class="work_text">
                        <span>生成图片:</span>
                        <span
                          v-bind:title="
                            mymeal.sumimgNum -
                            mymeal.imgNum +
                            '/' +
                            mymeal.sumimgNum
                          "
                          >{{ mymeal.sumimgNum - mymeal.imgNum }}/{{
                            mymeal.sumimgNum
                          }}</span
                        >
                      </div>
                      <div class="btn">
                        <a href="/usercenter/2">升级套餐</a>
                      </div>
                    </div>
                  </div>
                  <ul class="dropdown_ul">
                    <li><a href="/usercenter/1">账号设置</a></li>
                    <li><a href="/usercenter/4">钱包</a></li>
                    <li @click="serviceFun(true)">客服</li>
                    <li @click="aboutUsFun(true)">关于我们</li>
                    <li @click="myFeedBackListFun(true)">我的反馈</li>
                    <li @click="logoutBtnClick">退出登录</li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </template>
    </div>
    <Login :showLogin="showLogin" @closeLogin="loginPopChange" />
    <!-- 聊天 -->
    <MsgCom
      v-if="showMsgCom"
      :showMsgCom="showMsgCom"
      @MsgDialogFun="msgNavClick"
    />
    <!-- 关于 -->
    <AboutUs :showAboutus="showAboutus" @aboutUsFun="aboutUsFun" />
    <!-- 客服 -->
    <CustomerService :showCusService="showService" @serviceFun="serviceFun" />
    <div class="mask" v-show="userinfoshow" @click="userinfoshow = false"></div>
    <!-- 反馈及建议 -->
    <feedBack
      :showFeedBasck="showFeedBasck"
      @feedBackFun="feedBackFun"
    ></feedBack>
    <!-- 查询自己的反馈弹窗 -->
    <listMyFeedback
      :myFeedBackListFlg="myFeedBackListFlg"
      :FeedbackList="FeedbackList"
      @myFeedBackListFun="myFeedBackListFun"
    ></listMyFeedback>
  </div>
</template>
<script>
import Login from "@/components/Login";
import MsgCom from "@/components/MsgCom";
import AboutUs from "@/components/AboutUs";
import CustomerService from "@/components/CustomerService";
import feedBack from "@/components/feedback"; //反馈及建议
import listMyFeedback from "@/components/listMyFeedback/dialog.vue"; //查询自己的反馈
import { clearLoginInfo } from "@/utils/index";
import vueQr from "vue-qr";
export default {
  data() {
    return {
      searchVal: "",
      showLogin: false,
      showMsgCom: false, //聊天
      showAboutus: false, //关于我们
      showService: false, //客服
      showFeedBasck: false, //反馈及建议
      myFeedBackListFlg: false,
      UserPhoto: "",
      userInfo: "",
      mymeal: [], //套餐
      userinfoshow: false, //个人信息显示
      imgUrl: require("@/assets/images/concat.png"), //二维码
      // https://www.twyia.com/
      DataList: [
        {
          sourceName: "1001", //名编号
          logoSr:
            "https://qiniu.twyia.com/duiya/20230918/72dc2b7ca12e42c78f1ee28e76be93e0.png", //二维码中间图片
          text: "https://www.twyia.com/Download", //编码内容
          //  text: "https://qiniu.twyia.com/sd/app-release.apk", //编码内容
          size: 200, //尺寸, 长宽一致, 包含外边距
          correctLevel: 2, //容错级别
        },
      ],
      // 加入我们
      addWsList: [
        {
          value: "1",
          name: "社会招聘",
        },
        {
          value: "2",
          name: "校园招聘",
        },
      ],
      isAddWsIndex: -1,
      status: 3, // 全部
      FeedbackList: [], //我的反馈
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    if (this.token != null) {
      this.getUserList();
      this.getainum();
      this.myFeedbackFun(); //我的反馈
    }
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
    this.$bus.$on("show_msgCom", (e) => {
      this.msgNavClick(e.flag);
      console.log(e, 6665);
    });
    if (JSON.parse(localStorage.getItem("User"))) {
      // console.log(122, 111111);
      this.UserPhoto = JSON.parse(localStorage.getItem("User")).avatar;
    }
  },
  beforeDestroy() {
    this.$bus.$off("show_msgCom");
    // document.removeEventListener("click", this.bodyCloseMenus);
  },
  methods: {
    // 我的反馈 /app/user/listMyFeedback 反馈的状态  status:0 客服未读、 1 已读 、2 已回复、 3全部
    myFeedbackFun() {
      this.$http({
        url: this.$http.adornUrl("/app/user/listMyFeedback"),
        method: "get",
        params: this.$http.adornParams({
          status: this.status,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          // this.userInfo = data.result;
          this.FeedbackList = data.data;
          // console.log("我的反馈", data, this.FeedbackList);
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    // 加入我们
    addWsFun(item, index) {
      this.isAddWsIndex = index;
      // console.log("加入我们", item, index);
    },
    openDownloadFun() {
      let routeUrl = this.$router.resolve({
        path: "/Download",
        // query: {id:96}
      });
      window.open(routeUrl.href, "_blank");
    },
    // download(sourceName) {
    //   //下载
    //   //const iconUrl = this.$refs['qrCode' + sourceName].$el.src //2021年做项目写
    //   const iconUrl = this.$refs["qrCode" + sourceName][0].$el.src; //2023年修改
    //   const a = document.createElement("a"); //自定义a标签
    //   const event = new MouseEvent("click"); //自定义鼠标点击事件
    //   a.download = sourceName;
    //   a.href = iconUrl;
    //   a.dispatchEvent(event); //触发自定义事件
    // },
    //获取用户信息
    getUserList() {
      this.$http({
        url: this.$http.adornUrl("/app/user/userInfo"),
        method: "get",
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.userInfo = data.result;
          console.log("用户信息", this.userInfo);
        } else {
          // this.$message.error(data.msg);
        }
      });
    },
    // 去大赛
    GoGameHome() {
      // this.$router.push({ name: "gamehome" });
      let routeUrl = this.$router.resolve({
        // path: "/gamehome",
        name: 'gamehome',
        // query: {id:96}
      });
      window.open(routeUrl.href, "_blank");
    },
    //点击个人信息
    userinfoshowFn() {
      if (
        localStorage.getItem("token") == null ||
        localStorage.getItem("User") == null
      ) {
        this.loginPopChange(true);
      } else {
        this.userinfoshow = !this.userinfoshow;
        // console.log(1212, this.userinfoshow);
      }
    },
    // 登录弹窗操作
    loginPopChange(i) {
      this.showLogin = i;
    },

    // 导航菜单点击
    navItemClick(src) {
      if (src == "/") {
        this.$router.push(src);
      } else {
        if (
          localStorage.getItem("token") == null ||
          localStorage.getItem("User") == null
        ) {
          this.loginPopChange(true);
        } else {
          this.$router.push(src);
        }
      }
    },
    //密码登录
    logins() {
      console.log("密码登录");
      this.$router.push({ name: "logins" });
    },
    // 消息点击
    msgNavClick(i) {
      if (
        localStorage.getItem("token") == null ||
        localStorage.getItem("User") == null
      ) {
        this.loginPopChange(true);
      } else {
        this.showMsgCom = i;
      }
    },
    // 关于
    aboutUsFun(i) {
      this.showAboutus = i;
    },
    // 反馈及建议
    feedBackFun(i) {
      // console.log("i99", i);
      this.showFeedBasck = i;
    },
    // 反馈结果弹窗
    myFeedBackListFun(i) {
      // console.log("i99", i);
      this.myFeedBackListFlg = i;
    },
    // 客服
    serviceFun(i) {
      this.showService = i;
    },

    // 退出登录
    logoutBtnClick() {
      this.$confirm("确定进行退出操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "exitCancellation",
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl("/app/user/userlogout"),
            Headers: localStorage.getItem("token"),
            method: "post",
            data: this.$http.adornData(),
          }).then(({ data }) => {
            this.$message({
              message: "退出成功",
              type: "success",
            });
            clearLoginInfo();
            location.reload();
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消",
          // });
        });
    },
    //查看自己ai次数
    getainum() {
      let user = JSON.parse(localStorage.getItem("User"));
      let uid = user.uid;
      this.$http({
        url: this.$http.adornUrl(`/ai/user/findById?userId=${uid}`),
        method: "get",
      }).then(({ data }) => {
        this.mymeal = data;
        console.log("mymeal", this.mymeal);
      });
    },
  },
  watch: {
    // msg: {
    //   handler(val) {
    //     // console.log(val, "接收到新消息");
    //   },
    //   // immediate: true,
    //   deep: true,
    // },
  },
  computed: {
    isLogin() {
      return localStorage.getItem("token");
    },
    msgNum() {
      return this.$store.getters.unread;
    },
    msg() {
      return this.$store.getters.wsMsg;
    },
  },
  components: {
    Login,
    MsgCom,
    AboutUs,
    CustomerService,
    vueQr,
    feedBack, //反馈
    listMyFeedback, //反馈结果
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";

.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  z-index: 9;
  opacity: 0.5;
}
</style>

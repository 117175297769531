<template>
  <!-- 图片库 -->
  <div class="picture">
    <ul
      class="masonry"
      v-if="showImag"
      :style="{ gridTemplateColumns: gridTemplateColumns }"
    >
      <!-- <li class="list_item banner">
        <h1>堆伴们的宝库</h1>
        <h3>
          这里包含了堆鸦社区贡献者及平台提供的各类素材，您可以根据喜好，通过题材分类、搜索来发现灵感
        </h3>
        <h2>
          堆鸦诚挚邀请您
          <span>分享</span>
          您的素材
        </h2>
      </li> -->
      <li
        class="item"
        v-for="(item, index) in ImgList"
        :key="index + Date.now()"
        v-show="item.height > 0"
        :style="{
          backgroundImage: 'url(' + item.sysOssEntities[0].url + ')',
          height: item.height + 'px',
          width: '100%',
          gridRowEnd: item.gridRowEnd,
        }"
        @click="GoPicture(item)"
      >
        <div class="item_top">
          <div class="item_type">
            {{ item.isfree == 0 ? "免费" : "付费" }}
          </div>
          <!-- <div class="handle_box">
            <div
              class="handle_item"
              @click.stop="FavoritePictures(item, index)"
            >
              <img
                src="@/assets/images/Frame@2x-8.png"
                alt="收藏"
                v-show="item.scistate == 0"
              />
              <img
                src="@/assets/images/Frame@2x-4.png"
                alt="已收藏"
                v-show="item.scistate != 0"
              />
              <span class="num_box">{{ item.collectCount }}</span>
            </div>
            <div class="handle_item" @click.stop="LikeImage(item, index)">
              <img
                src="@/assets/images/fav.png"
                alt="未点赞"
                v-show="item.sclikeistate == 0"
              />
              <img
                src="@/assets/images/fav-1.png"
                alt="点赞"
                v-show="item.sclikeistate == 2"
              />
              <span class="num_box">{{ item.likeCount }}</span>
            </div>
          </div> -->
        </div>
        <div class="item_user">
          <div class="info">
            <div class="infoF">
              <div class="mask">
                <div class="user_img">
                  <img :src="item.appUserEntity.avatar" alt="" />
                </div>
                <div
                  class="user_name"
                  v-bind:title="item.appUserEntity.username"
                >
                  {{ item.appUserEntity.username }}
                </div>
              </div>
            </div>
            <div
              class="matrialZlgroupResponse"
              v-if="item.matrialZlgroupResponse !== null && item.matrialZlgroupResponse !== '' "
            >
              <div class="mask">
                <div class="avatar">
                  <img
                    :src="item.matrialZlgroupResponse.media"
                    alt=""
                  />
                </div>
                <div
                  class="user_name"
                  v-bind:title="item.matrialZlgroupResponse.groupname"
                >
                  {{ item.matrialZlgroupResponse.groupname }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <Login :showLogin="showLogin" @closeLogin="loginPopChange" />
  </div>
</template>
<script>
// import { t } from "element-ui/lib/locale";
// import { watch } from "vue";
// import Login from "@/components/Login";
import { showGlobalLoading, stopLoading } from "@/utils";
import Login from "@/components/Login";
export default {
  components: {
    Login,
  },
  props: {
    tagMsg: "",
  },
  data() {
    return {
      gridTemplateColumns: "",
      showImag: false,
      ImgList: [],
      total: 1,
      screenWidth: 0,
      columnCount: 5,
      showLogin: false,
    };
  },

  mounted() {
    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    this.screenWidth = document.body.clientWidth;
  },

  methods: {
    // 登录弹窗操作
    loginPopChange(i) {
      this.showLogin = i;
    },
    // 去图片详情
    GoPicture(e) {
      if (
        localStorage.getItem("token") == null ||
        localStorage.getItem("User") == null
      ) {
        // this.$message.error("请先登录");
        this.loginPopChange(true);
        return;
      } else {
        this.$emit("PictureSuccess", e.id);
      }
    },

    //点赞
    // LikeImage(e, index) {
    //   if (
    //     localStorage.getItem("token") == null &&
    //     localStorage.getItem("User") == null
    //   ) {
    //     this.$message.error("请先登录");
    //     return;
    //   } else {
    //     if (e.sclikeistate == 0) {
    //       this.AddLike(e, index);
    //     } else {
    //       this.EditLike(e, index);
    //     }
    //   }
    // },
    // 添加点赞
    // AddLike(e, index) {
    //   this.$http({
    //     url: this.$http.adornUrl("/app/material/addLike"),
    //     method: "post",
    //     data: this.$http.adornData({
    //       id: e.id, //素材id
    //     }),
    //   }).then((data) => {
    //     this.ImgList[index].sclikeistate = 2;
    //     this.ImgList[index].likeCount++;
    //     this.$message({
    //       message: "添加成功",
    //       type: "success",
    //       duration: 500,
    //     });
    //   });
    // },
    //取消点赞
    // EditLike(e, index) {
    //   this.$http({
    //     url: this.$http.adornUrl("/app/material/cancelLike"),
    //     method: "post",
    //     data: this.$http.adornData({
    //       id: e.id, //素材id
    //     }),
    //   }).then((data) => {
    //     this.ImgList[index].sclikeistate = 0;
    //     this.ImgList[index].likeCount--;
    //     this.$message({
    //       message: "取消成功",
    //       type: "success",
    //       duration: 500,
    //     });
    //   });
    // },

    // 收藏
    // FavoritePictures(e, index) {
    //   if (
    //     localStorage.getItem("token") == null &&
    //     localStorage.getItem("User") == null
    //   ) {
    //     this.$message.error("请先登录");
    //     return;
    //   } else {
    //     if (e.scistate == 0) {
    //       this.AddFavorite(e, index);
    //     } else {
    //       this.EditFavorite(e, index);
    //     }
    //   }
    // },
    //取消收藏
    // EditFavorite(e, index) {
    //   this.$http({
    //     url: this.$http.adornUrl("/app/material/cancel"),
    //     method: "post",
    //     data: this.$http.adornData({
    //       id: e.id, //素材id
    //     }),
    //   }).then((data) => {
    //     this.ImgList[index].scistate = 0;
    //     this.ImgList[index].collectCount--;
    //     this.$message({
    //       message: "取消成功",
    //       type: "success",
    //       duration: 500,
    //     });
    //   });
    // },

    // 添加收藏
    // AddFavorite(e, index) {
    //   this.$http({
    //     url: this.$http.adornUrl("/app/material/addCollect"),
    //     method: "post",
    //     data: this.$http.adornData({
    //       id: e.id, //素材id
    //     }),
    //   }).then((data) => {
    //     this.ImgList[index].scistate = 1;
    //     this.ImgList[index].collectCount++;
    //     this.$message({
    //       message: "收藏成功",
    //       type: "success",
    //       duration: 500,
    //     });
    //   });
    // },
    MaxWith(val) {
      if (val > 1920) {
        this.columnCount = 6;
        this.gridTemplateColumns = "1fr 1fr 1fr 1fr 1fr 1fr";
      } else if (val <= 1920 && val > 1467) {
        // console.log(1223);
        this.gridTemplateColumns = "1fr 1fr 1fr 1fr 1fr";
        this.columnCount = 5;
      } else if (val <= 1466 && val > 1367) {
        this.gridTemplateColumns = "1fr 1fr 1fr 1fr";
        this.columnCount = 4;
      } else if (val <= 1367 && val > 1050) {
        this.gridTemplateColumns = "1fr 1fr 1fr";
        // console.log(1223);
        this.columnCount = 3;
      } else if (val <= 1050) {
        this.gridTemplateColumns = "1fr 1fr";
        // console.log(1223);
        this.columnCount = 2;
      }
      // console.log(this.gridTemplateColumns, 347);
      // console.log(this.columnCount, '   this.columnCount')
    },
    layImage(e1, e2) {
      const img = new Image();
      img.src = e1.sysOssEntities[0].url;

      img.onload = () => {
        let OriginalWidth = img.width;

        let OriginalHeight = img.height;

        let proportionWidth = this.screenWidth / this.columnCount;

        let proport = OriginalWidth / proportionWidth;

        e1.height = OriginalHeight / proport;
        e1.gridRowEnd = `span ${~~(e1.height / 9.8 + 1)}`;
        this.$set(this.ImgList, e2, this.ImgList[e2]);
      };
    },
  },

  watch: {
    deep: true,
    tagMsg(newVal, oldVal) {
      // console.log(this.total,3334);

      this.$http({
        url: this.$http.adornUrl("/app/material/list"),
        method: "post",
        data: this.$http.adornData(newVal),
      }).then((data) => {
        // console.log( data.data.result.data,378);

        if (newVal.page == 1) {
          this.ImgList = data.data.result.data;
          this.ImgList.forEach((e1, e2) => {
            this.layImage(e1, e2);
          });
          // stopLoading()
          // layImage
        } else {
          let c = this.ImgList.length;
          data.data.result.data.forEach((element) => {
            this.ImgList.push(element);
          });
          this.ImgList.forEach((e1, e2) => {
            if (e2 > c) {
              this.layImage(e1, e2);
            }
          });
          //
          // this.layImage
        }

        // console.log(this.ImgList, 314);
        this.screenWidth = document.body.clientWidth;

        this.MaxWith(this.screenWidth);

        this.total = data.data.result.total;
        this.$emit("Showtotal", this.total);
        this.showImag = true;
      });
    },

    screenWidth(val, oldVal) {
      this.MaxWith(val);
      // console.log(111);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>

<template>
  <el-dialog
    :visible.sync="showLogin"
    :before-close="closeLogin"
    width="60%"
    destroy-on-close
    append-to-body
    :modal="false"
    :show-close="false"
    class="login_dialog login_dialog1"
  >
    <div class="login_main">
      <div class="left">
        <img
          class="left_logo"
          src="@/assets/images/logo/logoHei(1).png"
          alt="堆鸦"
        />
        <div class="form">
          <!-- 输入表格 -->
          <el-form
            :model="loginForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <template v-if="LoginType == 1">
              <el-form-item prop="phone" class="phone_email">
                <el-input
                  v-model="loginForm.phone"
                  placeholder="请输入您的手机号"
                ></el-input>
              </el-form-item>
            </template>
            <template v-if="LoginType == 2">
              <el-form-item prop="email" class="phone_email">
                <el-input
                  v-model="loginForm.email"
                  placeholder="请输入您的邮箱"
                ></el-input>
              </el-form-item>
            </template>
            <el-form-item prop="auth_code" class="auth_code">
              <el-input
                clear="code"
                v-model="loginForm.auth_code"
                placeholder="请输入验证码"
              ></el-input>
              <div
                class="get_auth"
                :class="{ wait: auth_count != 60 }"
                @click="deal == true ? getCOde() : ''"
              >
                {{ auth_count == 60 ? "获取验证码" : auth_count + "s" }}
              </div>
            </el-form-item>
            <el-form-item class="deal_item">
              <el-checkbox v-model="dealChecked"
                >我已阅读并同意堆鸦
              </el-checkbox>
              <span @click="dealItemClick(1)">《用户服务协议》</span>
              <span @click="dealItemClick(2)">《隐私政策》</span>
            </el-form-item>
          </el-form>
          <div class="login_btn">
            <el-button
              class="login-btn-submit"
              type="primary"
              @click="LoginBtnClick"
              >登录</el-button
            >
          </div>

          <!-- 其他方式 -->
          <el-divider class="line" content-position="center"
            >其他方式</el-divider
          >
          <div class="other_way">
            <el-tooltip effect="dark" content="微信登录" placement="top-start">
              <div class="o_item">
                <img src="@/assets/images/wx/Frame@3x.png" alt="wechat" />
                <div class="way">微信登录</div>
              </div>
            </el-tooltip>
            <template v-if="LoginType == 2">
              <el-tooltip
                effect="dark"
                content="手机登录"
                placement="top-start"
              >
                <div class="o_item" @click="LoginTypeChange(1)">
                  <img src="@/assets/images/phone.png" alt="phone" />
                  <div class="way">手机登录</div>
                </div>
              </el-tooltip>
            </template>
            <template v-if="LoginType == 1">
              <el-tooltip
                effect="dark"
                content="邮箱登录"
                placement="top-start"
              >
                <div class="o_item" @click="LoginTypeChange(2)">
                  <img src="@/assets/images/yx.png" alt="email" />
                  <div class="way">邮箱登录</div>
                </div>
              </el-tooltip>
            </template>
          </div>
          <div class="tip">
            未注册用户登录后自动注册，登录即代表同意用户服务协议和隐私政策
          </div>
        </div>
      </div>
      <!-- 右侧 -->
      <div class="right">
        <ul>
          <li><img src="@/assets/images/Group 111.png" alt="" /></li>
          <li><img src="@/assets/images/logos/bdcy.png" alt="" /></li>
          <li><img src="@/assets/images/logos/chuangyi.png" alt="" /></li>
          <li><img src="@/assets/images/logos/chuangzao.png" alt="" /></li>
          <li><img src="@/assets/images/logos/AI.png" alt="" /></li>
          <li><img src="@/assets/images/logos/jiazhi.png" alt="" /></li>
          <li>
            <img src="@/assets/images/logos/Frame 427321821@2x.png" alt="" />
          </li>
        </ul>
        <img class="r_bg" src="@/assets/images/Ellipse 986.png" alt="bg" />
      </div>
      <div class="close" @click="closeLogin">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <Dialog
      :showDialog="dealDialogShow"
      @closeDialog="closeDealDialog"
      :title="dealDialogTitle"
      :countent="dealDialogCountent"
    />
  </el-dialog>
</template>

<script>
import Dialog from "@/components/Dialog";
// import { initWebSocket } from "@/utils/ws.js";

export default {
  props: {
    showLogin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 1 手机号 2邮箱
      LoginType: 1,

      // 登陆数据
      loginForm: {
        phone: "",
        email: "",
        auth_code: "",
      },
      rules: {
        phone: [
          { required: true, message: "请输入您的手机号", trigger: "blur" },
        ],
        email: [{ required: true, message: "请输入您的邮箱", trigger: "blur" }],
        auth_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      deal: true,
      dealYx: true, //邮箱
      // 协议选择
      dealChecked: true,
      auth_count: 60, //验证码计数
      dealDialogTitle: "隐私协议", //协议弹窗标题
      dealDialogShow: false, //协议弹窗展示
      dealDialogCountent: "", //协议内容
    };
  },
  methods: {
    //获取验证码
    getCOde() {
      if (this.LoginType == 1) {
        // 手机登录
        this.getPhoneCode();
      } else {
        // 邮箱登录
        this.getEmail();
      }
    },
    //  手机验证码
    getPhoneCode() {
      let verify = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      if (verify.test(this.loginForm.phone) == false) {
        this.$message.error("请输入正确的手机号");
      } else {
        if (this.auth_count == 60) {
          this.deal = false;
          this.timer = setInterval(() => {
            if (this.auth_count > 0) {
              this.auth_count--;
            } else {
              this.deal = true;
              this.auth_count = 60;
              clearInterval(this.timer);
            }
          }, 1000);
          this.$http({
            url: this.$http.adornUrl("/app/user/sendSmsCode"),
            method: "post",
            data: this.$http.adornData({
              mobile: this.loginForm.phone,
            }),
          }).then((data) => {
            this.msg = data.data.code;
            console.log("data", data);
          });
        } else {
          this.$message.error(`${this.auth_count}秒后再试`);
          clearInterval(this.auth_count);
        }
      }
    },
    // 邮箱验证码
    getEmail() {
      let verifYx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (verifYx.test(this.loginForm.email) == false) {
        this.$message.error("请输入正确的邮箱");
      } else {
        this.deal = false;
        this.timer = setInterval(() => {
          if (this.auth_count > 0) {
            this.auth_count--;
          } else {
            this.deal = true;
            this.auth_count = 60;
            clearInterval(this.timer);
          }
        }, 1000);
        this.$http({
          url: this.$http.adornUrl("/app/user/sendEmailCode"),
          method: "post",
          data: this.$http.adornData({
            email: this.loginForm.email,
          }),
        }).then((data) => {
          this.msg = data.data.code;
        });
      }
    },
    // 隐私/用户服务点击
    dealItemClick(i) {
      // i-> 1用户 2 隐私
      if (i == 1) {
        this.dealDialogTitle = "用户服务协议";
      } else if (i == 2) {
        this.dealDialogTitle = "隐私政策";
      }
      this.dealDialogShow = true;
      this.getPrivacyProtocol(i);
      // console.log(i, "----------");
    },
    // 个人隐私政策'/app/system/privacy'---用户端——查询用户服务协议'/app/system/protocol'
    getPrivacyProtocol(i) {
      // console.log(i, "*******");
      let url =
        i === 1 ? "/app/system/protocol" : i === 2 ? "/app/system/privacy" : "";
      this.$http({
        url: this.$http.adornUrl(url),
        method: "get",
        params: this.$http.adornParams({}),
      }).then((data) => {
        this.dealDialogCountent = data.data.result;
        // console.log(
        //   `${i == 1 ? "用户服务协议" : i == 2 ? "个人隐私政策" : ""}`,
        //   this.dealDialogCountent
        // );
      });
    },
    // 登录按钮点击
    LoginBtnClick() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let data = {};
          if (this.LoginType == 1) {
            data = {
              code: this.loginForm.auth_code,
              mobile: this.loginForm.phone,
            };
          } else if (this.LoginType == 2) {
            data = {
              code: this.loginForm.auth_code,
              email: this.loginForm.email,
            };
          }
          this.Login(data);
        }
      });
    },
    //登录
    Login(e) {
      if (this.dealChecked == true) {
        this.$http({
          url: this.$http.adornUrl("/app/user/smsLogin"),
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          withCredentials: true,
          method: "post",
          data: this.$http.adornData(e),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.dialogVisible = false;
            localStorage.setItem("User", JSON.stringify(data.data));
            localStorage["token"] = data.token;
            location.reload();
            // initWebSocket();
          } else {
            this.$message.error(data.msg);
          }
        });
      } else {
        this.$message.error("请勾选我已阅读并同意");
      }
    },

    // 登陆类型切换
    LoginTypeChange(i) {
      this.$refs.ruleForm.resetFields();
      this.LoginType = i;
    },
    // 关闭登陆弹窗
    closeLogin() {
      this.$emit("closeLogin", false);
    },
    closeDealDialog(i) {
      this.dealDialogShow = i;
    },
  },
  components: {
    Dialog,
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>

<template>
  <!-- 模型 -->
  <div class="model">
    <ul class="model_list">
      <li
        class="list_item"
        @click="GoModle(item)"
        v-for="(item, index) in ModelList"
        :key="index"
        :style="{ backgroundImage: 'url(' + ModelImg(item.assgraph) + ')' }"
      >
        <div class="item_top">
          <div class="item_type free">
            {{ item.isfree == 0 ? "免费" : "付费" }}
            <!-- 免费 -->
          </div>
          <!-- <div class="handle_box">
            <div
              class="handle_item"
              @click.stop="FavoritePictures(item, index)"
            >
              <img
                src="@/assets/images/Frame@2x-8.png"
                alt="收藏"
                v-show="item.scistate == 0"
              />
              <img
                src="@/assets/images/Frame@2x-4.png"
                alt="已收藏"
                v-show="item.scistate != 0"
              />
              <span class="num_box">{{ item.collectCount }}</span>
            </div>
            <div class="handle_item" @click.stop="LikeImage(item, index)">
              <img
                src="@/assets/images/fav.png"
                alt="未点赞"
                v-show="item.sclikeistate == 0"
              />
              <img
                src="@/assets/images/fav-1.png"
                alt="点赞"
                v-show="item.sclikeistate == 2"
              />
              <span class="num_box">{{ item.likeCount }}</span>
            </div>
          </div> -->
        </div>
        <div class="info">
          <div class="item_name">{{ item.title }}</div>
          <!-- <div class="item_user">
            <div class="user_info">
              <div class="user_img">
                <img
                  :src="
                    item.matrialZlgroupResponse !== null
                      ? item.matrialZlgroupResponse.media
                      : item.appUserEntity.avatar
                  "
                  alt=""
                />
              </div>
              <div class="user_name">
                {{
                  item.matrialZlgroupResponse !== null
                    ? item.matrialZlgroupResponse.groupname
                    : item.appUserEntity.username
                }}
              </div>
            </div>
            <div
              class="showusername"
              v-if="item.matrialZlgroupResponse !== null"
            >
              {{ item.appUserEntity.username }}
            </div>
          </div> -->
          <div class="item_user">
            <div class="info_o">
              <div class="infoF">
                <div class="mask">
                  <div class="user_img">
                    <img :src="item.appUserEntity.avatar" alt="" />
                  </div>
                  <div
                    class="user_name"
                    v-bind:title="item.appUserEntity.username"
                  >
                    {{ item.appUserEntity.username }}
                  </div>
                </div>
              </div>
              <div
                class="matrialZlgroupResponse"
                v-if="item.matrialZlgroupResponse !== null && item.matrialZlgroupResponse !== '' "
              >
                <div class="mask">
                  <div class="avatar">
                    <img
                      :src="item.matrialZlgroupResponse.media"
                      alt=""
                    />
                  </div>
                  <div
                    class="user_name"
                    v-bind:title="item.matrialZlgroupResponse.groupname"
                  >
                    {{ item.matrialZlgroupResponse.groupname }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <Login :showLogin="showLogin" @closeLogin="loginPopChange" />
  </div>
</template>
<script>
import Login from "@/components/Login";
export default {
  components: {
    Login,
  },
  props: {
    ModeMsg: "",
  },
  data() {
    return {
      ModelList: [],
      total: 1,
      showLogin: false,
    };
  },
  methods: {
    ModelImg(e) {
      return e.split(",")[0];
    },
    // 登录弹窗操作
    loginPopChange(i) {
      this.showLogin = i;
    },
    // 去模型详情
    GoModle(e) {
      console.log(e, 3455);
      if (
        localStorage.getItem("token") == null ||
        localStorage.getItem("User") == null
      ) {
        this.loginPopChange(true);
        // this.$message.error("请先登录");
        return;
      } else {
        this.$emit("ModelSuccess", e.id);
      }
    },

    //点赞
    // LikeImage(e, index) {
    //   if (
    //     localStorage.getItem("token") == null &&
    //     localStorage.getItem("User") == null
    //   ) {
    //     this.$message.error("请先登录");
    //     return;
    //   } else {
    //     if (e.sclikeistate == 0) {
    //       this.AddLike(e, index);
    //     } else {
    //       this.EditLike(e, index);
    //     }
    //   }
    // },
    // 添加点赞
    // AddLike(e, index) {
    //   this.$http({
    //     url: this.$http.adornUrl("/app/material/addLike"),
    //     method: "post",
    //     data: this.$http.adornData({
    //       id: e.id, //素材id
    //     }),
    //   }).then((data) => {
    //     this.ModelList[index].sclikeistate = 2;
    //     this.ModelList[index].likeCount++;
    //     this.$message({
    //       message: "添加成功",
    //       type: "success",
    //       duration: 500,
    //     });
    //   });
    // },
    //取消点赞
    // EditLike(e, index) {
    //   this.$http({
    //     url: this.$http.adornUrl("/app/material/cancelLike"),
    //     method: "post",
    //     data: this.$http.adornData({
    //       id: e.id, //素材id
    //     }),
    //   }).then((data) => {
    //     this.ModelList[index].sclikeistate = 0;
    //     this.ModelList[index].likeCount--;
    //     this.$message({
    //       message: "取消成功",
    //       type: "success",
    //       duration: 500,
    //     });
    //   });
    // },

    // 收藏
    // FavoritePictures(e, index) {
    //   if (
    //     localStorage.getItem("token") == null &&
    //     localStorage.getItem("User") == null
    //   ) {
    //     this.$message.error("请先登录");
    //     return;
    //   } else {
    //     if (e.scistate == 0) {
    //       this.AddFavorite(e, index);
    //     } else {
    //       this.EditFavorite(e, index);
    //     }
    //   }
    // },
    //取消收藏
    // EditFavorite(e, index) {
    //   this.$http({
    //     url: this.$http.adornUrl("/app/material/cancel"),
    //     method: "post",
    //     data: this.$http.adornData({
    //       id: e.id, //素材id
    //     }),
    //   }).then((data) => {
    //     this.ModelList[index].scistate = 0;
    //     this.ModelList[index].collectCount--;
    //     this.$message({
    //       message: "取消成功",
    //       type: "success",
    //       duration: 500,
    //     });
    //   });
    // },

    // 添加收藏
    // AddFavorite(e, index) {
    //   this.$http({
    //     url: this.$http.adornUrl("/app/material/addCollect"),
    //     method: "post",
    //     data: this.$http.adornData({
    //       id: e.id, //素材id
    //     }),
    //   }).then((data) => {
    //     this.ModelList[index].scistate = 1;
    //     this.ModelList[index].collectCount++;
    //     this.$message({
    //       message: "收藏成功",
    //       type: "success",
    //       duration: 500,
    //     });
    //   });
    // },
  },
  watch: {
    ModeMsg(newVal) {
      this.$http({
        // url: this.$http.adornUrl("/sys/weblogin"),
        url: this.$http.adornUrl("/app/material/list"),
        method: "post",
        data: this.$http.adornData(newVal),
      }).then((data) => {
        if (newVal.page == 1) {
          this.ModelList = data.data.result.data;
          this.total = data.data.result.total; // console.log(this.ModelList, 5455);
        } else {
          data.data.result.data.forEach((element) => {
            this.ModelList.push(element);
          });
        }
      });
      this.$emit("Showtotal", this.total);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>

<template>
  <el-dialog
    title="关于我们"
    :visible.sync="showAboutus"
    width="40%"
    append-to-body
    custom-class="about_us"
    :before-close="handleClose"
  >
    <div class="pop_text about_us">
      <div class="img_box">
        <img src="@/assets/images/logo.png" alt="" />
        <img src="@/assets/images/logo/logoBai (1).png" alt="" />
      </div>
      <div class="text_item">
        <span>公司名称：</span> 无优堆鸦（武汉）智能科技有限公司
      </div>
      <div class="text_item">
        <span>公司介绍：</span>
        我们致力于创造优质的互联网内容创意创作社群，利用AI技术为创作者提供极致创作体验，并提供从创意到商业价值的全过程服务。
      </div>
      <div class="text_item"><span>联系电话：</span> 400-631-3398</div>
      <!-- <div class="text_item">
        <span>联系地址：</span>
        湖北省武汉市东湖新技术开发区高新二路286号1.1期B-1栋2层03办公号-1
      </div> -->
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    showAboutus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      property: "value",
    };
  },
  methods: {
    handleClose() {
      this.$emit("aboutUsFun", false);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .about_us {
  .el-dialog__header {
    text-align: center;

    .el-dialog__title {
      color: #fff;
      font-size: 24px;
    }
  }

  .el-dialog__body {
    min-height: 40vh;
    max-height: 60vh;
    overflow-y: auto;
    padding: 0 30px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d5d5d5;
    }
  }
  .pop_text {
    color: #fff;
    max-height: 60vh;
    overflow-y: auto;
    padding-bottom: 20px;
    .img_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 40px 0;

      img {
        display: block;
        width: 200px;
      }
    }

    .text_item {
      padding: 0 40px;
      margin-bottom: 10px;

      span {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
</style>

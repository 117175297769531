<template>
  <!-- 充值 -->
  <el-dialog
    width="50%"
    :visible="showRecharge"
    :show-close="false"
    custom-class="recharge"
    append-to-body
  >
    <div class="main_box">
      <div class="r_header">
        <div class="userinfo">
          <div class="img_user">
            <img :src="userInfo.avatar" alt="头像" />
          </div>
          <div class="user_info_box">
            <div class="name">{{ userInfo && userInfo.username }}</div>
            <div class="u_id">{{ userInfo.uid }}</div>
          </div>
        </div>
        <div class="nav_box">
          <div
            class="nav_item"
            :class="{ active: activeType == item.id }"
            v-for="item in navList"
            :key="item.id"
            @click="navItemClick(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="r_body">
        <!-- 用量 -->
        <template v-if="activeType == 1">
          <div class="form_box">
            <div class="form_header">
              <div class="t_item">项目</div>
              <div class="t_item">定价</div>
            </div>
            <div class="form_body">
              <div class="f_item">
                <div class="item">文本(元/次/800字)</div>
                <div class="item">￥0.013</div>
              </div>
              <div class="f_item">
                <div class="item">文本(元/次/800字)</div>
                <div class="item">￥0.013</div>
              </div>
            </div>
          </div>
        </template>
        <!-- 套餐 -->
        <template v-if="activeType == 2">
          <div class="pay_box">
            <div class="type_list">
              <div
                class="type_item"
                v-for="(item, index) in setMealList"
                :key="index"
                @click="setMealItemClick(item, index)"
              >
                <div
                  class="item_info"
                  :class="{ active: activeSetMeal == index }"
                >
                  <div class="info_item price">
                    {{ item.price == "免费" ? "免费" : "￥" + item.price }}
                  </div>
                  <div class="info_item">
                    <span>文本</span
                    ><span class="num">{{ item.textNum }}次</span>
                  </div>
                  <div class="info_item">
                    <span>图片</span
                    ><span class="num">{{ item.imgNum }}次</span>
                  </div>
                  <div class="info_item">
                    <span>快速出图</span
                    ><span class="num">{{ item.himgNum }}次</span>
                  </div>
                </div>
                <div class="item_name">
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div class="tip">*以上用量一年有效</div>

            <div class="pay_main">
              <div class="pay_tite">支付订单</div>
              <div class="pay_tip">支付成功后可在"订单列表"开具发票</div>
              <div class="ewm_tip">
                <div class="ewm">
                  <img src="@/assets/images/concat.png" alt="ewm" />
                  <div>使用微信/支付宝 扫码支付</div>
                  <div>支付即视为您同意 <span>相关协议</span>条例</div>
                </div>
                <div class="need_pay">
                  <div class="pay_price">
                    应付金额 <span>￥</span><span>{{ 89 }}</span
                    >元
                  </div>
                  <div class="img_box">
                    <img
                      src="@/assets/images/images/支付-微信.png"
                      alt="微信"
                    /><img
                      src="@/assets/images/images/支付-支付宝.png"
                      alt="支付宝"
                    />使用微信/支付宝支付
                  </div>
                  <div class="agree">
                    支付即视为您同意<span>《堆鸦会员服务协议》</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer">ICP备案信息（鄂ICP备2023008233号-1）</div>
          </div>
        </template>
      </div>
      <div class="close_icon el-icon-close" @click="closeDialog"></div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    // 展示类型 1用量价格 2套餐价格
    rechargeType: {
      type: Number,
      default: 1,
    },
    // 充电展示
    showRecharge: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeType: this.rechargeType || 1, //展示的菜单
      activeSetMeal: 0, //选中的套餐
      // 菜单
      navList: [
        { id: 1, name: "用量价格" },
        { id: 2, name: "套餐价格" },
      ],

      setMealList: [], //套餐
    };
  },
  created() {
    this.getUserSetMeal();
  },
  methods: {
    // 获取支付套餐
    getUserSetMeal() {
      this.$http({
        url: this.$http.adornUrl("/ai/taocan/list"),
        method: "get",
      }).then(({ data }) => {
        if (data) {
          this.setMealList = data;
          console.log("套餐", data);
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    // 套餐单项点击
    setMealItemClick(item, i) {
      this.activeSetMeal = i;
    },
    // 菜单点击
    navItemClick(item) {
      this.activeType = item.id;
    },
    // 关闭
    closeDialog() {
      this.$emit("CloseRecharge", false);
    },
  },
  computed: {
    userInfo() {
      // console.log(localStorage.getItem("User"));
      return JSON.parse(localStorage.getItem("User"));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>

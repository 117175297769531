<template>
  <!-- 文字 -->
  <div class="word">
    <ul class="word_list">
      <li class="word_item" v-for="(item, index) in 30" :key="index">
        <div class="user">
          <div class="user_img">
            <img src="@/assets/images/avatai2.jpg" alt="头像" />
          </div>
          <div class="user_name">asdsdas</div>
        </div>
        <div class="content">
          诗歌(英语: Poetry) 是一种有节奏和韵律、
          舞蹈表达情感的诗歌被认为是文学最初的起源，其最初发生于尚未有文字的人类社会，
          表达凝练 结构多样 用于反映 主活和表达情感的 于尚未有文字的人类社会
          以口语的 式流传，并与音乐、 以口语的形式流传，并与音乐、
          舞蹈表达情感的诗歌被认为是文学最初的起源，其最初发生于尚未有文字的人类社会，以口语的形式流传，并与音乐、舞蹈表达情感的
          舞蹈表达情感的诗歌被认为是文学最初的起源，其最初发生于尚未有文字的人类社会
          以口语的形式流传 并与音乐 诗歌被认为是文学最初的起源,
          其最初发生于尚未有文字的人类社会， 以口语的形式流传，并与音乐
          舞蹈表达情感的诗歌被认为是文学最初的起源
          其最初发生于尚未有文字的人类社会，以口语的形式流传，并与音乐、舞蹈
        </div>
        <div class="handle_box">
          <div class="item collect">
            <img src="@/assets/images/Frame@2x-8.png" alt="收藏" />
            <!-- <img src="@/assets/images/Frame@2x-4.png" alt="已收藏" /> -->
          </div>
          <div class="item detail_btn" @click="wmDialogShow(true)">
            详情
          </div>
        </div>
      </li>
    </ul>
    <WMDialog
      :DetailDialogShow="DetailDialogShow"
      @wmDialogShow="wmDialogShow"
    />
  </div>
</template>
<script>
import WMDialog from "@/components/Detail/WMDialog";
export default {
  data() {
    return {
      DetailDialogShow: false,
    };
  },
  methods: {
    wmDialogShow(i) {
      this.DetailDialogShow = i;
    },
  },
  components: {
    WMDialog,
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<template>
  <!-- 视频库 -->
  <div class="">视频库</div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
import store from '@/store'
import { result } from 'lodash';
var webSocket = null;
var globalCallback = null;//定义外部接收数据的回调函数
let timer = null;
// 是否执行重连 true/不执行 ； false/执行a
let lockReconnect = false;
let wsCreateHandlerCount = 0


let tokenKey = localStorage.getItem("token");
var wsurl = "wss://api.twyia.com/app/socket/" + tokenKey;//  线上 https://api.twyia.com/doc.html
// var wsurl = "ws://192.168.10.26:8080/app/socket/" + tokenKey; //本地 http://192.168.10.26:8080 ws:
// 初始化websocket
export function initWebSocket() {
    //判断浏览器是否支持websocket
    if ("WebSocket" in window && tokenKey != "") {
        try {
            webSocket = new WebSocket(wsurl);//创建socket对象
        } catch (err) {
            console.log("初始化连接异常,开始重连");
            reconnect()
        }
    } else {
        alert("该浏览器不支持websocket!");
    }
    //打开
    webSocket.onopen = function () {
        webSocketOpen();
        // heartCheck.start()
    };
    //收信
    webSocket.onmessage = function (msg) {
        webSocketOnMessage(msg);
        // heartCheck.start()
    };
    //关闭
    webSocket.onclose = function (event) {
        webSocketClose(event);
    };
    //连接发生错误的回调方法
    webSocket.onerror = function () {
        console.log("WebSocket连接发生错误");
        reconnect()
    };
}

//连接socket建立时触发
export function webSocketOpen() {
    console.log("WebSocket连接成功");
}

//客户端接收服务端数据时触发,e为接受的数据对象
export function webSocketOnMessage(msg) {
    // 存储在store中，然后在聊天界面中监控变化 自动拿取收到的信息
    // let result = null;

    // store.commit('SET_WS_MSG', msg)
    if (msg.data instanceof Blob) {
        const reader = new FileReader();
        reader.readAsText(msg.data, "UTF-8");
        reader.onload = (e) => {
            result = JSON.parse(reader.result);
            global_callback(result);
        };
        console.log(result, 666);
        // store.commit('SET_WS_MSG', result)
        store.dispatch('receiveMsgFun', result)

    } else {
        let resData = JSON.parse(msg.data);
        if (resData.type != "count") {
            // store.commit('SET_WS_MSG', resData)
            store.dispatch('receiveMsgFun', resData)
        }
        // store.commit('SET_WS_MSG', resData)

    }
}

//发送数据
export function webSocketSend(data) {
    console.log('发送数据');
    //在这里根据自己的需要转换数据格式
    webSocket.send(JSON.stringify(data));
}


//关闭socket
export function webSocketClose(event) {
    // 非正常关闭重连
    if (event && event.code != 1000) {
        reconnect();
    } else {
        if (webSocket) {
            webSocket.close()
            lockReconnect = true;
            timer && clearTimeout(timer);
            webSocket = null
            // heartCheck.stop();
            // wsCreateHandlerCount = 0
            console.log("对话连接已关闭");
        }
    }
}

// 重连函数
const reconnect = () => {
    if (lockReconnect || webSocket) {
        return;
    }
    wsCreateHandlerCount += 1
    if (wsCreateHandlerCount >= 10) return webSocketClose();
    console.log('1秒后重连');
    lockReconnect = true;
    // 没连接上会一直重连，设置延迟避免请求过多
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
        initWebSocket();
        lockReconnect = false;
    }, 1000);
}


// 心跳检查（看看websocket是否还在正常连接中）
let heartCheck = {
    timeout: 300000,
    timeoutObj: null,
    serverTimeoutObj: null,
    // 重启
    reset() {
        clearTimeout(this.timeoutObj);
        clearTimeout(this.serverTimeoutObj);
        this.start();
    },
    // 停止
    stop() {
        clearTimeout(this.timeoutObj);
        clearTimeout(this.serverTimeoutObj);
    },
    // 开启定时器
    start() {
        this.timeoutObj && clearTimeout(this.timeoutObj);
        this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
        // 15s之内如果没有收到后台的消息，则认为是连接断开了，需要重连
        this.timeoutObj = setTimeout(() => {
            console.log("心跳检查，发送ping到后台");
            try {
                const datas = { code: 2010 };
                webSocket.send(JSON.stringify(datas));
            } catch (err) {
                console.log("发送ping异常");
            }
            console.log("内嵌定时器this.serverTimeoutObj: ")
            // 内嵌定时器
            this.serverTimeoutObj = setTimeout(() => {
                console.log("没有收到后台的数据，重新连接");
                reconnect();
            }, this.timeout)
        }, this.timeout)
    }
}







//在其他需要socket地方调用的函数，用来发送数据及接受数据
export function sendSock(agentData, callback = () => { }) {
    globalCallback = callback;//此callback为在其他地方调用时定义的接收socket数据的函数，此关重要。
    //下面的判断主要是考虑到socket连接可能中断或者其他的因素，可以重新发送此条消息。
    if (webSocket) {
        switch (webSocket.readyState) {
            //CONNECTING：值为0，表示正在连接。
            case webSocket.CONNECTING:

                console.log('正在连接。。。');
                callback(webSocket.readyState, agentData)
                // webSocketSend(agentData, callback);
                break;
            //OPEN：值为1，表示连接成功，可以通信了。
            // callback(webSocket.readyState, agentData)

            case webSocket.OPEN:
                console.log('连接成功');
                webSocketSend(agentData, callback(webSocket.readyState, agentData));
                break;
            //CLOSING：值为2，表示连接正在关闭。
            case webSocket.CLOSING:
                console.log('连接关闭中');
                // webSocketSend(agentData, callback);
                callback(webSocket.readyState, agentData)
                break;
            //CLOSED：值为3，表示连接已经关闭，或者打开连接失败。
            case webSocket.CLOSED:
                console.log('连接关闭/打开失败');
                // do something
                // reconnect()
                initWebSocket()

                callback(3, agentData)
                // reconnectionFun()
                break;
            default:
                // this never happens
                break;
        }
    } else {
        console.log("没连接,重连");
        initWebSocket()

        callback(3, agentData)
    }
}

//将初始化socket函数、发送（接收）数据的函数、关闭连接的函数export出去
export default {
    initWebSocket,
    webSocketClose,
    sendSock
};
<template>
  <el-dialog :visible.sync="detailshow" :before-close="handleClose" custom-class="detail" fullscreen append-to-body>
    <!-- 素材 -->
    <template v-if="detailType == 1">
      <Picture :newPicture="newPicture" />
    </template>
    <!-- 模型 -->
    <template v-if="detailType == 2">
      <Model :newModel="newModel" @closeModel="closeModel" />
    </template>
    <!-- 视频 -->
    <template v-if="detailType == 3"><Video /></template>

    <template v-if="detailType == 4">
      <Audio :newAudio="newAudio" />
    </template>
    <div class="close" @click="handleClose">
      <i class="el-icon-close"></i>
    </div>
  </el-dialog>
</template>
<!-- AudiSuccess -->
<script>
import Picture from "@/components/Detail/Picture";
import Video from "@/components/Detail/Video";
import Model from "@/components/Detail/Model";
import Audio from "@/components/Detail/Audio";

export default {
  props: {
    // 模型信息
    ModlSuccess: {
      type: "",
      default: false,
    },
    //  图片信息
    PictureSuccess: {
      type: "",
      default: false,
    },
    // 音频信息
    AudiSuccess: {
      type: "",
      default: false,
    },

    // 展示
    detailshow: {
      type: Boolean,
      default: false,
    },
    // 详情ID
    detailId: {
      type: Number,
      // required: true,
      default: 0,
    },
    // 详情类型
    detailType: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  data() {
    return {
      newPicture: "1111",
      newModel: "111",
      newAudio: '111'
    };
  },
  methods: {
    closeModel() {
      // console.log(111);
      // this.handleClose()
      this.$emit("closeModele", false);
    },
    handleClose() {
      // console.log(3415);
      this.$emit("closeDetailDialog", false);
      // this.$router.push({ name: "home" });
    },
  },
  watch: {
    deep: true,
    immediate: true,
    PictureSuccess(newVal) {
      if (this.detailType == 1) {
        this.newPicture = newVal;
        console.log(123456789, newVal);
      }
    },
    ModlSuccess(newVal) {
      // console.log(newVal,111);
      if (this.detailType == 2) {
        this.newModel = newVal;
        // console.log(newVal,345);
      }
    },
    AudiSuccess(newVal) {
      console.log(newVal,377);
      if (this.detailType == 4) {
        this.newAudio = newVal;
        // console.log(newVal,345);
      }
    }
  },
  components: {
    Picture,
    Video,
    Model,
    Audio
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>

<template>
  <div>
    <el-drawer
      title="音频"
      :visible.sync="showImagModel"
      :direction="direction"
      :before-close="beforeClose"
      append-to-body
    >
      <el-form
        :model="uploadForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm form_box"
        style="padding: 20px 20px 90px"
        label-position="top"
      >
        <el-form-item label="上传音乐封面" prop="fileUrl">
          <el-upload
            class="upload-demo"
            drag
            multiple
            :file-list="fileList"
            name="Image"
            :action="url"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            :on-success="handleBgImageSuccess"
            :limit="1"
            ref="mYupload"
            :on-preview="handlePreview"
            list-type="picture"
          >
            <div><i class="el-icon-plus"></i>添加素材</div>
          </el-upload>
          <!-- 预览图片 -->
          <el-dialog
            append-to-body
            title="预览"
            :visible.sync="dialogVisible"
            width="50%"
            custom-class="dialogPreviewImg"
          >
            <span>
              <p>
                <img class="imsg" :src="dialogUrl" alt="" />
              </p>
            </span>
          </el-dialog>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="uploadForm.title"
            placeholder="请输入标题"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="请选择题材分类" prop="tags">
          <el-checkbox-group
            v-model="uploadForm.tags"
            size="medium"
            :max="3"
            fill="#e35d44"
          >
            <el-checkbox-button
              v-for="(item, index) in tagList"
              :key="index"
              :label="item.id"
              >{{ item.tagName }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="谁可以看" prop="permission">
          <el-radio
            fill="#e35d44"
            v-for="(item, index) in astrictList"
            :key="index"
            v-model="uploadForm.isopen"
            :label="item.value"
            >{{ item.name }}</el-radio
          >
        </el-form-item>
        <el-form-item label="授权信息" prop="accreditType">
          <el-select
            v-model="accreditStr"
            placeholder="请选择"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in accreditArr"
              :key="item.value"
              :label="item.label"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="内容简介" prop="content">
          <el-input
            type="textarea"
            v-model="uploadForm.content"
            placeholder="请填写简介"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item class="use_price" label="使用价格" prop="cut">
      <el-radio fill="#e35d44" v-for="(item, index) in CutList" :key="index" v-model="uploadForm.cut"
        style="color: #ffffff" :label="item.cutValue">{{ item.cutName }}</el-radio>
      <el-input v-model="uploadForm.cut" placeholder="堆币" clearable size="small" class="price_inp"></el-input>
    </el-form-item> -->
      </el-form>
      <div class="handle_box">
        <div class="hand_btn cancel" @click="beforeClose">取消</div>
        <div class="hand_btn confirm" @click="Submit()">提交素材</div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { textB, textA, textC } from "@/utils/index";
export default {
  props: {
    activeType: String,
    AudioSuccsess: "",
    showImagModel: "",
  },
  data() {
    return {
      fileList: [],
      dialogVisible: false,
      dialogVisiblePreview: false,
      direction: "rtl",
      flag: 0,
      cateid: "3",
      accreditArr: "", //授权信息

      dialogUrl: "",
      uploadForm: {
        // sucaiID: "", //图片Id
        // fileUrl: "", //素材文件
        // title: "", //标题
        // tags: [], //题材标签
        // strTags: "", //题材标签字符串
        // permission: "", //权限
        // content: "", //内容简介
        // cut: "", //0 普通贴 1 付费贴 2 堆币贴 使用价格
        // price: "", //堆币数数量
        // basemodel: "", // 基础模型
        // modeltype: "", //模型类型：
        // modelversion: "", //模型版本号：
        // relevancegp: "", //关联我的群聊：【群id】
      },
      checkedTagList: [],
      rules: {
        // 文件类型
        fileUrl: [
          {
            required: true,
            message: "文件类型不能为空",
            trigger: ["blur", "change"],
          },
        ],
        // 标题
        title: [
          {
            required: true,
            message: "标题不能为空",
            trigger: "blur",
          },
        ],
        // // 题材标签
        tags: [
          {
            type: "array",
            required: true,
            message: "最少选择一个，最多选择三个",
            trigger: "change",
          },
        ],
        // 权限
        permission: [
          { required: true, message: "请选择权限", trigger: "change" },
        ],
        // 授权信息
        accreditType: [
          { required: true, message: "请选择一个授权信息", trigger: "change" },
        ],
        // 内容简介
        content: [
          { required: true, message: "内容简介不能为空", trigger: "blur" },
        ],
        cut: [
          {
            required: true,
            message: "请输入堆币数",
            trigger: ["blur"],
          },
        ],
        // 基础模型
        basemodel: [
          {
            required: true,
            message: "请选择基础模型",
            trigger: ["blur", "change"],
          },
        ],
        // modeltype 模型类型：
        modeltype: [
          {
            required: true,
            message: "请选择模型类型",
            trigger: ["blur", "change"],
          },
        ],
        // 模型版本号
        modelversion: [
          {
            required: true,
            message: "模型版本号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        // 关联我的群聊：【群id】
        relevancegp: [
          {
            required: true,
            message: "请选择关联群聊",
            trigger: ["blur", "change"],
          },
        ],
      },

      //   题材分类数据

      tagList: [],
      //   谁可以看数据
      astrictList: [
        {
          name: "公开",
          value: 0,
        },
        {
          name: "私有",
          value: 1,
        },
      ],
      // 使用价格
      CutList: [
        {
          cutName: "免费",
          cutValue: 0,
        },
        {
          cutName: "金额",
          cutValue: 1,
        },
        {
          cutName: "堆币",
          cutValue: 2,
        },
      ],
      //   授权数据
      accreditTypeList: [{}],
      url: "",
      ImagesListData: {},
      accreditStr: "",
      fileListImg: [],
    };
  },
  watch: {
    AudioSuccsess(newValue) {
      this.uploadForm = JSON.parse(JSON.stringify(newValue));

      this.uploadForm.tags = this.uploadForm.tags.split(",").map(Number);

      let filecover = this.ImageSrc(this.uploadForm.filecover);
      if (filecover) {
        this.fileList = [];
        // console.log(112);

        // this.dialogVisible=true
        this.fileList.push({ url: filecover.url, id: filecover.id });

        this.$set(this.fileList, 0, this.fileList[0]);
      }
      this.ShowtagList();
      this.ShowaccreditTypeList();
    },
  },
  computed: {},

  mounted() {
    this.url = this.$http.adornUrl("/app/common/upload");
  },

  methods: {
    handleRemove(e) {
      this.fileList = [];
    },

    ImageSrc(e) {
      // console.log(e,333);
      if (e) {
        if (textC(e).url) {
          return textC(e);
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    // 点击文件预览弹出框
    handlePreview(file) {
      console.log(file);
      this.dialogVisible = true;
      this.dialogUrl = this.fileList[0].url;
      // this.$alert('<img src="' + file.url + '" style="width:100%" />', '预览图片');
    },
    // 上传封面
    handleBgImageSuccess(response, file, fileList) {
      console.log(31774);

      this.fileList[0] = { url: response.result, id: response.id };
    },
    beforeUpload(file) {
      // type: "video/mp4" "audio/mpeg"  "image/png" "image/jpeg" "image/webp" || "audio/ogg"
      // let isVideo = file.type === "video/mp4";
      // let isAudio = file.type === "audio/mpeg";
      let isJPG = file.type === "image/jpeg";
      let isPng = file.type === "image/png";
      let iswebp = file.type === "image/webp";
      // 1图片、2视频、3音频、4文字
      if (this.cateid == 3 && !isJPG && !isPng && !iswebp) {
        this.$message({
          message: "当前格式不支持",
          type: "warning",
        });
        return isJPG && isPng && iswebp;
      }
    },

    beforeClose() {
      // console.log(12);
      this.$emit("beforeClose", false);
    },

    // 显示所有的授权信息
    ShowaccreditTypeList() {
      this.$http({
        url: this.$http.adornUrl("/app/material/getAccreditType"),
        method: "get",
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.accreditStr = textB(
            data.accreditType,
            this.uploadForm.accreditType
          );
          console.log(this.accreditStr, 3678);
          this.accreditArr = textA(data.accreditType);
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    // 显示所有的题材分类
    ShowtagList() {
      this.$http({
        url: this.$http.adornUrl("/app/post/tagPost"),
        method: "get",
        params: this.$http.adornParams({
          // 题材分类id
          cateid: 3,
        }),
      }).then((data) => {
        if (data.data.code === 0) {
          this.tagList = data.data.tagList;
          // console.log("题材分类图片", this.tagList);
        }
      });
    },
    Submit() {
      // console.log("提交", this.accreditStr, this.accreditStr.value);
      // return;
      if (this.uploadForm.title == "") {
        this.$message.error("请输入标题");
        return;
      } else if (this.accreditStr == "") {
        this.$message.error("请选择授权信息");
        return;
      } else if (this.uploadForm.content == "") {
        this.$message.error("内容不能为空");
        return;
      }

      // console.log(accreditType);
      let data = {
        id: this.uploadForm.id,
        // 配合比赛
        isforcepe: 0,
        //素材作者
        author: JSON.parse(localStorage.getItem("User")).username,
        //题材分类
        cateid: 3,
        filepath: "",
        //是否免费：0.免费 1.付费
        isfree: 0,
        //权限：0.公开 1.私有
        isopen: this.uploadForm.isopen,
        //素材标题
        title: this.uploadForm.title,
        //素材价格
        price: 0,
        //题材标签
        // tags: JSON.stringify(tags),
        tags: this.uploadForm.tags.join(","),
        // 素材类型：1图片、2文字、3视频、4音频、5模型
        type: 4,
        uid: JSON.parse(localStorage.getItem("User")).uid,
        content: this.uploadForm.content,
        // 时长
        duration: this.uploadForm.duration,

        //封面
        filecover: [
          {
            baseId: "", //文件关联业务id
            baseType: "", //文件种类
            createDate: "", //创建时间
            fileType: 4, //文件类型：1图片、2文字、3视频、4音频
            id: this.fileList[0].id, //id
            url: this.fileList[0].url,
          },
          //文件路径
        ],

        //授权类型
        accreditType: this.accreditStr.value,
        ossEntities: [
          {
            baseId: "", //文件关联业务id
            baseType: "", //文件种类
            createDate: "", //创建时间
            fileType: 1, //文件类型：1图片、2文字、3视频、4音频
            id: this.uploadForm.sysOssEntities[0].id, //id
            url: this.uploadForm.sysOssEntities[0].url,
          },
          //文件路径
        ], //文件

        //基础模型
        basemodel: "",
        //模型类型
        modeltype: "",
        //模型版本号
        modelversion: "",
        //关联我的群聊
        relevancegp: "",
      };

      this.$http({
        url: this.$http.adornUrl("/app/material/update"),
        method: "post",
        data: this.$http.adornData(data),
      }).then((data) => {
        // console.log(1223);
        if (data.data.code === 0) {
          // console.log(data,1232);
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.$router.push({ name: "home" });
          location.reload();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../index";

::v-deep .el-upload-list__item {
  transition: none !important;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import $http from '@/utils/httpRequest';
import { Message } from 'element-ui'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    // 更新的聊天消息
    websocketMsg: '',
    // // 私聊好友 -最后一条消息
    privateChat: [],
    // 选中的私聊好友
    activePrivate: {},
    // 当前选中私聊好友的聊天记录
    activePrivateMsg: [],

    // 私聊记录分页数据
    personMSgSorter: {
      pageNum: 1,
      pageSize: 10,
      recordTotal: 0
    },
    // 网站当前登录用户信息
    userInfo: JSON.parse(localStorage.getItem('User')) || null,
    // aiImg新消息
    // msgAiImg: ''
  },
  getters: {
    // 私聊条数
    unread: state => 0,
    // 消息数据
    wsMsg: state => state.websocketMsg,
    // aiImg新消息
    // wsMsgAiImg: state => state.msgAiImg,
    //私聊-----------------------------------
    // 好友
    privateChat: state => state.privateChat,
    // 记录
    activePrivateMsg: state => state.activePrivateMsg,
    // 选中
    activePrivate: state => state.activePrivate,
    personMSgSorter: state => state.personMSgSorter,
    //私聊-----------------------------------
    userInfo: state => state.userInfo
  },
  mutations: {
    //设置当前用户信息
    SET_USERINFO(state, newData) {
      state.userInfo = newData
    },
    // 设置聊天消息
    SET_WS_MSG: (state, msg) => {
      state.websocketMsg = msg
    },
    // aiImg新消息
    // SET_WS_MSG_AI_IMG: (state, msg) => {
    //   state.msgAiImg = msg
    // },
    // 设置私聊好友
    SET_ALL_PERSON: (state, list) => {
      state.privateChat = list
      // console.log('设置好友', list);
    },
    // 设置选中的私聊好友
    SET_ACTIVE_PERSON: (state, person) => {
      state.activePrivate = person
    },
    //设置当前选中好友的聊天记录
    SET_ACTIVE_PERSON_MSG: (state, list) => {
      state.activePrivateMsg = list
    },
    // 设置聊天分页
    SET_MSG_PAGE: (state, data) => {
      console.log('设置聊天分页', data);
      state.personMSgSorter.pageNum = data.page
      state.personMSgSorter.pageSize = data.size
      state.personMSgSorter.recordTotal = data.total
    }
  },
  actions: {
    // websocket新消息触发
    receiveMsgFun({ commit, dispatch, state }, msg) {
      // 私聊
      if (msg.type == "person-message") {
        dispatch('getAllFriendLastMsg');//刷新最后一条
        if (state.activePrivate.sessionId == msg.data.sessionId) {
          dispatch('addPersonMsgList', msg.data)
        }
        commit('SET_WS_MSG', msg)
      }
      else if (msg.type == "group-message") {
        // localStorage.removeItem('aiImgUrl2')
        commit('SET_WS_MSG', msg)
        // if (msg.data.content == "" && msg.data.messageType == "img" && msg.data.senderId == "6") {
        //   console.log(msg, 'Ai空消息');
        //   localStorage.setItem('aiImgUrl', JSON.stringify(msg))
        // }
      } else {
        commit('SET_WS_MSG', msg)
      }
      // else if (msg.type == "end-load-message") {
      //   // if (JSON.parse(localStorage.getItem("aiImgUrl2")) != null) {
      //   //   localStorage.removeItem('aiImgUrl2')
      //   // }
      //   localStorage.setItem('aiImgUrl2', JSON.stringify(msg))
      //   console.log(msg, "Ai图片完成");
      //   // commit('SET_WS_MSG_AI_IMG', msg)
      // }
    },
    // 获取所有的私聊好友
    getAllFriend({ commit, state, dispatch }) {
      $http({
        url: $http.adornUrl("/app/friend/list"),
        method: "get",
      }).then(({ data }) => {
        if (data.code == 0) {
          data.data.forEach(item => {
            let sessionId =
              Math.min(state.userInfo.uid, item.friend_id).toString() +
              Math.max(state.userInfo.uid, item.friend_id).toString();
            item.sessionId = '-' + sessionId  //给每个用户增加sessionid的值  
          });
          // 将机器人移到最上面
          let robot = data.data.filter(item => item.id == 0)[0]
          let index = data.data.indexOf(robot)
          robot.friend_name = state.userInfo.username + '的Ai助手'
          if (index != -1) {
            data.data.splice(index, 1)
            data.data.unshift(robot)
          }
          // 暂时设置机器人默认选中
          dispatch('setACtivePreson', data.data.filter((item) => item.id == 0)[0])
          dispatch("getAllFriendLastMsg")
          commit('SET_ALL_PERSON', data.data)  //初始化所有好友
        } else {
          Message.error(data.msg)
        }
      });
    },
    // 获取好友的最后条消息
    getAllFriendLastMsg({ commit, state }) {
      $http({
        url: $http.adornUrl("/app/friend/GetImUidList"),
        method: "get",
      }).then(({ data }) => {
        if (data.code == 0) {
          let arr = [...state.privateChat]
          arr.forEach(item => {
            data.imuidData.forEach(ite => {
              if (item.sessionId == ite.session_id) {
                if (ite.message_type == "share") {
                  ite.content = "【分享】"
                }
                if (ite.message_type == "img" || ite.message_type == "imgfile") {
                  ite.content = "【图片】"
                }
                item.lastMsgData = ite
              }
            })
          })
          commit('SET_ALL_PERSON', arr)
        } else {
          Message.error(data.msg)
        }
      });
    },
    // 设置选中好友
    setACtivePreson({ commit, dispatch, state }, person) {
      commit("SET_MSG_PAGE", { page: 1, size: 10, total: 0 })
      commit('SET_ACTIVE_PERSON', person)  //设置数据
      commit('SET_ACTIVE_PERSON_MSG', [])
      dispatch('getAllRecordList', person)  //获取用户聊天记录
    },
    // 根据私聊ID设置私发好友
    setPersonFriend({ commit, dispatch, state }, id) {
      let arr = [...state.privateChat]
      let person = arr.filter(item => item.friend_id == id)[0]
      dispatch('setACtivePreson', person)
    },

    // 设置聊天记录分页
    setPersonMSgSorter({ commit, state, dispatch }, { page, size, total }) {
      commit('SET_MSG_PAGE', { page, size, total })
      console.log(page, size, total, "函数数据");
    },
    //获取单个好友的历史记录
    getAllRecordList({ commit, state, dispatch }, person) {
      $http({
        // url: $http.adornUrl("/app/chat/list"), // /app/chat/list  /app/chat/getChatMgAllList
        url: $http.adornUrl("/app/chat/getChatMgAllList"), // /app/chat/list  /app/chat/getChatMgAllList
        method: "post",
        data: $http.adornData({
          pageNum: state.personMSgSorter.pageNum,
          pageSize: state.personMSgSorter.pageSize,
          sessionId: person ? person.sessionId : '',
        }),
      }).then(({ data }) => {
        if (data.code == 0) {
          data.result.data.forEach(item => {
            if (item.senderId == 6) {
              item.senderName = state.userInfo.username + '的Ai助手'
            }
          })
          let arr = [...state.activePrivateMsg]
          data.result.data.forEach((item) => {
            arr.unshift(item);

          });
          dispatch("setPersonMSgSorter", { page: state.personMSgSorter.pageNum, size: state.personMSgSorter.pageSize, total: data.result.total })
          commit('SET_ACTIVE_PERSON_MSG', arr)

        } else {
          Message.error(data.msg)
        }
        // console.log(data, "记录msg");
      });
    },

    // 添加私聊聊天记录
    addPersonMsgList({ commit, state }, val) {
      const newItems = [...state.activePrivateMsg]
      newItems.push(val)
      commit('SET_ACTIVE_PERSON_MSG', newItems)
    },
    // 获取当前登录用户信息
    getUserINfo({ commit }) {
      const userInfo = localStorage.getItem('User')
      if (userInfo) {
        commit('SET_USERINFO', JSON.parse(User)) // 如果localStorage中有值，则解析并更新state中的值  
      } else {
        commit('SET_USERINFO', null) // 如果localStorage中没有值，则将state中的值设为null  
      }
    }
  },
  modules: {
  }
})

<template>
  <!-- 分享到群聊 -->
  <el-dialog
    title="选择聊天"
    :visible.sync="sharToGroupShow"
    width="50%"
    :before-close="handleClose"
    append-to-body
    custom-class="send_group"
  >
    <div class="send_data_main">
      <div class="search">
        <el-input v-model="searchVal" placeholder="搜索"></el-input>
      </div>
      <template v-if="allDataList.length">
        <el-checkbox-group class="group_list" v-model="checkList">
          <el-checkbox
            v-for="(item, index) in allDataList"
            :label="item"
            :key="index"
          >
            <div class="group_item">
              <div class="group_item_img">
                <img
                  :src="item.isGroup ? item.media : item.avatar"
                  alt="头像"
                />
              </div>
              <div class="group_item_info">
                <div>
                  {{ item.isGroup ? item.groupname : item.friendName }}
                  <span
                    class="item_type"
                    :class="item.isGroup ? 'is_group' : 'is_person'"
                    >{{ item.isGroup ? "群组" : "朋友" }}</span
                  >
                </div>
                <div class="info_jj" v-if="0">
                  简介啊实打实大苏打SAD阿斯顿阿萨简介啊实打实大苏打SAD阿斯顿阿萨简介啊实打实大苏打SAD阿斯顿阿萨简介啊实打实大苏打SAD阿斯顿阿萨
                </div>
              </div>
            </div>
          </el-checkbox>
        </el-checkbox-group>
      </template>
      <template v-else><div class="empty">暂无可分享好友或群聊</div></template>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="cancel" @click="handleClose">取 消</el-button>
      <el-button class="confirm" @click="ConfirmClick">发 送</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { sendSock } from "@/utils/ws.js";

export default {
  props: {
    // 素材内容
    materialData: {
      type: Object,
      default: () => {},
    },
    sharToGroupShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allDataList: [], //所有用户
      checkList: [], //选中的
      searchVal: "", //搜索的值
    };
  },
  created() {
    this.getAllGroupAndPersonData();
  },
  mounted() {
    console.log(this.materialData, "999877");
  },
  methods: {
    // 确认分享
    ConfirmClick() {
      console.log(this.checkList);
      //给分享的数据加上类型
      // this.materialData.shareType = this.shareType;
      this.checkList.forEach((item) => {
        // 群聊
        try {
          if (item.isGroup) {
            let time = new Date().getTime();
            let sendGroup = {
              type: "group-message",
              data: {
                sessionId: item.id,
                senderId: this.userInfo.uid,
                senderName: this.userInfo.username,
                senderAvatar: this.userInfo.avatar,
                receiverId: item.id,
                receiverName: item.groupname,
                receiverAvatar: item.media,
                sendTime: time,
                content: this.materialData,
                messageType: "share",
              },
            };
            console.log(sendGroup, "99");
            sendSock(sendGroup);
            this.$message.success("分享成功");
            this.handleClose();
          } else {
            // 私聊
            let time = new Date().getTime();
            let sessionId =
              Math.min(this.userInfo.uid, item.friendId).toString() +
              Math.max(this.userInfo.uid, item.friendId).toString();
            let sendParams = {
              type: "person-message",
              data: {
                sessionId: "-" + sessionId,
                senderId: this.userInfo.uid,
                senderName: this.userInfo.username,
                senderAvatar: this.userInfo.avatar,
                receiverId: item.friendId,
                receiverName:
                  item.notation == "" ||
                  item.notation == null ||
                  item.notation == undefined
                    ? item.friendName
                    : item.notation,
                receiverAvatar: item.avatar,
                sendTime: time,
                content: this.materialData,
                messageType: "share",
              },
            };
            console.log(sendParams, 66);
            sendSock(sendParams);
            this.$message.success("分享成功");
            this.handleClose();
          }
        } catch (error) {
          this.$message.error("分享出错咯~");
        }
      });
    },

    // 获取所有群聊和私聊信息z
    getAllGroupAndPersonData() {
      this.$http({
        url: this.$http.adornUrl("/app/friend/GetUidGroupList"),
        method: "get",
      }).then(({ data }) => {
        console.log(data);
        if (data.code == 0) {
          // 用户自创群组
          data.groupData.forEach((item) => {
            item.isGroup = true;
          });
          // 用加入的群组
          data.groupPersonData.forEach((item) => {
            item.isGroup = true;
          });
          // 用户的朋友
          data.uidData.forEach((item) => {
            item.isGroup = false;
          });
          let friendLisst = data.uidData.filter((item) => item.friendId != 6);
          this.allDataList = [
            ...friendLisst,
            ...data.groupData,
            ...data.groupPersonData,
          ];
          console.log(this.allDataList, 999);
        } else {
          this.allDataList = [];
          this.$message.error(data.msg);
        }
      });
    },

    handleClose() {
      this.$emit("sendTopGroupFun", false);
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
};
</script>

<style lang="scss" scoped>
::v-deep .send_group {
  .el-dialog__footer {
    background: var(--secondary);
    border-top: 1px solid #4b4b4b;
  }
  .cancel {
    background-color: var(--grey);
    border: 0;
  }
  .confirm {
    background: var(--redary);
    border: 0;
  }
  .send_data_main {
    min-height: 40vh;
    max-height: 60vh;
    overflow-y: auto;
    padding: 0 20px 20px;
    .search {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      padding: 20px 0;
      background: var(--secondary);
      z-index: 99;
      .el-input__inner {
        background: var(--blackary);
        border: 0;
        color: #fff;
      }
    }
    .group_list {
      display: flex;
      flex-direction: column;

      .el-checkbox {
        display: flex;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid #4e4e4e;
        margin-right: 0;
        &:hover {
          background-color: var(--blackary);
        }
        &:last-of-type {
          border-bottom: 0;
        }
      }
      .el-checkbox__inner {
        background: var(--secondary);
      }
      .el-checkbox__label {
        width: 100%;
      }
      .el-checkbox__input.is-checked .el-checkbox__inner,
      .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: var(--redary);
        border-color: var(--redary);
      }
      .group_item {
        display: flex;
        align-items: center;
        position: relative;

        .group_item_img {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          overflow: hidden;
          position: relative;

          img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .group_item_info {
          flex: 1;
          padding: 5px 10px;
          .item_type {
            margin-left: 20px;
            border: 1px solid;
            padding: 4px 10px;
            border-radius: 5px;
            font-size: 12px;
            &.is_group {
              color: #f9a839;
            }
            &.is_person {
              color: var(--redary);
            }
          }
          .info_jj {
            color: #8f8f8f;
            margin-top: 5px;
            width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>

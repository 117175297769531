<template>
  <div>
    <el-drawer
      title="模型"
      :visible.sync="showImagModel"
      :direction="direction"
      :before-close="beforeClose"
      append-to-body
    >
      <el-form
        :model="uploadForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        style="padding: 20px 20px 90px"
        class="demo-ruleForm form_box"
        label-position="top"
      >
        <el-form-item label="模型名称" prop="title">
          <el-input
            v-model="uploadForm.title"
            placeholder="模型名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="基础模型" prop="basemodel">
          <!-- <el-cascader :options="accreditTypeList" clearable></el-cascader> -->
          <el-select
            v-model="uploadForm.basemodel"
            placeholder="请选择"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in basicModelArr"
              :key="item.value"
              :label="item.label"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模型类型" prop="modeltype">
          <el-select
            v-model="uploadForm.modeltype"
            placeholder="请选择"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in TypeModelArr"
              :key="item.value"
              :label="item.label"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="请输入版本号 " prop="modelversion">
          <el-input
            v-model="uploadForm.modelversion"
            placeholder="请输入版本号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="请选择题材分类" prop="tags">
          <el-checkbox-group
            v-model="uploadForm.tags"
            size="medium"
            :max="3"
            fill="#e35d44"
          >
            <el-checkbox-button
              v-for="(item, index) in tagList"
              :key="index"
              :label="item.id"
              >{{ item.tagName }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="谁可以看" prop="permission">
          <el-radio
            fill="#e35d44"
            v-for="(item, index) in astrictList"
            :key="index"
            v-model="uploadForm.isopen"
            :label="item.value"
            >{{ item.name }}</el-radio
          >
        </el-form-item>
        <el-form-item label="授权信息" prop="accreditType">
          <el-select
            v-model="accreditStr"
            placeholder="请选择"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in accreditArr"
              :key="item.value"
              :label="item.label"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="内容简介" prop="content">
          <el-input
            type="textarea"
            v-model="uploadForm.content"
            placeholder="请填写简介"
          ></el-input>
        </el-form-item>
        <el-form-item label="关联我的群聊" class="relevance-group">
          <el-collapse class="my-collapse">
            <el-collapse-item title="请选择群聊" name="1">
              <div class="collapse-content">
                <el-radio-group v-model="gpresponse">
                  <div
                    class="text"
                    v-for="(item, index) in relevanceGroup"
                    :key="index"
                  >
                    <div class="imgs">
                      <img class="imgs" :src="item.media" alt="" />
                      <span> {{ item.groupname }}</span>
                    </div>

                    <div class="radio">
                      <el-radio
                        :label="item.id"
                        @click.native.prevent="radioClick(item.id)"
                        >{{ item.value }}</el-radio
                      >
                    </div>
                  </div>
                </el-radio-group>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-form-item>
        <!-- 关联样图 -->
        <div @click="dialogSampFun()">
          <el-form-item
            label="关联样图"
            prop="accreditType"
            class="relevance-group master-drawing"
          >
            <div class="relevance-box">
              <p>已关联（{{ relevanceImgsList.length }}/5）</p>
              <ul>
                <li class="relevance-li">
                  <div
                    class="imgs"
                    v-for="(item, index) in relevanceImgsList"
                    :key="index"
                  >
                    <img :src="item" alt="" />
                  </div>
                </li>
              </ul>
            </div>
          </el-form-item>
        </div>

        <!-- <el-form-item class="use_price" label="使用价格" prop="cut">
      <el-radio fill="#e35d44" v-for="(item, index) in CutList" :key="index" v-model="uploadForm.cut"
        style="color: #ffffff" :label="item.cutValue">{{ item.cutName }}</el-radio>
      <el-input v-model="uploadForm.cut" placeholder="堆币" clearable size="small" class="price_inp"></el-input>
    </el-form-item> -->
      </el-form>
      <div class="handle_box">
        <div class="hand_btn cancel" @click="beforeClose">取消</div>
        <div class="hand_btn confirm" @click="Submit()">提交素材</div>
      </div>
      <dialog-sample
        ref="dialogSample"
        v-show="dialogSampleFlg == true"
        :relevanceImgsList="relevanceImgsList"
        @ShowImgListUrl="ShowImgListUrl"
      ></dialog-sample>
    </el-drawer>
  </div>
</template>

<script>
import { textA, textB } from "@/utils/index";
import dialogSample from "./dialog";
export default {
  components: {
    dialogSample,
  },
  props: {
    activeType: String,
    ModelSuccsess: "",
    showImagModel: "",
  },
  data() {
    return {
      gpresponse: "",
      radioId: "",
      dialogVisiblePreview: false,
      direction: "rtl",
      accreditStr: "",
      accreditArr: "", //授权信息
      dialogVisible: false,
      dialogUrl: "",
      relevancegp: "", //关联我的群聊：【群id】
      uploadForm: {
        gpresponse: 0,
      },
      rules: {
        // 文件类型
        fileUrl: [
          {
            required: true,
            message: "文件类型不能为空",
            trigger: ["blur", "change"],
          },
        ],
        // 标题
        title: [
          {
            required: true,
            message: "标题不能为空",
            trigger: "blur",
          },
        ],
        // // 题材标签
        tags: [
          {
            type: "array",
            required: true,
            message: "最少选择一个，最多选择三个",
            trigger: "change",
          },
        ],
        // 权限
        permission: [
          { required: true, message: "请选择权限", trigger: "change" },
        ],
        // 授权信息
        accreditType: [
          { required: true, message: "请选择一个授权信息", trigger: "change" },
        ],
        // 内容简介
        content: [
          { required: true, message: "内容简介不能为空", trigger: "blur" },
        ],
        cut: [
          {
            required: true,
            message: "请输入堆币数",
            trigger: ["blur"],
          },
        ],
        // 基础模型
        basemodel: [
          {
            required: true,
            message: "请选择基础模型",
            trigger: ["blur", "change"],
          },
        ],
        // modeltype 模型类型：
        modeltype: [
          {
            required: true,
            message: "请选择模型类型",
            trigger: ["blur", "change"],
          },
        ],
        // 模型版本号
        modelversion: [
          {
            required: true,
            message: "模型版本号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        // 关联我的群聊：【群id】
        relevancegp: [
          {
            required: true,
            message: "请选择关联群聊",
            trigger: ["blur", "change"],
          },
        ],
      },

      //   题材分类数据

      tagList: [],
      //   谁可以看数据
      astrictList: [
        {
          name: "公开",
          value: 0,
        },
        {
          name: "私有",
          value: 1,
        },
      ],
      // 使用价格
      CutList: [
        {
          cutName: "免费",
          cutValue: 0,
        },
        {
          cutName: "金额",
          cutValue: 1,
        },
        {
          cutName: "堆币",
          cutValue: 2,
        },
      ],
      //   授权数据
      accreditTypeList: [{}],
      url: "",
      ModelsListData: {},
      fileListImg: [],
      basicModelArr: "", //基础模型
      TypeModelArr: "", //模型类型
      // 关联群组
      relevanceGroup: [],
      dialogSampleFlg: false, //关联样图
      // 已关联图片
      relevanceImgsList: [],
      nums: "",
      radio: "",
    };
  },
  watch: {
    ModelSuccsess(newValue) {
      this.uploadForm = JSON.parse(JSON.stringify(newValue));
      this.uploadForm.tags = this.uploadForm.tags.split(",").map(Number);
      //  console.log( this.uploadForm,345);
      if (this.uploadForm.gpresponse) {
        this.gpresponse = this.uploadForm.gpresponse.id;
        console.log(this.gpresponse, 2388);
      }
      this.ShowaccreditTypeList(); //显示所有的授权信息
      this.foundationModel(); //查询基础模型
      this.TypeModel(); //模型类型
      this.ShowRelevanceGroup(); //关联群聊
      this.nums = this.uploadForm.assgraph.split(",").length;
      this.relevanceImgsList = this.uploadForm.assgraph.split(",");
      // console.log( this.uploadForm);
      // relevanceImgsList
      // console.log("模型--", this.uploadForm, this.uploadForm.tags);
    },
  },
  computed: {},
  mounted() {
    this.ShowtagList(); //题材分类
    // 接收关联样图

    // console.log(this.uploadForm.gpresponse.id,3334);
  },

  methods: {
    radioClick(e) {
      // console.log(typeof this.radio)
      e === this.gpresponse ? (this.gpresponse = "") : (this.gpresponse = e);
    },
    ShowImgListUrl(e) {
      // console.log(e);
      this.relevanceImgsList = e;
    },

    beforeClose() {
      // console.log(12);
      this.$emit("beforeClose", false);
    },
    // 关联样图
    dialogSampFun() {
      // console.log("点击了");
      this.dialogSampleFlg = true;
      this.$nextTick(() => {
        this.$refs.dialogSample.init(1);
      });
    },
    // 显示所有的授权信息
    ShowaccreditTypeList() {
      this.$http({
        url: this.$http.adornUrl("/app/material/getAccreditType"),
        method: "get",
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.accreditStr = textB(
            data.accreditType,
            this.uploadForm.accreditType
          );
          this.accreditArr = textA(data.accreditType);
          console.log(this.accreditArr);
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    // 显示所有的题材分类
    ShowtagList() {
      this.$http({
        url: this.$http.adornUrl("/app/post/tagPost"),
        method: "get",
        params: this.$http.adornParams({
          // 题材分类id
          cateid: 5,
        }),
      }).then((data) => {
        if (data.data.code === 0) {
          this.tagList = data.data.tagList;
          // console.log("题材分类模型", this.tagList);
        }
      });
    },
    //  查询基础模型
    foundationModel() {
      this.$http({
        url: this.$http.adornUrl("/app/material/getCommonConfig"),
        method: "get",
        params: this.$http.adornParams({
          key: "pc_basic_model",
        }),
      }).then((data) => {
        //  this. uploadForm.basemodel
        this.uploadForm.basemodel = textB(
          data.data.value,
          this.uploadForm.basemodel
        );
        this.basicModelArr = textA(data.data.value);
        // console.log(9999, this.basicModelArr);
      });
    },
    //模型类型
    TypeModel() {
      this.$http({
        url: this.$http.adornUrl("/app/material/getCommonConfig"),
        method: "get",
        params: this.$http.adornParams({
          key: "pc_model_type",
        }),
      }).then((data) => {
        this.uploadForm.modeltype = textB(
          data.data.value,
          this.uploadForm.modeltype
        );
        this.TypeModelArr = textA(data.data.value);
        // console.log(this.TypeModelArr, 33);
      });
    },
    //  关联群聊
    ShowRelevanceGroup() {
      this.$http({
        url: this.$http.adornUrl("/app/friend/GetZlUidGroupList"),
        method: "get",
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data, 333);
          if (data.groupData.length == 0) {
            this.relevanceGroup = [];
          } else {
            this.relevanceGroup = data.groupData;
          }
        }
      });
    },
    Submit() {
      if (this.gpresponse == "") {
        this.gpresponse = 0;
      }
      //  console.log(this.accreditStr);

      //       return
      // console.log(this.relevanceImgsList);
      // return
      // console.log("提交", this.uploadForm.assgraph.split(","));
      // console.log(this.uploadForm);
      // return
      if (this.uploadForm.title == "") {
        this.$message.error("请输入标题");
        return;
      } else if (this.uploadForm.content == "") {
        this.$message.error("内容不能为空");
        return;
      } else if (this.uploadForm.accreditType == "") {
        this.$message.error("授权类型不能为空");
        return;
      } else if (this.uploadForm.modeltype == "") {
        this.$message.error("模型类型不能为空");
        return;
      } else if (this.relevanceImgsList.length == 0) {
        this.$message.error("请至少选择一张关联样图");
        return;
      }

      // console.log(accreditType);
      let data = {
        id: this.uploadForm.id,
        // 配合比赛

        //素材作者
        author: JSON.parse(localStorage.getItem("User")).username,
        //题材分类
        cateid: 5,
        filepath: "",
        //是否免费：0.免费 1.付费
        isfree: 0,
        //权限：0.公开 1.私有
        isopen: this.uploadForm.isopen,
        //素材标题
        title: this.uploadForm.title,
        //素材价格
        price: 0,
        //题材标签
        // tags: JSON.stringify(tags),
        tags: this.uploadForm.tags.join(","),
        // 素材类型：1图片、2文字、3视频、4音频、5模型
        type: 5,
        uid: JSON.parse(localStorage.getItem("User")).uid,
        content: this.uploadForm.content,
        // 时长
        duration: "",
        //授权类型
        // ?accreditType: this.accreditStr.value,
        ossEntities: [
          {
            baseId: "", //文件关联业务id
            baseType: "", //文件种类
            createDate: "", //创建时间
            fileType: 1, //文件类型：1图片、2文字、3视频、4音频
            id: 0, //id
            url: this.uploadForm.sysOssEntities[0].url,
          },
          //文件路径
        ], //文件

        //基础模型
        // ?basemodel: this.uploadForm.basemodel.value,
        //模型类型
        //? modeltype: this.uploadForm.modeltype.value,
        //模型版本号
        modelversion: this.uploadForm.modelversion,
        //关联我的群聊
        relevancegp: this.gpresponse,
        // 关联图片列表
        assgraphList: this.relevanceImgsList,
      };

      this.$http({
        url: this.$http.adornUrl("/app/material/update"),
        method: "post",
        data: this.$http.adornData(data),
      }).then((data) => {
        // console.log(1223);
        if (data.data.code === 0) {
          console.log(data, 1232);
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.$router.push({ name: "home" });
          location.reload();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../index";
// 已关联样图
.relevance-box {
  ul {
    li {
      display: flex;
      flex-wrap: wrap;

      div {
        margin-right: 10px;
      }
      img {
        width: 90px;
        object-fit: cover;
      }
    }
  }
}
</style>

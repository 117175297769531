import Vue from "vue";
import router from "@/router";
import store from "@/store";
// import'@/assets/styles/global'
import ElementUI from "element-ui";
// 引入Loading组件
import { Loading } from "element-ui";

Vue.use(ElementUI);
// 创建全局的Loading实例
let loadingInstance = null;

// 显示全局加载动画
export function showGlobalLoading() {
  if (loadingInstance === null) {
    loadingInstance = Loading.service({
      text: "拼命加载中...",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
      customClass: "custom-loading",
    });
  } else {
    loadingInstance.text = "加载中";
  }
}
// 停止加载动画
export function stopLoading() {
  if (loadingInstance !== null) {
    loadingInstance.close();
    loadingInstance = null;
  }
}

/**
 * 获取uuid
 */
export function getUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    return (c === "x" ? (Math.random() * 16) | 0 : "r&0x3" | "0x8").toString(
      16
    );
  });
}

/**
 * 历史时间转换 年-月-日 时：分：秒
 * */
export function FupdateTime(times) {
  var date = new Date(times);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  var h = date.getHours();
  h = h < 10 ? "0" + h : h;
  var minute = date.getMinutes();
  minute = minute < 10 ? "0" + minute : minute;
  var s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + s;
}
// 字符串转换数组不包含前面的值
export function doesNotContain(itemNum, item) {
  let str = "";
  str = item;
  let nums = str.match(/\d+/)[0];
  if (nums == itemNum) {
    return str
      .replace(itemNum, "")
      .replace(":", "")
      .replace('"', "")
      .replace('"', "");
  }
  // return str
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
  return (
    JSON.parse(sessionStorage.getItem("permissions") || "[]").indexOf(key) !==
    -1 || false
  );
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = "id", pid = "parentId") {
  var res = [];
  var temp = {};
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i];
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]["children"]) {
        temp[data[k][pid]]["children"] = [];
      }
      if (!temp[data[k][pid]]["_level"]) {
        temp[data[k][pid]]["_level"] = 1;
      }
      data[k]["_level"] = temp[data[k][pid]]._level + 1;
      temp[data[k][pid]]["children"].push(data[k]);
    } else {
      res.push(data[k]);
    }
  }
  return res;
}

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
  // Vue.cookie.delete('token')
  // localStorage.removeItem("nums");
  // localStorage.removeItem("userid"); //userid
  localStorage.removeItem("token"); //token
  localStorage.removeItem("User")
  localStorage.removeItem("aiImgUrl");
  localStorage.removeItem("aiImgUrl2");
  location.reload();
  // store.commit('resetStore')
  router.options.isAddDynamicMenuRoutes = false
  router.push({ name: "home" });
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

export function text(data1, data2) {
  let tex;
  let str = data1;
  var str1 = str.substr(1);
  var str2 = str1.substring(0, str1.length - 1);
  str2 = str2.split(",");

  str2.forEach((element, index) => {
    let st3 = element.split("");
    // console.log(st3[0]);
    data2 = data2 + "";
    let flag = st3.indexOf(data2);

    if (flag == 0) {
      // return element;
      tex = element;
    }
  });
  tex = tex.split('"')[1];

  return tex;
}
export function textA(data1, data2) {
  let str = data1;
  var str1 = str.substr(1);
  var str2 = str1.substring(0, str1.length - 1);
  str2 = str2.split(",");
  let str3 = [];
  str2.forEach((item, index) => {
    str3.push({
      value: item.split(":")[0],
      label: item.split('"')[1],
    });
  });
  // console.log(str3, 314);
  return str3;
}
// 计算图片宽高
export function getImageSize(url) {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    if (img.complete) {
      // 如果图片被缓存，则直接返回缓存数据
      resolve({ width: img.width, height: img.height });
    } else {
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
    }
  });
}
// getImageSize(url).then(sizeInfo => {
//   const { width, height } = sizeInfo
// console.log('width, height: ', width, height);
// })

//获取二级菜单
export function shwocateListType() {
  return new Promise((resolve) => {
    Vue.prototype
      .$http({
        url: Vue.prototype.$http.adornUrl("/app/post/catePostPc"),
        method: "get",
      })
      .then(({ data }) => {
        if (data.code == 0) {
          resolve(data);
        }
      });
  });
}

//获取题材分类标签(三级)
export function getTagPost(id) {
  return new Promise((resolve) => {
    Vue.prototype
      .$http({
        url: Vue.prototype.$http.adornUrl("/app/post/tagPost"),
        method: "get",
        params: Vue.prototype.$http.adornParams({
          // 题材分类id
          cateid: id, //1我得
        }),
      })
      .then((data) => {
        if (data.data.code === 0) {
          console.log(data, 456);
          resolve(data.data);
        }
      });
  });
}
// 获取图片视频列表 (数据)
// page页数,uid用户id,type素材类型
export function getTagMsg(page, uid, tags, cateid, type) {
  return new Promise((resolve) => {
    let data = {
      page,
      uid,
      isMaterial: 0, //素材库判断：0.我的 1.素材库 2.收藏 3.喜欢 4.模型返图
      scistate: "", // 收藏状态：0.未收藏 1.已收藏 2.喜欢
      isopen: 0, //权限：0.公开 1.私有
      cateid, //素材类型：1图片、2文字、3视频、4音频
      tags, //题材分类
      type, //素材类型：1图片、2文字、3视频、4音频、5模型
      isforcepe: 1, //0否 1.是
    };
    Vue.prototype
      .$http({
        url: Vue.prototype.$http.adornUrl("/app/material/list"),
        method: "post",
        data: Vue.prototype.$http.adornData(data),
      })
      .then((data) => {
        resolve(data.data);
      });
  });
}
export function textB(data1, data2) {
  let tex;
  let str = data1;
  var str1 = str.substr(1);
  var str2 = str1.substring(0, str1.length - 1);
  str2 = str2.split(",");

  str2.forEach((element, index) => {
    let st3 = element.split("");
    // console.log(st3[0]);
    data2 = data2 + "";
    let flag = st3.indexOf(data2);

    if (flag == 0) {
      // return element;
      tex = element;
    }
  });

  // console.log(tex.split('"'),378);
  tex = {
    value: tex.split(":")[0],
    label: tex.split('"')[1],
  };

  return tex;
}
export function textC(data) {

  let str = data;
  var str1 = str.substr(1);
  var str2 = str1.substring(0, str1.length - 1);
  // console.log( str2.split('#'));
  let c = {
    id: str2.split('#')[0],
    url: str2.split('#')[1]
  }
  return c

}

<template>
  <!-- 模型 -->
  <div class="model_detail">
    <div class="model_box">
      <div style="width: 100%" v-if="ModelSuccsess.assgraph !== null">
        <el-carousel
          indicator-position="none"
          :interval="5000"
          arrow="always"
          :autoplay="false"
        >
          <el-carousel-item v-for="(item, index) in CarouselImg" :key="index">
            <div class="draw_same">
              <!-- <img class="vertical detail_img" :src="item" alt="模型" /> -->
              <el-image
                class="detail_img"
                :src="item"
                :preview-src-list="[item]"
              >
              </el-image>
              <div class="shows">
                <div class="btn">画同款</div>
                <DrawSame />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div v-else-if="ModelSuccsess.assgraph == null">
        <div v-for="(item, index) in ModelSuccsess.sysOssEntities" :key="index">
          <img class="vertical" :src="item.url" alt="模型" />
        </div>
      </div>
    </div>
    <div class="info_matter">
      <div class="info_box_com">
        <div class="info_main">
          <div class="info_left">
            <div class="top_box">
              <div class="user_about">
                <div class="u_img">
                  <img :src="ModelSuccsess.avatar" alt="头像" />
                </div>
                <div class="u_name">{{ ModelSuccsess.author }}</div>
              </div>
              <div class="btn_gz" @click="follow()">
                {{ ModelSuccsess.isFollow ? "已关注" : "关注" }}
              </div>
            </div>
            <div class="title">{{ ModelSuccsess.title }}</div>
            <div class="content">{{ ModelSuccsess.content }}</div>
            <div class="collect_like">
              <div class="cl_left">
                <div
                  class="cl_item"
                  @click.stop="FavoritePictures(ModelSuccsess)"
                >
                  <img
                    v-if="ModelSuccsess.scistate == 0"
                    src="@/assets/images/shoucang.png"
                    alt="收藏"
                  />
                  <img
                    v-else
                    src="@/assets/images/Frame@2x-4.png"
                    alt="已收藏"
                  />
                  <span>{{ ModelSuccsess.collectCount }}</span>
                </div>
                <div class="cl_item" @click.stop="LikeImage(ModelSuccsess)">
                  <img
                    v-if="ModelSuccsess.sclikeistate == 2"
                    src="@/assets/images/fav-1.png"
                    alt="喜欢"
                  />
                  <img v-else src="@/assets/images/fav1.png" alt="未喜欢" />
                  <span>{{ ModelSuccsess.likeCount }}</span>
                </div>
              </div>
              <div class="back_pic" @click="GoBackGraph()">
                <i class="el-icon-plus"></i>返图区
              </div>
            </div>
            <div class="data_list">
              <div class="data_item">
                <div class="item_name">基础模型</div>
                <div class="item_text">{{ basemodelText }}</div>
              </div>
              <div class="data_item">
                <div class="item_name">类型</div>
                <div class="item_text">{{ basicModelTypeText }}</div>
              </div>
              <div class="data_item">
                <div class="item_name">下载量</div>
                <div class="item_text">{{ ModelSuccsess.downloadtimes }}</div>
              </div>
              <div class="data_item">
                <div class="item_name">使用次数</div>
                <div class="item_text">{{ ModelSuccsess.usetimes }}</div>
              </div>
              <div class="data_item">
                <div class="item_name">题材分类</div>
                <div class="item_text">{{ GetTags() }}</div>
              </div>
            </div>
          </div>
          <div class="info_right">
            <!-- 有无关联群组作者本人可编辑 -->
            <!-- <div
              v-if="user.uid == ModelSuccsess.uid"
              class="upload_btn"
              @click="uploadBtnClick()"
            >
              编辑
            </div> -->

            <!-- 无关联群组非作者本人不可编辑 -->
            <div
              class="community_item"
              style="
                height: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
              v-if="ModelSuccsess.gpresponse == null"
            >
              暂无关联群组
            </div>
            <!-- 有关联群组 -->
            <div
              class="community_item"
              v-if="ModelSuccsess.gpresponse !== null"
            >
              <div
                class="community_item_bg"
                :style="{
                  backgroundImage: 'url(' + gpresponseList.backimg + ')',
                }"
              >
                <div class="item_group_img">
                  <img :src="gpresponseList.media" alt="头像" />
                </div>
                <div
                  v-if="user.uid !== ModelSuccsess.uid"
                  class="add_group"
                  @click="applyForJoinHotCom(gpresponseList)"
                >
                  加入
                </div>
              </div>
              <div class="community_item_info">
                <div class="c_info_top">
                  <img src="@/assets/images/Frame 198.png" alt="pic" /><span>{{
                    gpresponseList.groupname
                  }}</span>
                </div>
                <div class="item_group_introduce">
                  {{ gpresponseList.content }}
                </div>
                <div class="item_group_num">
                  <span>{{ gpresponseList.groupUidCount }}</span
                  >群成员
                </div>
              </div>
            </div>
            <div class="data_list">
              <div class="data_item">
                <div class="item_name">使用价格</div>
                <div class="item_text">
                  {{ ModelSuccsess.isfree == 0 ? "免费" : ModelSuccsess.price }}
                </div>
              </div>
              <div class="data_item">
                <div class="item_name">谁可以看</div>
                <div class="item_text">
                  {{ ModelSuccsess.isopen == 0 ? "公开" : "仅自己" }}
                </div>
              </div>
              <div class="data_item">
                <div class="item_name">授权信息</div>
                <div class="item_text">{{ accreditTypeText }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn_list">
          <!-- <div class="share" @click="sendTopGroupFun(true)">分享到聊天</div>
          <div class="share" @click="sendTopGroupFun(true)">使用模型</div>
          <div class="share" @click="sendTopGroupFun(true)">下载模型</div> -->
          <div class="share" @click="sendTopGroupFun(true, 1)">分享到聊天</div>

          <div class="sharetwo">使用模型</div>
          <div class="share" @click="DolondModel()">下载模型</div>
          <div
            class="share"
            v-if="user.uid == ModelSuccsess.uid"
            @click="uploadBtnClick()"
          >
            编辑模型详情
          </div>
        </div>
      </div>
    </div>
    <ShareToGroup
      v-if="sharToGroupShow"
      :materialData="ModelSuccsess"
      :sharToGroupShow="sharToGroupShow"
      @sendTopGroupFun="sendTopGroupFun"
    />
    <!-- 编辑上传弹窗 -->
    <UpLoadDrawerUpdate
      :ModelSuccsess="ModelSuccsess"
      :showImagModel="showImagModel"
      @beforeClose="beforeClose"
    >
    </UpLoadDrawerUpdate>
  </div>
</template>
<script>
import DrawSame from "@/components/Detail/DrawSame";
import ShareToGroup from "@/components/Detail/ShareToGroup";
import { text } from "@/utils/index";
import { sendSock } from "@/utils/ws.js";
import UpLoadDrawerUpdate from "../UpLoadDrawers/Models/index.vue";
export default {
  props: {
    // 图片Id
    newModel: "",
  },

  data() {
    return {
      showImagModel: false,
      activeType: "5",
      sharToGroupShow: false, //分享到群聊
      ModelSuccsess: "",
      accreditTypeText: "", //授权信息
      basemodelText: "", //基础模型
      basicModelTypeText: "", //模型类型
      gpresponseList: {}, //群组信息
      user: "", //个人信息
      url: "",
      cateid: 5, //模型
      dialogVisible: false,
      dialogUrl: "", //预览图片url
      CarouselImg: [], //关联图轮播
      editUpdateFlg: false, //编辑上传弹窗
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("User"));
  },
  mounted() {
    this.url = this.$http.adornUrl("/app/common/upload");
  },
  components: {
    DrawSame,
    ShareToGroup,
    UpLoadDrawerUpdate,
  },
  methods: {
    beforeClose(e) {
      console.log(e);
      this.showImagModel = e;
    },
    // 编辑/上传按钮点击
    uploadBtnClick() {
      this.showImagModel = true;
      let c = JSON.parse(JSON.stringify(this.ModelSuccsess));
      this.ModelSuccsess = c;
    },
    // 下载模型
    DolondModel() {
      // console.log();
      if (this.ModelSuccsess.sysOssEntities[0]) {
        window.open(this.ModelSuccsess.sysOssEntities[0].url);
      }
    },
    // 加入点击
    applyForJoinHotCom(item) {
      console.log(item);
      let time = new Date().getTime();
      let sendArr = {
        type: "group-apply",
        data: {
          senderId: this.user.uid,
          senderName: this.user.username,
          senderAvatar: this.user.avatar,
          receiverId: item.id,
          sendTime: time,
        },
      };
      console.log(sendArr, "发送请求");
      sendSock(sendArr);
      this.$message({
        message: "申请成功，等待确认",
        type: "success",
      });
    },
    // 获取题材分类
    GetTags() {
      if (this.ModelSuccsess) {
        return this.ModelSuccsess.tagList.join(",");
      }
    },
    sendTopGroupFun(i, num) {
      this.sharToGroupShow = i;
      // this.sharToGroupShow = i;
      // 1，分享聊天 2，使用模型3，下载模型
      // if (num == 1) {
      //   this.sharToGroupShow = true;
      //   console.log("分享聊天", i, num);
      //   return;
      // }
      // if (num == 2) {
      //   console.log("使用模型", i, num);
      //   return;
      // }
      // if (num == 3) {
      //   console.log(
      //     "下载模型",
      //     this.ModelSuccsess,
      //     this.ModelSuccsess.sysOssEntities[0].url,
      //     window.location.href
      //   );
      //   return;
      // }
    },
    // 获取模型信息
    GetModelSuccess() {
      this.$http({
        url: this.$http.adornUrl("/app/material/detail"),
        method: "get",
        params: this.$http.adornParams({
          id: this.newModel,
        }),
      }).then(({ data }) => {
        // console.log(data,345);
        if (data.code == 0) {
          this.ModelSuccsess = data.result;
          if (this.ModelSuccsess.assgraph) {
            this.CarouselImg = this.ModelSuccsess.assgraph.split(",");
            //  this.CarouselImg,
            console.log(this.ModelSuccsess, 368);
          }

          //  str2.split(",");
          if (this.ModelSuccsess.gpresponse != null) {
            this.gpresponseList = this.ModelSuccsess.gpresponse;
          }
          this.GetAccreditType(); //获取授权信息
          this.getModelCommonConfig(); //获取基础模型参数值
          this.getModelType(); //获取模型类型参数值
        }
      });
    },
    // 获取授权信息
    GetAccreditType() {
      if (this.ModelSuccsess) {
        this.$http({
          url: this.$http.adornUrl("/app/material/getAccreditType"),
          method: "get",
        }).then(({ data }) => {
          this.accreditTypeText = text(
            data.accreditType,
            this.ModelSuccsess.accreditType
          );
          // console.log(  this.accreditTypeText,3545);
        });
      }
    },
    // 获取基础模型参数值
    getModelCommonConfig() {
      this.$http({
        url: this.$http.adornUrl("/app/material/getCommonConfig"),
        method: "get",
        params: this.$http.adornParams({
          key: "pc_basic_model",
        }),
      }).then((data) => {
        this.basemodelText = text(
          data.data.value,
          this.ModelSuccsess.basemodel
        );
        // console.log("基础模型参数值", this.basemodelText);
      });
    },
    // modelType modeltype
    getModelType() {
      this.$http({
        url: this.$http.adornUrl("/app/material/getCommonConfig"),
        method: "get",
        params: this.$http.adornParams({
          key: "pc_model_type",
        }),
      }).then((data) => {
        this.basicModelTypeText = text(
          data.data.value,
          this.ModelSuccsess.modeltype
        );
        // console.log("基础模型参数值", this.basicModelTypeText);
      });
    },
    // 关注/取消关注
    follow() {
      let url = this.ModelSuccsess.isFollow
        ? "/app/user/cancelFollow"
        : "/app/user/addFollow ";
      this.$http({
        url: this.$http.adornUrl(url),
        method: "post",
        data: this.$http.adornData({
          id: this.ModelSuccsess.uid,
        }),
      }).then((data) => {
        if (data.data.code === 0) {
          this.ModelSuccsess.isFollow = !this.ModelSuccsess.isFollow;
          this.$message({
            message: `${data.data.msg}`,
            type: "success",
            duration: 1500,
          });
        } else {
          this.$message({
            message: `${data.data.msg}`,
            type: "warning",
            duration: 1500,
          });
        }
      });
    },
    //点赞
    LikeImage(e) {
      if (e.sclikeistate == 0) {
        this.AddLike(e);
      } else {
        this.EditLike(e);
      }
    },
    // 添加点赞
    AddLike(e) {
      this.$http({
        url: this.$http.adornUrl("/app/material/addLike"),
        method: "post",
        data: this.$http.adornData({
          id: e.id, //素材id
        }),
      }).then((data) => {
        this.ModelSuccsess.sclikeistate = 2;
        this.ModelSuccsess.likeCount++;
        this.$message({
          message: "添加成功",
          type: "success",
          duration: 500,
        });
      });
    },
    //取消点赞
    EditLike(e) {
      this.$http({
        url: this.$http.adornUrl("/app/material/cancelLike"),
        method: "post",
        data: this.$http.adornData({
          id: e.id, //素材id
        }),
      }).then((data) => {
        this.ModelSuccsess.sclikeistate = 0;
        this.ModelSuccsess.likeCount--;
        this.$message({
          message: "取消成功",
          type: "success",
          duration: 500,
        });
      });
    },

    // 收藏
    FavoritePictures(e) {
      if (e.scistate == 0) {
        this.AddFavorite(e);
      } else {
        this.EditFavorite(e);
      }
    },
    //取消收藏
    EditFavorite(e) {
      this.$http({
        url: this.$http.adornUrl("/app/material/cancel"),
        method: "post",
        data: this.$http.adornData({
          id: e.id, //素材id
        }),
      }).then((data) => {
        this.ModelSuccsess.scistate = 0;
        this.ModelSuccsess.collectCount--;
        this.$message({
          message: "取消成功",
          type: "success",
          duration: 500,
        });
      });
    },

    // 添加收藏
    AddFavorite(e) {
      this.$http({
        url: this.$http.adornUrl("/app/material/addCollect"),
        method: "post",
        data: this.$http.adornData({
          id: e.id, //素材id
        }),
      }).then((data) => {
        this.ModelSuccsess.scistate = 1;
        this.ModelSuccsess.collectCount++;
        this.$message({
          message: "收藏成功",
          type: "success",
          duration: 500,
        });
      });
    },
    //返图区
    GoBackGraph() {
      this.$router.push({ path: "/backgraph/" + this.ModelSuccsess.id });
      this.$emit("closeModel", false);
      // if (
      //   this.$router.replace({ path: "/backgraph/" + this.ModelSuccsess.id })
      // ) {
      //   location.reload();
      // }

      // if (this.$router.push({ name: "backgraph" })) {
      //   location.reload();
      // }
    },
  },
  mounted() {
    this.GetModelSuccess();
  },
  watch: {
    newModel(newVal) {
      console.log(newVal, 345);
      // console.log(newVal,this.ModelSuccsess);
      this.GetModelSuccess();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>

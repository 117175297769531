<template>
  <el-form
    :model="uploadForm"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    class="demo-ruleForm"
    label-position="top"
  >
    <el-form-item label="上传素材" prop="fileUrl">
      <el-upload
        class="upload-demo"
        drag
        multiple
        v-model="uploadForm.fileUrl"
        action=""
      >
        <div><i class="el-icon-plus"></i>添加素材</div>
      </el-upload>
    </el-form-item>
    <el-form-item label="标题" prop="title">
      <el-input
        v-model="uploadForm.title"
        placeholder="请输入标题"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item label="请选择题材分类" prop="tags">
      <el-checkbox-group
        v-model="uploadForm.tags"
        size="medium"
        :max="3"
        fill="#e35d44"
      >
        <el-checkbox-button
          v-for="(item, index) in tagList"
          :key="index"
          :label="item"
          >{{ item }}</el-checkbox-button
        >
      </el-checkbox-group>
    </el-form-item>
    <el-form-item label="谁可以看" prop="permission">
      <el-radio
        fill="#e35d44"
        v-for="(item, index) in astrictList"
        :key="index"
        v-model="uploadForm.permission"
        :label="item.value"
        >{{ item.name }}</el-radio
      >
    </el-form-item>
    <el-form-item label="授权信息" prop="accreditType">
      <el-cascader :options="accreditTypeList" clearable></el-cascader>
    </el-form-item>

    <el-form-item label="内容简介" prop="content">
      <el-input
        type="textarea"
        v-model="uploadForm.content"
        placeholder="请填写简介"
      ></el-input>
    </el-form-item>

    <el-form-item class="use_price" label="使用价格" prop="cut">
      <el-radio
        fill="#e35d44"
        v-for="(item, index) in CutList"
        :key="index"
        v-model="uploadForm.cut"
        style="color: #ffffff"
        :label="item.cutValue"
        >{{ item.cutName }}</el-radio
      >
      <el-input
        v-model="uploadForm.cut"
        placeholder="堆币"
        clearable
        size="small"
        class="price_inp"
      ></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  data() {
    return {
      uploadForm: {
        fileUrl: "", //素材文件
        title: "", //标题
        tags: [], //题材标签
        strTags: "", //题材标签字符串
        permission: "", //权限
        accreditType: null, //授权信息
        content: "", //内容简介
        cut: "", //0 普通贴 1 付费贴 2 堆币贴 使用价格
        price: "", //堆币数数量
        basemodel: "", // 基础模型
        modeltype: "", //模型类型：
        modelversion: "", //模型版本号：
        relevancegp: "", //关联我的群聊：【群id】
      },
      rules: {
        // 文件类型
        fileUrl: [
          {
            required: true,
            message: "文件类型不能为空",
            trigger: ["blur", "change"],
          },
        ],
        // 标题
        title: [
          {
            required: true,
            message: "标题不能为空",
            trigger: "blur",
          },
        ],
        // // 题材标签
        tags: [
          {
            type: "array",
            required: true,
            message: "最少选择一个，最多选择三个",
            trigger: "change",
          },
        ],
        // 权限
        permission: [
          { required: true, message: "请选择权限", trigger: "change" },
        ],
        // 授权信息
        accreditType: [
          { required: true, message: "请选择一个授权信息", trigger: "change" },
        ],
        // 内容简介
        content: [
          { required: true, message: "内容简介不能为空", trigger: "blur" },
        ],
        cut: [
          {
            required: true,
            message: "请输入堆币数",
            trigger: ["blur", "change"],
          },
        ],
        // 基础模型
        basemodel: [
          {
            required: true,
            message: "请选择基础模型",
            trigger: ["blur", "change"],
          },
        ],
        // modeltype 模型类型：
        modeltype: [
          {
            required: true,
            message: "请选择模型类型",
            trigger: ["blur", "change"],
          },
        ],
        // 模型版本号
        modelversion: [
          {
            required: true,
            message: "模型版本号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        // 关联我的群聊：【群id】
        relevancegp: [
          {
            required: true,
            message: "请选择关联群聊",
            trigger: ["blur", "change"],
          },
        ],
      },

      //   题材分类数据

      tagList: [
        "AI",
        "人物",
        "动物",
        "人物",
        "动物",
        "人物",
        "动物",
        "人物",
        "动物",
      ],
      //   谁可以看数据
      astrictList: [
        {
          name: "公开",
          value: 0,
        },
        {
          name: "私有",
          value: 1,
        },
      ],
      // 使用价格
      CutList: [
        {
          cutName: "免费",
          cutValue: 0,
        },
        {
          cutName: "金额",
          cutValue: 1,
        },
        {
          cutName: "堆币",
          cutValue: 2,
        },
      ],
      //   授权数据
      accreditTypeList: [{}],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../component.scss";
</style>